import PropTypes from 'prop-types';
import React from 'react';

import styles from './weekdayHeaders.scss';

export default function WeekdayHeaders(props) {
  const { weekdayHeaders } = props;

  const sevenWeekdayHeaders = [];
  for (let weekday = 0; weekday <= 6; weekday++) {
    sevenWeekdayHeaders.push(
      <th key={weekday} className={styles.weekdayLabel}>
        {weekdayHeaders[weekday]}
      </th>
    );
  }

  return (
    <tr aria-hidden className={styles.weekdayHeadersContainer}>
      {sevenWeekdayHeaders}
    </tr>
  );
}

const { arrayOf, string } = PropTypes;

WeekdayHeaders.propTypes = {
  weekdayHeaders: arrayOf(string)
};

WeekdayHeaders.defaultProps = {
  weekdayHeaders: Array(7)
};
