import { BAZAAR_VOICE, DEFAULT } from './constants/pageTypes';
import { GEOLOCATION_TIMEOUT_KEY, getIntConfig } from './helper';

export const getInitialState = (surveyData = {}) => {
  const firstPart =
    surveyData.body &&
    surveyData.body.body &&
    surveyData.body.body.parts &&
    surveyData.body.body.parts[0];

  return {
    geolocationTimeout: getIntConfig(surveyData, GEOLOCATION_TIMEOUT_KEY),
    type: firstPart && firstPart.blockType === 'bazaarVoicePage' ? BAZAAR_VOICE : DEFAULT,
    isRTL: surveyData.textDirection === 'rtl'
  };
};

export default function reduce(state = {}) {
  return state;
}
