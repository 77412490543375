import { HTML_INPUT_TYPES } from '@models/htmlInput';
import { QUESTION_TYPES } from '@models/question';
import ResourcesRelationshipManager from '@models/resourcesRelationshipManager';

export const MATCH_TEXTFIELD = 'textfield';
export const MATCH_TEXTAREA = 'textarea';

export const extractQuestionData = (id, questionData) => ({
  id,
  caption: questionData.caption,
  type: QUESTION_TYPES.TEXTFIELD
});

export const extractTextfieldData = (id, questionData) => {
  const { formkey, fieldName, value, requiredField, validationEmpty, validationFailed } =
    questionData;
  return {
    caption: '',
    hasAnswer: Boolean(value),
    hasValidationFailure: Boolean(validationFailed),
    hasValidationFailureForEmpty: Boolean(validationEmpty),
    htmlInput: formkey,
    isRequired: Boolean(requiredField),
    question: id,
    value,
    fieldName
  };
};

export const extractHtmlInputData = (id, questionData) => {
  const { formkey, value, disableAutocomplete } = questionData;
  return {
    id: formkey,
    disableAutocomplete: Boolean(disableAutocomplete),
    question: id,
    type: HTML_INPUT_TYPES.TEXT,
    value
  };
};

export default class TextfieldLogic {
  // eslint-disable-next-line class-methods-use-this
  shouldNormalize({ questionData }) {
    const shouldNormalize = questionData.blockType === MATCH_TEXTFIELD;
    return shouldNormalize;
  }

  normalize({ id, questionData }) {
    if (!this.shouldNormalize({ questionData })) return undefined;
    const resourcesManager = new ResourcesRelationshipManager();

    const question = resourcesManager.createOrUpdateQuestion(extractQuestionData(id, questionData));

    const htmlInputData = extractHtmlInputData(id, questionData);
    const htmlInput = resourcesManager.createOrUpdateHtmlInput(htmlInputData);
    if (htmlInput.value) question.htmlInputsWithAnswer.add(htmlInput.id);

    const textfieldData = extractTextfieldData(id, questionData);
    resourcesManager.createOrUpdateOption(textfieldData);

    resourcesManager.createCondition(questionData.condition, id);
    const initialValue = textfieldData.value ? textfieldData.value : '';
    resourcesManager.createField(questionData.fieldName, initialValue, id);

    return resourcesManager.resources();
  }
}
