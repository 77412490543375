export function supportPageOffset() {
  return window && typeof window.pageYOffset !== 'undefined';
}

export function isCSS1Compat() {
  return (document.compatMode || '') === 'CSS1Compat';
}

export function getIOSScrollTop() {
  return document.getElementsByTagName('body')[0].scrollTop;
}

export function setIOSScrollTop(value) {
  document.getElementsByTagName('body')[0].scrollTop = value;
}

export default function scrollTop(newValue) {
  // write value
  if (newValue !== undefined) {
    document.documentElement.scrollTop = newValue; // safari, samsung internet
    document.body.scrollTop = newValue; // everyone else.
  }

  const scrollTopProp = isCSS1Compat() ? 'documentElement' : 'body';
  return supportPageOffset() ? window.pageYOffset : document[scrollTopProp].scrollTop;
}
