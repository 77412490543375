export default class ScrollService {
  components: Map<number, HTMLElement>;

  constructor() {
    this.components = new Map();
  }

  subscribeComponent(componentId: number, node: HTMLElement) {
    this.components.set(componentId, node);
  }

  scrollIntoComponent(componentId: number) {
    const targetElement = this.components.get(componentId);

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
}
