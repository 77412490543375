import {
  CLEAR_FOCUS,
  SET_FETCHING_STATUS,
  SET_FOCUSED_ELEMENT,
  SET_READY_STATUS
} from '@redux/actions/setPageStatus';

export const pageStatuses = {
  READY: 'READY',
  FETCHING: 'FETCHING'
};

export const initialState = {
  pageStatus: pageStatuses.READY,
  focusedElement: null
};

export const getInitialState = () => initialState;

export default function reduce(state = {}, action) {
  if (action.type === SET_FETCHING_STATUS) {
    return {
      ...state,
      pageStatus: pageStatuses.FETCHING
    };
  }
  if (action.type === SET_READY_STATUS) {
    return {
      ...state,
      pageStatus: pageStatuses.READY
    };
  }
  if (action.type === SET_FOCUSED_ELEMENT) {
    return {
      ...state,
      focusedElement: action.payload.element
    };
  }
  if (action.type === CLEAR_FOCUS) {
    return {
      ...state,
      focusedElement: null
    };
  }
  return state;
}
