import PropTypes from 'prop-types';

//----------------------------------------------------------------------
// CONSTANTS
//----------------------------------------------------------------------

export const COOKIE_BANNER_VERSION_V1 = 'V1';
export const COOKIE_BANNER_VERSION_V3 = 'V3';

export const ARIA_TITLE_ID = 'cookie-banner-title';
export const ARIA_DESCRIPTION_ID = 'cookie-banner-description';
export const ARIA_MESSAGE_ID = 'cookie-banner-message';

export const cookieBannerVersionPropType = PropTypes.oneOf([
  COOKIE_BANNER_VERSION_V1,
  COOKIE_BANNER_VERSION_V3
]);

//----------------------------------------------------------------------
// UTILS
//----------------------------------------------------------------------

export const isV1 = (version) => version === COOKIE_BANNER_VERSION_V1;
export const isV3 = (version) => version === COOKIE_BANNER_VERSION_V3;

export const versionClassNameAuthority = (version, classNameV1, classNameV3) =>
  isV1(version) ? classNameV1 : classNameV3;

export const createNavigationLink = (navigation, panel) => (evt) => {
  evt.preventDefault();
  navigation.push(panel);
};
