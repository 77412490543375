import PropTypes from 'prop-types';
import React from 'react';

import Accordion from '@commons/Accordion';
import Checkbox from '@commons/Checkbox';

import CookieCategoryControl from '@components/CookieBanner/V3/CookieCategoryControl';
import CookieCategoryDetails from '@components/CookieBanner/V3/CookieCategoryDetails';
import {
  COOKIE_CATEGORY,
  PANEL_ID_CATEGORY,
  PANEL_ID_LEGAL_NOTICE
} from '@components/CookieBanner/V3/utils';
import CookieBannerPanel from '@components/CookieBanner/shared/CookieBannerPanel';
import {
  COOKIE_BANNER_VERSION_V3,
  createNavigationLink
} from '@components/CookieBanner/shared/utils';
import { propTypes as StackNavigatorPropTypes } from '@components/StackNavigator/utils';

import styles from './panelCookieSettings.scss';

const PanelCookieSettings = ({
  navigation,
  categories,
  allSelected,
  // Actions
  onSelectAll,
  onDeselectAll,
  onToggleCategory,
  onConfirmChoices,
  // Translations
  title,
  description,
  legalInfoLinkText,
  confirmText,
  manageTitle,
  cookieDetailsText,
  selectAllCookiesText,
  requiredText
}) => {
  const handleSelectAll = () => {
    if (allSelected) {
      onDeselectAll();
    } else {
      onSelectAll();
    }
  };

  return (
    <CookieBannerPanel.Root>
      <CookieBannerPanel.Title>{title}</CookieBannerPanel.Title>
      <CookieBannerPanel.Section>
        <CookieBannerPanel.Description isShrinkedHeight parseHTML>
          {description}
        </CookieBannerPanel.Description>
      </CookieBannerPanel.Section>

      <CookieBannerPanel.Section noMargin>
        <CookieBannerPanel.Link
          version={COOKIE_BANNER_VERSION_V3}
          onClick={createNavigationLink(navigation, PANEL_ID_LEGAL_NOTICE)}>
          {legalInfoLinkText}
        </CookieBannerPanel.Link>
      </CookieBannerPanel.Section>

      <CookieBannerPanel.Section isRow noMargin>
        <h2 className={styles.subheading}>{manageTitle}</h2>
        <Checkbox id="checkbox-all-cookies" checked={allSelected} onChange={handleSelectAll}>
          {selectAllCookiesText}
        </Checkbox>
      </CookieBannerPanel.Section>

      <CookieBannerPanel.Section>
        <Accordion.Root>
          {categories.map((category) => (
            <Accordion.Tab
              key={category.categoryType}
              id={category.categoryType}
              title={
                <CookieCategoryControl
                  id={category.categoryType}
                  title={category.title}
                  value={category.consent}
                  onChange={onToggleCategory}
                  disabled={category.categoryType === COOKIE_CATEGORY.TECHNICALLY_NECESSARY}
                  requiredLabel={requiredText}
                />
              }>
              <CookieCategoryDetails
                category={PANEL_ID_CATEGORY[category.categoryType]}
                navigation={navigation}
                linkText={cookieDetailsText}>
                {category.message}
              </CookieCategoryDetails>
            </Accordion.Tab>
          ))}
        </Accordion.Root>
      </CookieBannerPanel.Section>

      <CookieBannerPanel.ActionArea>
        <CookieBannerPanel.Action full onClick={onConfirmChoices}>
          {confirmText}
        </CookieBannerPanel.Action>
      </CookieBannerPanel.ActionArea>
    </CookieBannerPanel.Root>
  );
};

PanelCookieSettings.propTypes = {
  navigation: StackNavigatorPropTypes.navigation.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      message: PropTypes.string,
      category: PropTypes.string
    })
  ).isRequired,
  allSelected: PropTypes.bool.isRequired,
  // Actions
  onSelectAll: PropTypes.func.isRequired,
  onDeselectAll: PropTypes.func.isRequired,
  onToggleCategory: PropTypes.func.isRequired,
  onConfirmChoices: PropTypes.func.isRequired,
  // Translations
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  legalInfoLinkText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  manageTitle: PropTypes.string.isRequired,
  cookieDetailsText: PropTypes.string.isRequired,
  selectAllCookiesText: PropTypes.string.isRequired,
  requiredText: PropTypes.string.isRequired
};

export default PanelCookieSettings;
