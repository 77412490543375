import get from 'lodash/get';

function createField(field) {
  if (!field) return null;

  const newField = {
    id: get(field, 'id'),
    isMultiValued: get(field, 'isMultiValued', false)
  };

  return newField;
}

export { createField };

export default createField;
