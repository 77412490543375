import { NODE_TYPES } from '@services/conditionEngine/constants';
import countEvaluator, { COUNT_OPERATIONS } from '@services/conditionEngine/evaluators/count';
import evaluatorRegistry from '@services/conditionEngine/evaluators/registry';

function orEvaluator(node, values) {
  const countConditionNode = {
    ...node,
    op: COUNT_OPERATIONS.GREATER_OR_EQUAL,
    compare_to: 1
  };

  return countEvaluator(countConditionNode, values);
}

evaluatorRegistry.registerEvaluatorForNodeType(NODE_TYPES.OR, orEvaluator);
export default orEvaluator;
