import { QUESTION_TYPES } from '@models/question';
import ResourcesRelationshipManager from '@models/resourcesRelationshipManager';

export const MATCH_TEXT = 'text';
export const MATCH_TEXT_QUESTION = 'questionText';

export default class TextLogic {
  // eslint-disable-next-line class-methods-use-this
  shouldNormalize({ questionData }) {
    switch (questionData.blockType) {
      case MATCH_TEXT:
      case MATCH_TEXT_QUESTION:
        return true;

      default:
        return false;
    }
  }

  normalize({ id, questionData }) {
    if (!this.shouldNormalize({ questionData })) return undefined;
    const resourcesManager = new ResourcesRelationshipManager();

    const { blockType, caption, validationFailed } = questionData;

    const isValidationText = Boolean(validationFailed);
    const isValid = !isValidationText;
    const hasQuestionTitleStyle = blockType === 'questionText';

    resourcesManager.createOrUpdateQuestion({
      id,
      caption,
      hasQuestionTitleStyle,
      isValidationText,
      isValid,
      type: QUESTION_TYPES.TEXT,
      isRequired: false
    });

    resourcesManager.createCondition(questionData.condition, id);

    return resourcesManager.resources();
  }
}
