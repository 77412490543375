/* eslint-disable react/forbid-component-props */

/* eslint-disable react/require-default-props */

/* eslint-disable jsx-a11y/label-has-for */

/* eslint-disable react/no-danger */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import ValidationMessage from '@commons/ValidationMessage';

import { isAdvancedAccessibilityEnabled } from '@services/featureFlags';
import { getTranslation } from '@services/translations';

import styles from './question.scss';

const validationCodeToTranslationKey = {
  validationFailed: 'BAD_FIELD_TOOLTIP',
  answerTooLong: 'ANSWER_TOO_LONG'
};

export function getValidation({
  validationMessage,
  isRequired,
  validationCode,
  hasValidationFailure,
  hasValidationFailureForEmpty,
  validationMessages
}) {
  if (validationMessage) {
    return {
      isError: true,
      validationCaption: [validationMessage]
    };
  }
  const validationMessagesArePresent =
    validationMessages && Array.isArray(validationMessages) && validationMessages.length;
  const badFieldTooltip = getTranslation('BAD_FIELD_TOOLTIP');
  const requiredField = getTranslation('REQUIRED_FIELD');
  const requiredFieldTooltip = getTranslation('REQUIRED_FIELD_TOOLTIP');
  const isError = hasValidationFailure;

  let validationCaption =
    (hasValidationFailureForEmpty && requiredFieldTooltip) ||
    (!validationMessagesArePresent &&
      getTranslation(validationCodeToTranslationKey[validationCode])) ||
    (hasValidationFailure && !validationMessagesArePresent && badFieldTooltip) ||
    (isRequired && requiredField) ||
    '';

  validationCaption = validationCaption ? [validationCaption] : [];

  if (validationMessagesArePresent) {
    validationCaption = [...validationCaption, ...validationMessages];
  }

  return { isError, validationCaption };
}

export default function Question(props, context) {
  const {
    ariaId,
    caption,
    children,
    hasLegendCaption,
    captionId,
    validationMessage,

    // for non-normalized components
    validationEmpty,
    validationFailed,
    validationMessages,
    requiredField,

    // for normalized components
    isRequired,
    hasValidationFailure,
    hasValidationFailureForEmpty
  } = props;

  const { isError, validationCaption } = getValidation({
    validationMessage,
    translations: context.translations || {},
    isRequired: Boolean(requiredField) || isRequired,
    validationCode: validationFailed,
    hasValidationFailure: Boolean(validationFailed) || hasValidationFailure,
    hasValidationFailureForEmpty: Boolean(validationEmpty) || hasValidationFailureForEmpty,
    validationMessages
  });

  const questionTitleStyles = classNames(styles.questionTitle, {
    [styles.questionTitle_hasNoCaption]: !caption
  });

  const Container = hasLegendCaption ? 'legend' : 'div';
  const containerProps = { id: captionId };

  return (
    <Container className={questionTitleStyles} {...containerProps}>
      {validationCaption.map((text) => (
        <ValidationMessage
          key={text}
          isError={isError}
          caption={text}
          shouldRender={Boolean(text)}
        />
      ))}
      {isAdvancedAccessibilityEnabled() ? (
        <label
          htmlFor={ariaId}
          className={classNames("questionCaption", styles.alignQuestion)}
          dangerouslySetInnerHTML={{ __html: caption }}
        />
      ) : (
        <span className={classNames("questionCaption", styles.alignQuestion)}  dangerouslySetInnerHTML={{ __html: caption }} />
      )}
      {children}
    </Container>
  );
}

const { string, node, bool, arrayOf, object } = PropTypes;

Question.propTypes = {
  ariaId: string,
  ariaLabel: string,
  caption: string,
  children: node,
  hasLegendCaption: bool,
  captionId: string,
  validationMessage: string,

  // for non-normalized components
  validationEmpty: string,
  validationFailed: string,
  validationMessages: arrayOf(string),
  requiredField: string,

  // for normalized components
  isRequired: bool,
  hasValidationFailure: bool,
  hasValidationFailureForEmpty: bool
};

Question.defaultProps = {
  hasLegendCaption: true,
  captionId: null
};

Question.contextTypes = {
  translations: object
};
