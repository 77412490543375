import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { isEnterKey, isSpaceKey } from '@utils/detectKey';

import styles from './input.scss';

const { any, bool, func, number, string, oneOfType } = PropTypes;

export default class Input extends PureComponent {
  static propTypes = {
    ariaDescribedBy: string,
    ariaInvalid: bool,
    ariaLabel: string,
    ariaPosInSet: number,
    ariaRequired: bool,
    ariaRole: string,
    ariaSetSize: number,
    ariaTitle: string,
    checked: bool,
    containerStyles: string,
    domId: string,
    formKey: string,
    formValue: any,
    isCheckbox: bool,
    isFocused: bool,
    isHidden: bool,
    isRadio: bool,
    isTextarea: bool,
    isTextfield: bool,
    maxLength: number,
    onBlur: func,
    onChange: func,
    onChangeHandleUpdateValue: func,
    onClick: func,
    onClickHandleUpdateValue: func,
    onFocus: func,
    onKeyUp: func,
    onMouseLeave: func,
    onMouseMove: func,
    onMouseOver: func,
    placeholder: string,
    tabIndex: number,
    disableMouseOutBlur: bool,
    dataRepresentativeValue: oneOfType([string, number])
  };

  static defaultProps = {
    formValue: '',
    checked: false
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.formValue,
      checked: props.checked
    };

    this.setRefToInput = this.setRefToInput.bind(this);
    this.handleOnMouseOutBlurInput = this.handleOnMouseOutBlurInput.bind(this);
    this.handleSpacebarOnKeyUp = this.handleSpacebarOnKeyUp.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleOnKeyPress = this.handleOnKeyPress.bind(this);
  }

  handleOnChange(e) {
    const { isCheckbox, isRadio, onChange, onChangeHandleUpdateValue } = this.props;

    if (isCheckbox || isRadio) {
      let checked = !this.state.checked;
      if (onChangeHandleUpdateValue) checked = onChangeHandleUpdateValue(e, checked);
      this.setState({ checked });
      if (onChange) onChange(e, checked);
    } else {
      let value = e.target.value;
      if (onChangeHandleUpdateValue) value = onChangeHandleUpdateValue(e, value);
      if (value === undefined || value === null) value = '';
      this.setState({ value });
      if (onChange) onChange(e, value);
    }
  }

  handleOnClick(e) {
    const { isCheckbox, isRadio, onClick, onClickHandleUpdateValue } = this.props;

    if (!isCheckbox && !isRadio) {
      if (onClick) onClick(e);
      return;
    }

    let checked = !this.state.checked;
    if (onClickHandleUpdateValue) checked = onClickHandleUpdateValue(e, checked);
    this.setState({ checked });
    if (onClick) onClick(e, checked);
  }

  handleOnMouseOutBlurInput(e) {
    if (!this.props.disableMouseOutBlur) {
      if (this._$input) this._$input.blur();
      if (this.props.onMouseLeave) this.props.onMouseLeave(e);
    }
  }

  handleSpacebarOnKeyUp(e) {
    if (this.props.isRadio && isSpaceKey(e)) this._$input.click();
    if (this.props.onKeyUp) this.props.onKeyUp(e);
  }

  setRefToInput(domInput) {
    this._$input = domInput;
  }

  createDomId(formKey, value) {
    return `${formKey}_${value}`;
  }

  determineInputType({ isCheckbox, isHidden, isRadio, isTextarea, isTextfield }) {
    if (isRadio) return 'radio';
    if (isCheckbox) return 'checkbox';
    // `textfield` is not a valid `type` for `<input>`.
    // Instead, we should have `type = "text"` for `isTextfield: true`.
    if (isTextfield) return 'text';
    if (isTextarea) return 'textarea';
    if (isHidden) return 'hidden';
    return 'hidden';
  }

  focus() {
    if (this._$input) this._$input.focus();
  }

  componentDidUpdate() {
    if (this.props.isFocused && this._$input) this._$input.focus();
  }

  componentWillReceiveProps(nextProps) {
    const { checked = false, formValue = '' } = nextProps;
    this.setState({ checked, value: formValue });
  }

  handleOnKeyPress(e) {
    return !this.props.isTextarea && isEnterKey(e) && e.preventDefault();
  }

  render() {
    const {
      ariaDescribedBy,
      ariaInvalid,
      ariaLabel,
      ariaPosInSet,
      ariaRole,
      ariaSetSize,
      ariaTitle,
      containerStyles,
      domId,
      formKey,
      formValue,
      isCheckbox,
      isHidden,
      isRadio,
      isTextarea,
      isTextfield,
      maxLength,
      onBlur,
      onFocus,
      onMouseMove,
      onMouseOver,
      placeholder,
      tabIndex,
      dataRepresentativeValue
    } = this.props;

    let ariaRequired = this.props.ariaRequired;
    ariaRequired = ariaRequired && { 'aria-required': ariaRequired };

    if (!formKey) {
      if (process.env.NODE_ENV === 'development') {
        console.error('Missing formKey for <Input/>!');
      }
      return null;
    }

    const type = this.determineInputType({
      isCheckbox,
      isHidden,
      isRadio,
      isTextarea,
      isTextfield
    });
    const inputStyles = classNames(containerStyles, {
      [styles.textfield]: isTextfield || isTextarea,
      [styles.textarea]: isTextarea
    });

    const elementProps = {
      id: domId || this.createDomId(formKey, formValue),
      ref: this.setRefToInput,
      role: ariaRole,
      ...ariaRequired,
      'aria-describedby': ariaDescribedBy,
      'aria-invalid': ariaInvalid,
      'aria-label': ariaLabel,
      'aria-posinset': ariaPosInSet,
      'aria-setsize': ariaSetSize,
      checked: this.state.checked,
      className: inputStyles,
      'data-mds-value': formValue, // expose formValue for implementation team
      'data-representative-value': dataRepresentativeValue, // expected value for the user and testing
      maxLength: maxLength,
      name: formKey,
      onBlur: onBlur,
      onChange: !this.props.onClick ? this.handleOnChange : undefined,
      onClick: !this.props.onChange ? this.handleOnClick : undefined,
      onFocus: onFocus,
      onKeyUp: this.handleSpacebarOnKeyUp,
      onMouseMove: onMouseMove,
      onMouseOut: this.handleOnMouseOutBlurInput,
      onMouseOver: onMouseOver,
      placeholder: placeholder,
      tabIndex: tabIndex,
      type: type,
      value: this.state.value,
      onKeyPress: this.handleOnKeyPress
    };

    if (!isTextarea) {
      elementProps.title = ariaTitle;
    }

    return isTextarea ? <textarea {...elementProps} /> : <input {...elementProps} />;
  }
}
