/* eslint-disable no-underscore-dangle */

const SEPARATOR_SIGN_REGEX = /\/|-|\.|\s/;
const DEFAULT_SEPARATOR_SIGN = '/';
const GUARANTEED_EMPTY_STRING = '';
const NUMBER_ONLY_REGEX = /[0-9]/gi;
const MONTH_IDENTIFIER = 'M';
const DAY_IDENTIFIER = 'D';
const YEAR_IDENTIFIER = 'Y';

export const extractSeparatorSign = (dateFormat = '') => {
  const separatorMatch = dateFormat.match(SEPARATOR_SIGN_REGEX);
  return separatorMatch ? separatorMatch[0] : DEFAULT_SEPARATOR_SIGN;
};

export const isEmptyString = (testValue) => testValue === '';

export const isNotString = (testValue) => typeof testValue !== 'string';

export const removeLastCharFromDateValue = (string = '') => string.substr(0, string.length - 1);

export default class DateEntryValidator {
  constructor({ dateFormat }) {
    this._dateFormat = dateFormat;

    const separatorSign = extractSeparatorSign(dateFormat);
    this._separatorSign = separatorSign;

    if (separatorSign) {
      this._firstSeparatorSignPosition = dateFormat.indexOf(separatorSign) + 1;
      this._secondSeparatorSignPosition = dateFormat.lastIndexOf(separatorSign) + 1;
    }

    const { month, day, year } = this._extractMonthDayYearPositionOnSeparatorSign(dateFormat);
    this._monthPositionInDateFormat = month;
    this._dayPositionInDateFormat = day;
    this._yearPositionInDateFormat = year;
  }

  _extractMonthDayYearPositionOnSeparatorSign(dateFormat) {
    const retval = {};
    if (!this._hasSeparatorSign(dateFormat)) return retval;

    const dateFormatSplittedOnSeparatorSign = dateFormat.split(this._separatorSign);
    for (let i = 0; i < dateFormatSplittedOnSeparatorSign.length; i++) {
      const fragment = dateFormatSplittedOnSeparatorSign[i];
      switch (fragment[0]) {
        case MONTH_IDENTIFIER:
          retval.month = i;
          break;
        case DAY_IDENTIFIER:
          retval.day = i;
          break;
        case YEAR_IDENTIFIER:
          retval.year = i;
          break;
        default:
          break;
      }
    }

    return retval;
  }

  transformToValidValue(dateValue = '') {
    if (isNotString(dateValue) || isEmptyString(dateValue)) {
      return GUARANTEED_EMPTY_STRING;
    }

    const currentChar = dateValue[dateValue.length - 1];

    const currentCharIsASlash = this._isSeparatorSign(currentChar);
    const currentCharIsInASlashPosition =
      this._isInFirstSeparatorSignPosition(dateValue) ||
      this._isInSecondSeparatorSignPosition(dateValue);
    if (currentCharIsASlash && currentCharIsInASlashPosition) return dateValue;

    const match = currentChar.match(NUMBER_ONLY_REGEX);
    if (match) {
      if (currentCharIsInASlashPosition) {
        return `${removeLastCharFromDateValue(dateValue)}/${currentChar}`;
      }
      return dateValue;
    }

    return removeLastCharFromDateValue(dateValue);
  }

  extractMonthDayYear(dateValue) {
    const retval = { month: null, day: null, year: null };
    if (isNotString(dateValue)) return retval;
    if (!this._hasSeparatorSign(dateValue)) return retval;

    const dateFormatSplittedOnSeparatorSign = dateValue.split(this._separatorSign);
    let month = dateFormatSplittedOnSeparatorSign[this._monthPositionInDateFormat];
    let year = dateFormatSplittedOnSeparatorSign[this._yearPositionInDateFormat];
    let day = dateFormatSplittedOnSeparatorSign[this._dayPositionInDateFormat];
    month = parseInt(month, 10);
    day = parseInt(day, 10);
    year = parseInt(year, 10);

    if (Number.isNaN(month) || Number.isNaN(day) || Number.isNaN(year)) return retval;

    const monthIsInValidMonthRange = month >= 0 && month <= 12;
    if (!monthIsInValidMonthRange) return retval;

    retval.month = month;
    retval.year = year;
    retval.day = day;
    return retval;
  }

  _hasSeparatorSign(value) {
    return value.indexOf(this._separatorSign) >= 0;
  }

  _isSeparatorSign(testValue) {
    return testValue === this._separatorSign;
  }

  _isInFirstSeparatorSignPosition(dateValue = '') {
    return dateValue.length === this._firstSeparatorSignPosition;
  }

  _isInSecondSeparatorSignPosition(dateValue = '') {
    return dateValue.length === this._secondSeparatorSignPosition;
  }
}
