import { HTML_INPUT_TYPES } from '@models/htmlInput';
import { QUESTION_TYPES } from '@models/question';
import ResourcesRelationshipManager from '@models/resourcesRelationshipManager';

export const MATCH_DROPDOWN = 'dropdown';
export default class DropdownLogic {
  // eslint-disable-next-line class-methods-use-this
  shouldNormalize({ questionData: { blockType } }) {
    return MATCH_DROPDOWN === blockType;
  }

  normalize({ id, questionData }) {
    if (!this.shouldNormalize({ questionData })) return undefined;
    const resourcesManager = new ResourcesRelationshipManager();

    const { fieldName, formkey, caption, condition, requiredField, alt, multivalued } =
      questionData;

    resourcesManager.createOrUpdateQuestion({
      id,
      isRequired: Boolean(requiredField),
      caption,
      type: QUESTION_TYPES.DROPDOWN
    });

    let initialValue;

    if (multivalued) {
      const activeItems = alt.filter((dropdownList) => dropdownList.active);
      initialValue = activeItems.map((item) => item.id);
    } else {
      const activeItem = alt.find((dropdownList) => dropdownList.active);
      initialValue = activeItem ? activeItem.id : null;
    }

    resourcesManager.createOrUpdateHtmlInput({
      id: formkey,
      type: HTML_INPUT_TYPES.HIDDEN,
      value: initialValue,
      question: id
    });

    resourcesManager.createField(fieldName, initialValue, id);
    resourcesManager.createCondition(condition, id);

    alt.forEach((alternative) => {
      const option = resourcesManager.createOrUpdateOption({
        question: id,
        caption: alternative.text
      });
      resourcesManager.createAlternative(fieldName, alternative.id, option.id);
      resourcesManager.createCondition(alternative.condition, option.id);
    });
    return resourcesManager.resources();
  }
}
