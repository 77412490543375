import '@utils/setAssetPath';
import '@utils/polyfills/scheduling';

import { isEmpty, merge } from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';

import App from '@components/App';

import MediaQueryWatcher from '@models/mediaQueryWatcher';
import NavigationButtonsNormalizer from '@models/navigationButtonsNormalizer';
import QuestionNormalizer from '@models/questionNormalizer';
import TouchDetector from '@models/touchDetector';

import { getInitialState as getCookieBannerInitialState } from '@redux/reducers/cookieBanner';
import { getInitialState as getPageSettingsInitialState } from '@redux/reducers/pageSettings';
import { getInitialState as getSurveyStatusInitialState } from '@redux/reducers/surveyStatus';
import createStoreWithInitialState from '@redux/store';

import { surveyIsReady } from '@services/api/events';
import { setStore } from '@services/api/surveyMethods';
import '@services/conditionEngine/evaluators';
import { isSurveyAPIEnabled, setFeatureFlags } from '@services/featureFlags';
import FieldsService from '@services/fields';
import IPCService from '@services/inPageConditioning';
import { createProvider } from '@services/surveyDataProvider';
import { trackPageStats } from '@services/telemetry';
import { defineTranslations } from '@services/translations';
import ValidationsService from '@services/validations';

import getAllBlocks from '@utils/getAllBlocks';
import parseBoolProperties from '@utils/parseBoolProperties';
import { shouldEnableSurveyDataOverrides, surveyDataOverrides } from '@utils/surveyDataOverrides';

if (!isEmpty(surveyDataOverrides) && shouldEnableSurveyDataOverrides()) {
  const newSurveyData = merge(window.surveyData, surveyDataOverrides);
  window.surveyData = newSurveyData;
}

const surveyData = parseBoolProperties(window.surveyData || {});
const questionBlocks = getAllBlocks(surveyData);
defineTranslations(window.surveyData.translations);
setFeatureFlags(window.surveyData);
const questionNormalizer = new QuestionNormalizer();
const navButtonsNormalizer = new NavigationButtonsNormalizer();

// Deprecation pending: usage of surveyData variable. Start using surveyDataStore instead
let normalizedData = questionNormalizer.normalize({ rawData: surveyData, questionBlocks });
normalizedData = navButtonsNormalizer.normalize({ rawData: surveyData, normalizedData });
normalizedData.pageSettings = getPageSettingsInitialState(surveyData);
normalizedData.surveyStatus = getSurveyStatusInitialState();

// New store slices are created from survey data store **/
const surveyDataStore = createProvider(questionBlocks, surveyData.cookieNotice);
normalizedData.cookieBanner = getCookieBannerInitialState(surveyDataStore.cookieBanner);

const store = createStoreWithInitialState(normalizedData);
IPCService.setStore(store).setFieldsService(FieldsService);
FieldsService.setStore(store).setIPCService(IPCService);
ValidationsService.setStore(store).init();

if (process.env.NODE_ENV !== 'production') {
  const queryString = window.location.search;
  const a11yFlag = queryString.includes('a11y');
  if (a11yFlag) {
    const a11y = require('react-a11y');
    a11y(React, { ReactDOM });
  }
}

MediaQueryWatcher.watch(store);
const $survey = document.getElementById('survey');
TouchDetector.detectAndAddHasTouchClassNameToDomNode($survey);
ReactDOM.render(
  <App questionBlocks={questionBlocks} surveyData={surveyData} store={store} />,
  $survey,
  () => {
    trackPageStats();
    if (isSurveyAPIEnabled()) {
      setStore(store);
      surveyIsReady();
    }
  }
);
