import PropTypes from 'prop-types';
import React from 'react';

import styles from './consentDetails.scss';

const ConsentDetails = ({ title, value }) => (
  <p className={styles.wrapper} key={title}>
    <span className={styles.title} dangerouslySetInnerHTML={{ __html: `${title}:` }} />
    <span dangerouslySetInnerHTML={{ __html: value }} />
  </p>
);

ConsentDetails.defaultProps = {
  value: ''
};

ConsentDetails.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string
};

export default ConsentDetails;
