import classNames from 'classnames';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import IndicatorCheckbox from '@commons/IndicatorCheckbox';
import IndicatorCircle from '@commons/IndicatorCircle';
import IndicatorRadio from '@commons/IndicatorRadio';
import IndicatorStar from '@commons/IndicatorStar';
import Input from '@commons/Input';

import { isAdvancedAccessibilityEnabled } from '@services/featureFlags';

import styles from './cellOption.scss';

const { any, bool, func, number, string } = PropTypes;

class CellOption extends PureComponent {
  static propTypes = {
    id: string,
    answerQuestion: func,
    ariaLabel: string,
    ariaPosInSet: number,
    ariaSetSize: number,
    ariaDescribedBy: string,
    caption: string,
    containerStyles: string,
    hasAnswer: bool,
    htmlInput: string,
    htmlInputFormKey: string,
    indicatorContainerStyles: string,
    isNaOption: bool,
    isRequired: bool,
    shouldDisplayAsSelected: bool,
    question: number,
    showNumbers: bool,
    showStars: bool,
    isMultiValued: bool,
    value: any
  };

  constructor(props) {
    super(props);
    const debouncedHandlerBecauseSomeBrowsersFiringEventTwiceForOnChange = debounce(
      this.handleOnChangeAnswerQuestion.bind(this),
      1
    );
    this.handleOnChangeAnswerQuestion =
      debouncedHandlerBecauseSomeBrowsersFiringEventTwiceForOnChange;
  }

  handleOnChangeAnswerQuestion() {
    const { id, htmlInput, question, value, answerQuestion } = this.props;

    answerQuestion({
      htmlInputId: htmlInput,
      optionId: id,
      questionId: question,
      value
    });
  }

  render() {
    const {
      ariaLabel,
      ariaPosInSet,
      ariaSetSize,
      ariaDescribedBy,
      caption,
      containerStyles,
      hasAnswer,
      htmlInput,
      htmlInputFormKey,
      indicatorContainerStyles,
      isNaOption,
      isRequired,
      shouldDisplayAsSelected,
      showNumbers,
      showStars,
      isMultiValued,
      value
    } = this.props;

    const isStarsEnabled = !isNaOption && showStars;

    const mainContainerStyles = classNames(styles.cellOptionContainer, containerStyles, {
      [styles.starScale]: isStarsEnabled
    });

    const cellIndicatorContainerStyles = classNames(
      styles.indicatorContainer,
      indicatorContainerStyles,
      {
        [styles.indicatorContainer_isNaOption]: isNaOption
      }
    );

    const indicatorStyles = classNames({
      [styles.indicator_isRadio]: !showNumbers && !isStarsEnabled && !isMultiValued,
      [styles.indicator_isCheckbox]: isMultiValued,
      [styles.indicator_isStar]: isStarsEnabled
    });

    const Indicator = isStarsEnabled
      ? IndicatorStar
      : showNumbers
      ? IndicatorCircle
      : isMultiValued
      ? IndicatorCheckbox
      : IndicatorRadio;

    const ariaRequired =
      isRequired && !isAdvancedAccessibilityEnabled() ? { ariaRequired: true } : {};
    return (
      <div
        className={mainContainerStyles}
        data-has-answer={hasAnswer}
        data-should-display-as-selected={shouldDisplayAsSelected}
      >
        <Input
          isCheckbox={isMultiValued}
          isRadio={!isMultiValued}
          ariaLabel={ariaLabel}
          {...ariaRequired}
          ariaDescribedBy={ariaDescribedBy}
          checked={hasAnswer}
          containerStyles={styles.input}
          formKey={htmlInputFormKey || htmlInput}
          formValue={value}
          onClick={this.handleOnChangeAnswerQuestion}
          dataRepresentativeValue={caption}
        />
        <div aria-hidden className={cellIndicatorContainerStyles}>
          <Indicator
            containerStyles={indicatorStyles}
            caption={caption}
            isSelected={hasAnswer || shouldDisplayAsSelected}
          />
        </div>
      </div>
    );
  }
}

export default CellOption;
