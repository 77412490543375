import { NODE_TYPES } from '@services/conditionGenerator/constants';

function constantGenerator(value) {
  return {
    type: NODE_TYPES.CONSTANT,
    value: `${value}`
  };
}

export default constantGenerator;
