import Question from '@models/question';

import { ANSWER_QUESTION } from '@redux/actions/answerQuestion';

import hasPayloadAndResourcesAreLinked from '@utils/hasPayloadAndResourcesAreLinked';

import { QuestionsState } from './types';

export function determineQuestionHasAnswer({ htmlInputsWithAnswer, optionsWithAnswer }) {
  if (htmlInputsWithAnswer) return htmlInputsWithAnswer.size > 0;
  if (optionsWithAnswer) return optionsWithAnswer.size > 0;
  return false;
}

export function determineQuestionIsAnswered({
  question,
  optionsWithAnswer,
  htmlInputsWithAnswer,
  htmlInputs
}) {
  if (question.isLongformType() || question.isNestedDropdownLongformType()) {
    // question does not have Other option with textfield
    if (htmlInputsWithAnswer.size === 1) return determineQuestionHasAnswer({ optionsWithAnswer });

    // question has Other option with textfield so we expect 2 htmlInputsWithAnswer
    const idsOfHtmlInputsWithAnswer = [...htmlInputsWithAnswer];
    for (let i = 0; i < idsOfHtmlInputsWithAnswer.length; i++) {
      const id = idsOfHtmlInputsWithAnswer[i];
      const htmlInput = htmlInputs[id];
      if (htmlInput.isBooleanAnswer() && !htmlInput.hasValue()) return false;
      if (htmlInput.isTextAnswer()) return htmlInput.hasValue();
    }
  }

  if (question.isCalendarDatePickerType()) {
    return determineQuestionHasAnswer({ htmlInputsWithAnswer });
  }

  /**
   * This is the check for the following questions:
   *   question.isRatingGridType()
   *   question.isTextareaType()
   *   question.isTextfieldType()
   */
  return optionsWithAnswer.size === question.options.size;
}

export default function questions(state: QuestionsState = {}, action): QuestionsState {
  if (!hasPayloadAndResourcesAreLinked(state, action)) return state;
  switch (action.type) {
    case ANSWER_QUESTION: {
      const { question, htmlInputs, options } = action.payload;
      const htmlInputsWithAnswer = new Set();
      const optionsWithAnswer = new Set();

      question.htmlInputs.forEach((id) => {
        const htmlInput = htmlInputs[id];
        if (htmlInput.hasValue()) htmlInputsWithAnswer.add(htmlInput.id);
      });

      question.options.forEach((id) => {
        const option = options[id];
        if (option.hasAnswer) optionsWithAnswer.add(option.id);
      });

      /**
       * hasAnswer means that Question has at least one htmlInput with an answer
       */
      const hasAnswer = determineQuestionHasAnswer({ htmlInputsWithAnswer });

      /**
       * isAnswered is when a Question is considered 'complete' with all answers
       * based on its type. For example: rating grid with 3 rows requires all
       * 3 rows to have an answer to be considered isAnswered === true;
       */
      const isAnswered = determineQuestionIsAnswered({
        htmlInputs,
        question,
        optionsWithAnswer,
        htmlInputsWithAnswer
      });

      const newQuestion = new Question({
        ...question,
        htmlInputsWithAnswer,
        optionsWithAnswer,
        hasAnswer,
        isAnswered
      });

      return {
        ...state,
        [newQuestion.id]: newQuestion
      };
    }
    default:
      return state;
  }
}
