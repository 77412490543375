import classNames from 'classnames';
import React from 'react';

import styles from '@assets/fonts/medallicons.scss';

//----------------------------------------------------------------------
// CONFIG
//----------------------------------------------------------------------

export const ICON_TYPES = {
  ARROW_DROPDOWN: '',
  ARROW_LEFT: '',
  ARROW_RIGHT: '',
  ARROW_UP: '',
  ARROW_UP_CHEVRON: '\u2303',
  CALENDAR: '',
  CHECKBOX: '',
  CLOSE: '',
  FILE_AUDIO: '',
  FILE_EXCEL: '',
  FILE_GENERIC: '',
  FILE_IMAGE: '',
  FILE_PDF: '',
  FILE_POWER_POINT: '',
  FILE_VIDEO: '',
  FILE_WORD: '',
  FILE_ZIP: '',
  INFORMATION: '',
  POWERED_BY_MEDALLIA: '',
  SEARCH: (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
      <path
        fill="#7A8394"
        fillRule="evenodd"
        d="M10.681 9.969a6.058 6.058 0 1 0-.713.713L15.287 16l.713-.713-5.319-5.318Zm-4.618 1.135a5.042 5.042 0 1 1 5.041-5.041 5.048 5.048 0 0 1-5.04 5.04Z"
        clipRule="evenodd"
      />
    </svg>
  ),
  STAR: '',
  TRASH_BIN: ''
};

//----------------------------------------------------------------------
// TYPES
//----------------------------------------------------------------------

interface Props {
  type: JSX.Element;
  ariaHidden: boolean;
  containerStyles: string;
  shouldRender: boolean;
  ariaLabel: string;
}

//----------------------------------------------------------------------
// COMPONENT
//----------------------------------------------------------------------

export default function Icon(props: Props) {
  if (!props.shouldRender) return null;
  const { type, ariaHidden, containerStyles, ariaLabel } = props;
  const iconStyles = classNames(styles.medallicons, containerStyles);

  if (ariaHidden && ariaLabel) {
    return (
      <span>
        <span className={styles.medalliconLabel}>{ariaLabel}</span>
        <i aria-hidden={ariaHidden} className={iconStyles}>
          {type}
        </i>
      </span>
    );
  }

  return (
    <i aria-hidden={ariaHidden} className={iconStyles}>
      {type}
    </i>
  );
}

Icon.defaultProps = {
  ariaHidden: true,
  shouldRender: true
};
