import evaluateNode from '@services/conditionEngine';
import { NODE_TYPES } from '@services/conditionEngine/constants';
import evaluatorRegistry from '@services/conditionEngine/evaluators/registry';

import SET_OPERATIONS from './constants';

const OPERATIONS = {
  [SET_OPERATIONS.ALL]: (fieldValues, conditionValues) =>
    conditionValues.every((item) => fieldValues.includes(item)),
  [SET_OPERATIONS.ANY]: (fieldValues, conditionValues) =>
    conditionValues.some((item) => fieldValues.includes(item)),
  [SET_OPERATIONS.EXACTLY]: (fieldValues, conditionValues) =>
    conditionValues.length === fieldValues.length &&
    conditionValues.every((item) => fieldValues.includes(item))
};

function multiValuedSetConditionEvaluator(node, values) {
  const { op, left, right } = node;
  const fieldValues = evaluateNode(left, values);
  const conditionValues = evaluateNode(right, values);
  const operationFn = OPERATIONS[op];

  if (!operationFn) throw new Error('Invalid operation type');

  return Array.isArray(fieldValues) && operationFn(fieldValues, conditionValues);
}

evaluatorRegistry.registerEvaluatorForNodeType(
  NODE_TYPES.MULTI_VALUED_SET,
  multiValuedSetConditionEvaluator
);
export default multiValuedSetConditionEvaluator;
