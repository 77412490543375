import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Icon, { ICON_TYPES } from '@commons/Icon';

import { getFileSizeLabel, getFormData } from '@components/FileUpload/helpers/file';

import { getTranslation } from '@services/translations';

import styles from './uploadinginfo.scss';

export default class UploadingInfo extends PureComponent {
  constructor(props) {
    super(props);

    this.cancelFileLabel = getTranslation('survey.FILE_UPLOAD_UPLOAD_FILE_CANCEL');
    this.deleteFileLabel = getTranslation('survey.FILE_UPLOAD_UPLOAD_FILE_DELETE');
    this.uploadingFileLabel = getTranslation('survey.FILE_UPLOAD_UPLOAD_FILE_PROGRESS_UPLOADING');

    this.handleDeleteFile = this.handleDeleteFile.bind(this);
  }

  getDeleteIcon() {
    return this.props.file.isUploading ? ICON_TYPES.CLOSE : ICON_TYPES.TRASH_BIN;
  }

  getDocumentIcon() {
    const { filename } = this.props.file;
    let extension = filename.match(/(\.\w+)$/);
    extension = extension ? extension[0] : '';

    switch (extension.toLowerCase()) {
      case '.pdf':
        return ICON_TYPES.FILE_PDF;
      case '.gif':
      case '.jpeg':
      case '.jpg':
      case '.png':
      case '.webp':
        return ICON_TYPES.FILE_IMAGE;
      case '.mp3':
      case '.mp4':
      case '.wav':
        return ICON_TYPES.FILE_AUDIO;
      case '.csv':
      case '.xls':
      case '.xlsx':
        return ICON_TYPES.FILE_EXCEL;
      case '.pps':
      case '.ppsx':
      case '.ppt':
      case '.pptx':
        return ICON_TYPES.FILE_POWER_POINT;
      case '.mov':
        return ICON_TYPES.FILE_VIDEO;
      case '.doc':
      case '.docx':
      case '.txt':
        return ICON_TYPES.FILE_WORD;
      default:
        return ICON_TYPES.FILE_GENERIC;
    }
  }

  handleDeleteFile(e) {
    e.stopPropagation();
    e.preventDefault();

    this.props.onFileDelete(this.props.file);
  }

  render() {
    const { file, fields } = this.props;
    const { deletedOn, filename, isUploading, uploadedOn, uploadProgress } = file;

    const deleteIcon = this.getDeleteIcon();
    const documentIcon = this.getDocumentIcon();

    const progressBarContainerStyle = classNames(styles.progressContainer, {
      [styles.progressContainer_isDone]: !isUploading
    });

    const containerStyle = classNames(styles.container, {
      [styles.container_fileIsDeleted]: !!deletedOn
    });

    const hasFormData = !!(uploadedOn || deletedOn);

    const progressLabel = `${this.uploadingFileLabel}: ${uploadProgress * 100}%`;
    const actionButtonLabel = isUploading ? this.cancelFileLabel : this.deleteFileLabel;
    const filenameLabel = `${filename} (${getFileSizeLabel(file)})`;

    return (
      <li className={containerStyle}>
        {hasFormData &&
          getFormData(file, fields).map(([name, value]) => (
            <input key={file.order + name} hidden readOnly name={name} value={value || ''} />
          ))}
        <div className={styles.contentContainer}>
          <Icon type={documentIcon} containerStyles={styles.documentIcon} />
          <span className={styles.fileName}>{filenameLabel}</span>
        </div>
        <div className={styles.contentContainer}>
          <div className={progressBarContainerStyle}>
            <div
              className={styles.progressBar}
              style={{ transform: `scaleX(${file.uploadProgress})` }}
            />
            <span className={styles.isHiddenForAria} aria-live="polite">
              {progressLabel}
            </span>
          </div>
          <button className={styles.closeButton} onClick={this.handleDeleteFile}>
            <Icon
              ariaHidden={false}
              ariaLabel={actionButtonLabel}
              containerStyles={styles.closeIcon}
              type={deleteIcon}
            />
          </button>
        </div>
      </li>
    );
  }
}

UploadingInfo.propTypes = {
  file: PropTypes.shape({
    isUploading: PropTypes.bool,
    filename: PropTypes.string
  }).isRequired,
  fields: PropTypes.shape({ [PropTypes.string]: PropTypes.string }),
  onFileDelete: PropTypes.func.isRequired
};

UploadingInfo.defaultProps = {
  fields: {}
};
