/* eslint-disable global-require */
/**
 * https://github.com/gaearon/redux-devtools/blob/master/docs/Walkthrough.md#exclude-devtools-from-production-builds
 *
 * Exclude DevTools from Production Builds
 *
 * Finally, to make sure we’re not pulling any DevTools-related code in the production builds,
 * we will envify our code. You can use DefinePlugin with Webpack, or envify for Browserify.
 *
 * The trick is to replace all occurrences of a constant like process.env.NODE_ENV
 * into a string depending on the environment, and import and render redux-devtools
 * only when process.env.NODE_ENV is not 'production'. Then, if you have an Uglify
 * step before production, Uglify will eliminate dead if (false) branches with
 * redux-devtools imports.
 */

if (process.env.NODE_ENV === 'development') {
  require('preact/debug');
}

if (process.env.NODE_ENV === 'production') {
  module.exports = require('./index.prod');
} else {
  module.exports = require('./index.dev');
}
