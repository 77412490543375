import isNil from 'lodash/isNil';
import { Store } from 'redux';

import { shouldValidatorPass } from '@models/questionNormalizer/normalizeHelpers';

import resetComponentValidations from '@redux/actions/resetComponentValidations';
import setComponentValidation from '@redux/actions/setComponentValidation';
import setComponentValidations from '@redux/actions/setComponentValidations';

import IPCService from '@services/inPageConditioning';

//  ----------------------------------------------------------------------
//  TYPES
//  ----------------------------------------------------------------------

export type ValidationsServiceSeverity = 'error' | 'warning';

export interface ValidatorFunction {
  predicate: (componentId: number, store: any) => boolean;
  shouldValidatorPass: typeof shouldValidatorPass;
  severity: ValidationsServiceSeverity;
  retries: number;
  message: string;
}

//  ----------------------------------------------------------------------
//  CORE
//  ----------------------------------------------------------------------

export default class ValidationsService {
  store: Store<unknown> | null;
  components: Map<number, Array<ValidatorFunction>>;

  constructor() {
    this.store = null;
    this.components = new Map();
  }

  setStore(store) {
    this.store = store;
    return this;
  }

  init() {
    const { dispatch } = this.store;
    const components = Array.from(this.components.entries());

    components.forEach((component) => {
      const [id, validators] = component;
      const initialState = validators.map((validation) => ({
        retries: validation.retries
      }));

      setComponentValidations(id, initialState)(dispatch);
    });
  }

  subscribeComponent(componentId: number, validators: Array<ValidatorFunction>) {
    this.components.set(componentId, validators);
  }

  getInstanceForComponent(componentId: number) {
    return this.components.get(componentId);
  }

  resetValidationsForComponent(componentId: number) {
    const { dispatch, getState } = this.store;
    resetComponentValidations(componentId)(dispatch, getState);
  }

  componentConformsToValidation(componentId: number, validationId: number) {
    const { components } = this.store.getState();

    const defaultRetries = this.components.get(componentId)![validationId].retries;
    const currentRetries = components[componentId].validations[validationId].retries;

    return defaultRetries === currentRetries;
  }

  /**
   * Returns whether a given component has failing validations or not and updates the store.
   * - A falsy return here means no validation concerns were found.
   * - A truthy result means it's going to be included in the array returned by `runAllValidations`.
   */
  runComponentValidations(componentId: number) {
    const { dispatch } = this.store;

    // For now, we don't want to run validations for components that are not visible to the user.
    const isComponentHidden = !IPCService.shouldShowComponent(componentId);
    if (isComponentHidden) {
      return false;
    }

    const storeValidations = this.store.getState().components[componentId].validations;
    const instanceValidations = this.components.get(componentId);

    return instanceValidations.some((validator: ValidatorFunction, validationId: number) => {
      const { predicate, shouldValidatorPass, severity } = validator;

      const predicateResult = predicate(componentId, this.store);
      const storeValidation = storeValidations[validationId];

      if (!isNil(storeValidation)) {
        const { retries } = storeValidation;
        const isPassing = shouldValidatorPass(predicateResult, severity, retries);

        if (!isPassing) {
          setComponentValidation(componentId, validationId, {
            retries: retries - 1
          })(dispatch);
        }

        return !isPassing;
      }

      return false;
    });
  }

  runAllValidations() {
    return Array.from(this.components.keys()).filter((componentId) =>
      this.runComponentValidations(componentId)
    );
  }
}
