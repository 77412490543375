/* eslint-disable max-depth */
import {
  MAPPING_OF_WRAPPER_TO_CHILDREN,
  QUESTION_BLOCK_TYPES,
  WRAPPING_BLOCK_TYPES
} from './constants';
import normalizeEnvelope from './normalizeEnvelope';

// getSurveyBlocksFromData function internally calls normalizeEnvelope function
// (utils/getAllBlocks/normalizeEnvelope) which uses a global variable to assign ids.
// So unless normalizeEnvelope is mocked, resetId should always be called
// in test contexts after calling getSurveyBlocksFromData()
export default function getAllBlocks(envelope, memo = []) {
  if (!envelope) return memo;

  if (envelope.constructor === Array) {
    for (let i = 0; i < envelope.length; i++) {
      getAllBlocks(envelope[i], memo, normalizeEnvelope);
    }
  } else if (envelope.constructor === Object) {
    const { blockType } = envelope;
    if (QUESTION_BLOCK_TYPES.indexOf(blockType) >= 0) {
      const normalizedEnvelope = normalizeEnvelope(envelope);
      memo.push(normalizedEnvelope);
      return memo;
    }
    if (WRAPPING_BLOCK_TYPES.indexOf(blockType) >= 0) {
      const isLastPageBlockWithoutAnyParts =
        blockType === 'page' && (envelope.parts || []).length === 0 && envelope.caption;
      if (isLastPageBlockWithoutAnyParts) {
        memo.push({
          blockType: 'text',
          caption: envelope.caption,
          surveyType: 'terminalPage'
        });
      } else {
        const wrappedChildren = envelope[MAPPING_OF_WRAPPER_TO_CHILDREN[blockType]];
        if (Array.isArray(wrappedChildren)) {
          wrappedChildren.forEach((child, index) => {
            if (envelope.condition) {
              wrappedChildren[index].condition = envelope.condition;
            }
          });
        }

        if (wrappedChildren && envelope.surveyNodeType) {
          for (let i = 0; i < wrappedChildren.length; i++) {
            if (wrappedChildren[i].blockType === 'text') {
              wrappedChildren[i].surveyType = envelope.surveyNodeType;
            }
          }
        }
        return getAllBlocks(wrappedChildren, memo);
      }
    }
  }
  return memo;
}
