import evaluateNode from '@services/conditionEngine';
import { NODE_TYPES } from '@services/conditionEngine/constants';
import evaluatorRegistry from '@services/conditionEngine/evaluators/registry';

function inSetEvaluator(node, values) {
  const fieldValue = evaluateNode(node.left, values);
  const set = evaluateNode(node.right, values);

  return set.includes(fieldValue);
}

evaluatorRegistry.registerEvaluatorForNodeType(NODE_TYPES.IN_SET, inSetEvaluator);
export default inSetEvaluator;
