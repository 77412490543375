import PropTypes from 'prop-types';
import React from 'react';

import CookieBannerPanel from '@components/CookieBanner/shared/CookieBannerPanel';
import {
  COOKIE_BANNER_VERSION_V3,
  createNavigationLink
} from '@components/CookieBanner/shared/utils';
import { propTypes as StackNavigatorPropTypes } from '@components/StackNavigator/utils';

import styles from './cookieCategoryDetails.scss';

function CookieCategoryDetails(props) {
  const { children, category, navigation, linkText } = props;

  return (
    <div className={styles.wrapper}>
      <p className={styles.text}>{children}</p>

      <CookieBannerPanel.Link
        version={COOKIE_BANNER_VERSION_V3}
        onClick={createNavigationLink(navigation, category)}
      >
        {linkText}
      </CookieBannerPanel.Link>
    </div>
  );
}

CookieCategoryDetails.propTypes = {
  category: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  navigation: StackNavigatorPropTypes.navigation.isRequired,
  linkText: PropTypes.string.isRequired
};

export default CookieCategoryDetails;
