import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { getOpenerContent, isItemSelected } from '@commons/Dropdown/Simple/common';
import { dropdownListType, selectedType } from '@commons/Dropdown/Simple/types';
import styles from '@commons/Dropdown/dropdown.scss';
import Icon, { ICON_TYPES } from '@commons/Icon';
import Modal from '@commons/Modal';

import layoutStyles from '@css/layout.scss';

import { isIosDropdownOpeningWhenKeyboardShownFixEnabled } from '@services/featureFlags';

import { isSafariMobile } from '@utils/browserAgent';
import { afterSafariKeyboardCloses } from '@utils/iosDisplay';
import scrollTop from '@utils/scrollTop';
import stripHtml from '@utils/stripHtml';

import MobileDropdownItem from './MobileItem';

const { bool, func, string } = PropTypes;

class MobileDropdown extends Component {
  static propTypes = {
    label: string,
    selected: selectedType,
    dropdownList: dropdownListType,
    disabled: bool,
    selectItem: func.isRequired,
    isMultiValued: bool,
    modalHeaderStyles: string
  };

  static defaultProps = {
    label: '',
    selected: null,
    dropdownList: [],
    disabled: false,
    isMultiValued: false,
    modalHeaderStyles: ''
  };

  static contextTypes = {
    translations: PropTypes.object
  };

  state = { scrollTop: 0, listInvisible: true };

  handleShowMobile = () => {
    if (this.props.disabled) return;
    if (!isSafariMobile() || !isIosDropdownOpeningWhenKeyboardShownFixEnabled()) {
      return this.openModal();
    }
    return afterSafariKeyboardCloses().then(this.openModal);
  };

  openModal = () => {
    this.setState({ scrollTop: scrollTop(), listInvisible: false });
  };

  handleHide = () => {
    this.setState({ listInvisible: true });
  };

  handleItemClick = () => {
    const { isMultiValued } = this.props;

    if (!isMultiValued) {
      this.handleHide();
    }
  };

  getCloseButtonLabel = () => {
    const closeTranslation =
      this.context && this.context.translations && this.context.translations.TA_CLOSE;
    return closeTranslation || 'Close';
  };

  render() {
    const {
      isDropdownLabelInactive,
      label,
      dropdownList,
      title,
      containerStyles,
      selectorStyles,
      selectItem,
      calendarTitleStyles,
      calendarIconStyles,
      ariaRequired,
      ariaDisabled,
      ariaLabel,
      ariaInvalid,
      disabled,
      selected,
      isMultiValued,
      modalHeaderStyles
    } = this.props;

    const labelClasses = classNames(styles.dropdownLabel, {
      [styles.dropdownLabel_isInactive]: isDropdownLabelInactive
    });

    const selectorClasses = classNames(
      styles.dropdownSelector,
      styles.dropdownSelector_isInactive,
      {
        [styles.dropdownSelector_isDisable]: disabled
      }
    );

    return (
      <div className={layoutStyles.mobileScreenOnly}>
        {label && <div className={labelClasses}>{label}</div>}
        <div className={`${styles.dropdownContainer} ${containerStyles}`}>
          <div
            className={classNames(selectorClasses, selectorStyles)}
            onClick={this.handleShowMobile}
            title={stripHtml(title)}
            aria-haspopup="true"
            aria-expanded={!this.state.listInvisible}
            role="button"
            tabIndex="0"
            {...ariaInvalid}
            {...ariaRequired}
            {...ariaLabel}
            {...ariaDisabled}>
            <div className={calendarTitleStyles}>{getOpenerContent(this.props)}</div>
            <Icon containerStyles={calendarIconStyles} type={ICON_TYPES.ARROW_DROPDOWN} />
          </div>
          {!this.state.listInvisible && (
            <Modal
              onClickHide={this.handleHide}
              scrollTop={this.state.scrollTop}
              label={label}
              cancelButtonLabel={this.getCloseButtonLabel()}
              modalHeaderStyles={modalHeaderStyles}>
              <ul
                role="menu"
                className={`${styles.dropdownList} ${styles.dropdownList_isActive}`}
                onClick={this.handleItemClick}>
                {dropdownList.map((item) => (
                  <MobileDropdownItem
                    key={item.id}
                    selected={isItemSelected({ itemId: item.id, isMultiValued, selected })}
                    selectItem={selectItem}
                    item={item}
                    isMultiValued={isMultiValued}
                  />
                ))}
              </ul>
            </Modal>
          )}
        </div>
      </div>
    );
  }
}

export default MobileDropdown;
