import PropTypes from 'prop-types';
import React from 'react';

import Day from '@commons/CalendarDatePicker/Day';

import styles from './week.scss';

export default function Week(props) {
  const {
    ariaLabelForSelected,
    days,
    focusThisDay,
    inverseLeftRightArrowMovement,
    notInTheFuture,
    onDayClick,
    onDayTabPress,
    onKeyPressFocusDay,
    selectedValue,
    tabbableDay,
    today
  } = props;

  const daysInOneWeek = [];
  for (let day = 0; day <= 6; day++) {
    const dayProps = days[day];
    const value = dayProps ? dayProps.value : null;
    daysInOneWeek.push(
      <Day
        key={day}
        {...dayProps}
        ariaLabelForSelected={ariaLabelForSelected}
        focusThisDay={focusThisDay}
        inverseLeftRightArrowMovement={inverseLeftRightArrowMovement}
        isSelected={selectedValue === value}
        notInTheFuture={notInTheFuture}
        onDayClick={onDayClick}
        onDayTabPress={onDayTabPress}
        onKeyPressFocusDay={onKeyPressFocusDay}
        tabbableDay={tabbableDay}
        today={today}
      />
    );
  }

  return <tr className={styles.week}>{daysInOneWeek}</tr>;
}

const { arrayOf, bool, func, number, shape, string } = PropTypes;

Week.propTypes = {
  ariaLabelForSelected: string,
  days: arrayOf(
    shape({
      ariaLabel: string,
      day: number,
      value: string
    })
  ),
  focusThisDay: number,
  inverseLeftRightArrowMovement: bool,
  notInTheFuture: bool,
  onDayClick: func,
  onDayTabPress: func,
  onKeyPressFocusDay: func,
  selectedValue: string,
  tabbableDay: number,
  today: shape({
    year: number,
    month: number,
    day: number
  })
};

Week.defaultProps = {
  days: Array(7)
};
