/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React from 'react';

import styles from './cookieBannerPanel.scss';

const CookieBannerPanel = ({ children }) => <div className={styles.wrapper}>{children}</div>;

CookieBannerPanel.propTypes = {
  children: PropTypes.node.isRequired
};

export default CookieBannerPanel;
