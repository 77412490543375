import { isEqual } from 'lodash';

import HtmlInput from '@models/htmlInput';
import { QUESTION_TYPES } from '@models/question';

export default function hidden(state, action, htmlInput) {
  let newValue;

  const nextValue = action.payload.value;
  const currValue = htmlInput.value;

  // MPC-7849 Option to Make Video/Audio Question Required
  // The previous solution was causing undesired resetting of the error state.
  if (QUESTION_TYPES.MULTIMEDIA_FEEDBACK || isEqual(nextValue, currValue)) {
    newValue = nextValue;
  }

  const newHtmlInput = new HtmlInput({ ...htmlInput, value: newValue });
  return {
    ...state,
    [newHtmlInput.id]: newHtmlInput
  };
}
