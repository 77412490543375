import { connect } from 'react-redux';
import { compose } from 'redux';

import withCondition from '@commons/withCondition';

import setComponentAnswer from '@redux/actions/setComponentAnswer';

import TextField from './TextField';

export const mapDispatchToProps = (dispatch, { fieldName, id }) => ({
  setComponentAnswer: (value, extraParams = {}) =>
    dispatch(setComponentAnswer(value, id, fieldName, extraParams))
});

export default compose(withCondition, connect(null, mapDispatchToProps))(TextField);
