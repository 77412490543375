/* eslint-disable react/forbid-component-props */
import { ResizeObserver } from '@juggle/resize-observer';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

import { ConditionedCell } from '@commons/Table/Cell';
import Row from '@commons/Table/Row';

import SubComponentCell from '@components/Matrix/SubComponentCell';

import styles from './rowWrapper.scss';

class RowWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.resizeRowCaption = this.resizeRowCaption.bind(this);
    this.rowObserver = new ResizeObserver(this.resizeRowCaption);
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-find-dom-node
    const node = ReactDOM.findDOMNode(this);
    this.rowObserver.observe(node);
  }

  componentWillUnmount() {
    this.rowObserver.disconnect();
  }

  resizeRowCaption(data) {
    const { id, questionId } = this.props;
    const rowCaptionId = `desktopRowTitle_${questionId}_${id}`;
    const anchorsRowHeight = document.querySelector(`.internalAnchor_row_${id}`).offsetHeight;
    const rowHeight = data[0].target.offsetHeight;
    const rowCaption = document.getElementById(rowCaptionId);
    if (rowHeight && rowCaption) {
      rowCaption.style.height = `${rowHeight}px`;
      rowCaption.style.marginTop = `${anchorsRowHeight}px`;
    }
  }

  render() {
    const { id, questionId, cells, caption, isMobileMedia, questionCaption } = this.props;

    const containerStyles = classNames(styles.cell, {
      [styles.mobileCellQuestion]: isMobileMedia
    });
    const elements = cells.map((cell, idx) => {
      const {
        piece: { fieldName },
        columnId
      } = cell;

      return (
        <ConditionedCell
          key={`subcomponentContainer_${id}_${columnId}`}
          id={columnId}
          containerStyles={containerStyles}>
          <SubComponentCell
            id={fieldName}
            questionId={questionId}
            columnIndex={idx}
            component={cell.piece}
            isMobileMedia={isMobileMedia}
            rowCaption={caption}
            questionCaption={questionCaption}
          />
        </ConditionedCell>
      );
    });

    return <Row id={`rowWrapper_${id}`}>{elements}</Row>;
  }
}

const { bool, object, arrayOf, number } = PropTypes;

RowWrapper.propTypes = {
  id: number.isRequired,
  questionId: number.isRequired,
  isMobileMedia: bool,
  cells: arrayOf(object).isRequired
};

RowWrapper.defaultProps = {
  isMobileMedia: false
};

export default RowWrapper;
