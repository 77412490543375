/* global document window */

/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Icon, { ICON_TYPES } from '@commons/Icon';

import { isTab } from '@utils/detectKey';

import styles from './tooltip.scss';

const { bool, string } = PropTypes;

const VERTICAL_SPACING = 4;

const hiddenStyle = {
  position: 'fixed',
  left: '100%',
  opacity: '0'
};

const shownStyle = {
  position: 'absolute',
  opacity: '1'
};

let ctx;

export default class Tooltip extends PureComponent {
  static propTypes = {
    alwaysVisibleForVoiceOver: bool,
    ariaHidden: bool,
    ariaLabel: string,
    caption: string,
    containerStyles: string,
    domId: string,
    imagePath: string,
    isTabbable: bool,
    shouldRender: bool
  };

  static defaultProps = {
    shouldRender: true
  };

  constructor(props) {
    super(props);
    this.state = { style: { ...hiddenStyle }, isPopupVisible: false };
    this.handleShowPopup = this.handleShowPopup.bind(this);
    this.handleHidePopup = debounce(this.handleHidePopup.bind(this), 250);
    this.handleCancelingHidePopup = this.handleCancelingHidePopup.bind(this);
    this.handleOnKeyUp = this.handleOnKeyUp.bind(this);
    this.setRefForContainer = this.setRefForContainer.bind(this);
    this.setRefForContentContainer = this.setRefForContentContainer.bind(this);

    if (!ctx) {
      ctx = document.createElement('canvas').getContext('2d');
      ctx.font = "18px 'Open Sans', Arial, sans-serif";
    }
  }

  setRefForContainer($el) {
    this._$container = $el;
  }

  setRefForContentContainer($el) {
    this._$contentContainer = $el;
  }

  handleOnKeyUp(e) {
    if (this.props.isTabbable && isTab(e)) this.showPopup();
  }

  handleHidePopup() {
    this.hidePopup();
  }

  handleShowPopup() {
    this.showPopup();
  }

  handleCancelingHidePopup() {
    this.handleHidePopup.cancel();
  }

  showPopup() {
    if (this.state.isPopupVisible) return;
    const style = { ...shownStyle };

    const boundingRect = this._$container.getBoundingClientRect();
    const contentDimensions = {
      height: this._$contentContainer.getBoundingClientRect().height,
      width: this._$contentContainer.getBoundingClientRect().width
    };
    style.top = this._calcVerticalPosition({ boundingRect, contentDimensions });
    style.left = this._calcHorizontalPosition({ boundingRect, contentDimensions });

    this.setState({ style, isPopupVisible: true });
  }

  hidePopup() {
    if (!this.state.isPopupVisible) return;
    this.setState({
      style: {
        ...this.state.style,
        ...hiddenStyle
      },
      isPopupVisible: false
    });
  }

  _calcHorizontalPosition({ boundingRect, contentDimensions }) {
    const hasEnoughSpaceOnRight = window.innerWidth - boundingRect.right > contentDimensions.width;
    if (hasEnoughSpaceOnRight) return boundingRect.width;

    const hasEnoughSpaceOnLeft = boundingRect.left > contentDimensions.width;
    if (hasEnoughSpaceOnLeft) return -contentDimensions.width;

    const centeredLeftPoint = window.innerWidth / 2 - boundingRect.left;
    return centeredLeftPoint - contentDimensions.width / 2;
  }

  _calcVerticalPosition({ boundingRect, contentDimensions }) {
    const topAvailableSpace = boundingRect.top; // add 2 for extra padding to default to top
    const topPosition = -(contentDimensions.height + VERTICAL_SPACING);

    if (topAvailableSpace > contentDimensions.height) return topPosition;

    const bottomAvailableSpace = window.innerHeight - boundingRect.top - boundingRect.height;
    const bottomPosition = boundingRect.height + VERTICAL_SPACING;

    return bottomAvailableSpace < contentDimensions.height ? -topAvailableSpace : bottomPosition;
  }

  render() {
    if (!this.props.shouldRender) return null;
    const {
      alwaysVisibleForVoiceOver,
      ariaHidden,
      ariaLabel,
      caption,
      containerStyles,
      domId,
      imagePath,
      isTabbable
    } = this.props;

    const tooltipContainerStyles = classNames(styles.tooltipContainer, containerStyles);
    const tooltipContentContainerStyles = classNames(styles.tooltipContentContainer, {
      [styles.tooltipContentContainer_isInvisible]:
        !alwaysVisibleForVoiceOver && !this.state.isPopupVisible
    });
    const tooltipContentStyles = classNames(styles.tooltipContent, {
      [styles.tooltipContent_isInvisible]: !alwaysVisibleForVoiceOver && !this.state.isPopupVisible
    });

    const style = this.state.style;
    if (!imagePath && caption) {
      const textWidth = ctx.measureText(this.props.caption).width;
      style.width = textWidth;
    }

    return (
      <span
        id={domId}
        ref={this.setRefForContainer}
        role="tooltip"
        aria-expanded={this.state.isPopupVisible}
        aria-haspopup="true"
        aria-hidden={ariaHidden}
        className={tooltipContainerStyles}
        onFocus={this.handleShowPopup}
        onMouseOver={this.handleShowPopup}
        onMouseLeave={this.handleHidePopup}
        onKeyUp={this.handleOnKeyUp}
        onBlur={this.handleHidePopup}
        tabIndex={isTabbable ? '0' : '-1'}>
        <Icon containerStyles={styles.tooltipIcon} type={ICON_TYPES.INFORMATION} />
        <div
          aria-label={ariaLabel}
          className={tooltipContentContainerStyles}
          ref={this.setRefForContentContainer}
          style={this.state.style}
          onMouseOver={this.handleCancelingHidePopup}
          onFocus={this.handleCancelingHidePopup}>
          {imagePath ? (
            <img className={tooltipContentStyles} src={imagePath} alt={caption} />
          ) : (
            <span className={tooltipContentStyles} dangerouslySetInnerHTML={{ __html: caption }} />
          )}
        </div>
      </span>
    );
  }
}
