import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import questionTitleStyles from '@commons/Question/question.scss';

import ValidationText from '@components/ValidationText';

import styles from '@css/layout.scss';

import stripHtml from '@utils/stripHtml';

export const OPT_OUT = 'optout';
export const EXPIRED = 'expired';
export const TERMINAL_PAGE = 'terminalPage';
export const COOKIE_CONFIRMATION = 'cookieConfirmation';
export const INVITATION_FOOTER = 'invitationErrorFooter';
export const TIMEOUT_PAGE = 'timeout-page';

export function determineCaption(caption, translations, surveyType, blockType) {
  if (OPT_OUT === surveyType) return translations.WILL_REMOVE + '</br>' + translations.APOLOGIZE;
  if (EXPIRED === surveyType) return translations.SURVEY_EXPIRED;
  if (TERMINAL_PAGE === surveyType) return caption;
  if (TIMEOUT_PAGE === blockType) {
    const timeoutLink =
      '<a href="https://surveysupport.medallia.com">' +
      translations.TIMEOUT_ISSUE_SUPPORT_CONTACT_LINK +
      '</a>' +
      translations.TIMEOUT_ISSUE_SUPPORT_CONTACT_AFTER_LINK;
    return (
      translations.TIMEOUT_MESSAGE +
      '</br>' +
      translations.TIMEOUT_ORIGINAL_LINK +
      '</br>' +
      translations.TIMEOUT_ISSUE_SUPPORT_CONTACT_BEFORE_LINK +
      timeoutLink
    );
  }
  if (caption !== null && typeof caption === 'object') {
    return caption.caption;
  }
  return caption;
}

export default function Text(props, context) {
  const blockType = props.blockType;
  let surveyType = props.surveyType;
  let linktext;
  if (props.caption !== null && typeof props.caption === 'object') {
    surveyType = props.caption.blockType;
    linktext = props.caption.linktext;
  }
  const translations = context.translations || {};
  const caption = determineCaption(props.caption, translations, surveyType, blockType);
  const validationFailed = Boolean(props.validationFailed);
  const isText = blockType === 'text' || blockType === TIMEOUT_PAGE || surveyType === TERMINAL_PAGE;
  const isQuestionText = blockType === 'questionText';

  const textNodeStyles = classNames({
    [`${styles.text} textNode`]: isText,
    [`${questionTitleStyles.questionTitle} questionTextNode`]: isQuestionText,
    optOutNode: OPT_OUT === surveyType,
    expirationNode: EXPIRED === surveyType,
    terminalPageNode: TERMINAL_PAGE === surveyType,
    [`${styles.yellowText} cookieConfirmationNode`]: COOKIE_CONFIRMATION === surveyType,
    [`${styles.yellowText} invitationErrorFooterNode`]: INVITATION_FOOTER === surveyType,
    timeoutPageNode: TIMEOUT_PAGE === blockType
  });

  /** This is for backwards compatibility. Before ValidationText,
   * errors would be send as blockType Text, with a parameter to style it as an error.
   * I left this in place, as a just in case anybody execute an old express instance.
   */

  const isValidationText = isText && validationFailed;
  if (isValidationText) {
    return <ValidationText caption={caption} />;
  }

  return (
    <div className={textNodeStyles}>
      <span dangerouslySetInnerHTML={{ __html: caption }} />
      {linktext && (
        <input
          type="submit"
          value={stripHtml(linktext)}
          name="invitation-error"
          className={styles.invitationErrorFooterLink + ' invitationErrorFooterLink'}
        />
      )}
    </div>
  );
}

const { string } = PropTypes;

Text.propTypes = {
  caption: string,
  blockType: string,
  surveyType: string,
  validationFailed: string
};

Text.defaultProps = {
  caption: '',
  blockType: 'text'
};

Text.contextTypes = {
  translations: PropTypes.object
};
