import { NODE_TYPES } from '@services/conditionGenerator/constants';

function fieldGenerator(field) {
  return {
    type: NODE_TYPES.FIELD,
    field
  };
}

export default fieldGenerator;
