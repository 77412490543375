import React from 'react';

import { getStore } from '@services/surveyDataProvider/provider';

const withData = (mapSurveyDataToProps) => (component) => {
  const WrappedComponent = component;

  class ConnectedHoC extends React.PureComponent {
    render() {
      const dataProps = mapSurveyDataToProps(getStore(), this.props);
      return <WrappedComponent {...dataProps} {...this.props} />;
    }
  }
  return ConnectedHoC;
};

export default withData;
