import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import ValidationMessage from '@commons/ValidationMessage';
import styles from '@commons/ValidationMessage/validation.scss';

export default function ValidationText(props) {
  const containerStyles = classNames('additionalValidationNode', {
    [styles.globalErrorBlock]: props.isGlobal
  });

  return (
    <div className={containerStyles}>
      <ValidationMessage isError caption={props.caption} />
    </div>
  );
}

Text.propTypes = {
  caption: PropTypes.string,
  isGlobal: PropTypes.bool
};

Text.defaultProps = {
  caption: '',
  isGlobal: false
};
