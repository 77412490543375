import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  isLivingLensUploadValidationEnabled,
  isA11yWfcEnhancementEnabled
} from '@services/featureFlags';

import Button, { BUTTON_STYLES } from '@commons/Button';

import layoutStyles from '@css/layout.scss';

import styles from './navigationButtons.scss';

const { number, string, shape, bool } = PropTypes;

const LAYOUT_STYLES = classNames(
  layoutStyles.layoutContainer,
  layoutStyles.layoutContainer_fadeIn
);
const MOVE_FORWARD_CLASS = '_moveForwardButton_';
const MOVE_FORWARD_CLASSES = [BUTTON_STYLES.PRIMARY, MOVE_FORWARD_CLASS].join(' ');

class NavigationButtons extends Component {
  static propTypes = {
    beginButton: shape({
      caption: string,
      domId: string,
      formKey: string
    }),
    backButton: shape({
      caption: string,
      domId: string,
      formKey: string
    }),
    nextButton: shape({
      caption: string,
      domId: string,
      formKey: string
    }),
    finishButton: shape({
      caption: string,
      domId: string,
      formKey: string
    }),
    submitButton: shape({
      caption: string,
      domId: string,
      formKey: string
    }),
    saveButton: shape({
      caption: string,
      domId: string,
      formKey: string
    }),
    numOfButtons: number,
    moveForwardIsLoading: bool
  };

  static contextTypes = {
    onSubmit: PropTypes.func
  };

  onClick = {
    ...(isLivingLensUploadValidationEnabled() && {
      onClickCallBack: event => this.context.onSubmit(event)
    })
  };

  buttonContainerStyles = classNames(
    styles.pageButtonContainer,
    {
      [styles.ltr]: isA11yWfcEnhancementEnabled()
    }
  );

  // BUTTONS

  renderBackButton() {
    return (<Button
      {... this.props.backButton}
      containerStyles={this.buttonContainerStyles}
      styling={BUTTON_STYLES.SECONDARY}
      showArrowIcon={false}
      {...this.onClick}
    />);
  }

  renderBeginButton() {
    return (<Button
      {...this.props.beginButton}
      containerStyles={this.buttonContainerStyles}
      showArrowIcon
      styling={MOVE_FORWARD_CLASSES}
      {...{ hasSpinner: this.props.moveForwardIsLoading }}
      {...this.onClick}
    />);
  }

  renderNextButton() {
    return (<Button
      {...this.props.nextButton}
      containerStyles={this.buttonContainerStyles}
      styling={MOVE_FORWARD_CLASSES}
      showArrowIcon
      {...{ hasSpinner: this.props.moveForwardIsLoading }}
      {...this.onClick}
    />);
  }

  renderFinishButton() {
    return (<Button
      {...this.props.finishButton}
      containerStyles={this.buttonContainerStyles}
      styling={MOVE_FORWARD_CLASSES}
      showArrowIcon
      {...{ hasSpinner: this.props.moveForwardIsLoading }}
      {...this.onClick}
    />);
  }

  renderSaveButton() {
    return (<Button
      {...this.props.saveButton}
      containerStyles={this.buttonContainerStyles}
      styling={MOVE_FORWARD_CLASSES}
      {...{ hasSpinner: this.props.moveForwardIsLoading }}
      {...this.onClick}
    />);
  }

  renderSubmitButton() {
    return (<Button
      {...this.props.submitButton}
      styling={BUTTON_STYLES.TERTIARY}
      showArrowIcon
      {...this.onClick}
    />);
  }

  // CONTAINERS

  renderLTRButtonContainer(navigationContainerStyles) {
    return (
      <div className={LAYOUT_STYLES}>
        <div className={navigationContainerStyles}>
          {this.props.backButton && this.renderBackButton()}
          {this.props.beginButton && this.renderBeginButton()}
          {this.props.saveButton && this.renderSaveButton()}
          {this.props.nextButton && this.renderNextButton()}
          {this.props.finishButton && this.renderFinishButton()}
        </div>
        {this.props.submitButton && this.renderSubmitButton()}
      </div>
    );
  }

  renderButtonContainer(navigationContainerStyles) {
    return (
      <nav className={LAYOUT_STYLES}>
        {this.props.submitButton && this.renderSubmitButton()}
        <div className={navigationContainerStyles}>
          {this.props.finishButton && this.renderFinishButton()}
          {this.props.nextButton && this.renderNextButton()}
          {this.props.saveButton && this.renderSaveButton()}
          {this.props.beginButton && this.renderBeginButton()}
          {this.props.backButton && this.renderBackButton()}
        </div>
      </nav>
    );
  }

  render() {
    const { numOfButtons } = this.props;
    if (numOfButtons < 1 || numOfButtons > 3) return null;

    const navigationContainerStyles = classNames(
      styles.navigationButtonsContainer,
      {
        [styles.ltr]: isA11yWfcEnhancementEnabled(),
        [styles.navigationButtonsContainer_hasOneButton]: numOfButtons === 1,
        [styles.navigationButtonsContainer_hasTwoOrThreeButtons]:
          numOfButtons >= 2
      }
    );

    return isA11yWfcEnhancementEnabled() ?
      this.renderLTRButtonContainer(navigationContainerStyles) :
      this.renderButtonContainer(navigationContainerStyles);
  }
}

export default NavigationButtons;
