import loadable from '@loadable/component';
import React, { FunctionComponent } from 'react';

const SimpleDropdown = loadable(() => import('@commons/Dropdown/Simple'));
const SingleSelectDropdown = loadable(() => import('@commons/Dropdown/Select/Single'));
const MultiSelectDropdown = loadable(() => import('@commons/Dropdown/Select/Multi'));

//----------------------------------------------------------------------
// TYPES
//----------------------------------------------------------------------

interface DropdownProps {
  containerStyles: string;
  dropdownList: Array<any>;
  isMultiValued: true;
  isSubComponent: true;
  selected: Array<any>;
  isSearchable: boolean;
  isRTL: boolean;
  title: string;
  onChange: (newValue: any) => void;
}

//----------------------------------------------------------------------
// UTILS
// ----------------------------------------------------------------------

const componentMap = (isSelect: boolean, isMulti: boolean) => {
  if (!isSelect) {
    return SimpleDropdown;
  } else if (!isMulti) {
    return SingleSelectDropdown;
  } else {
    return MultiSelectDropdown;
  }
};

//----------------------------------------------------------------------
// COMPONENT
//----------------------------------------------------------------------

const Dropdown: FunctionComponent<DropdownProps> = (props) => {
  const Component = componentMap(props.isSearchable, props.isMultiValued);
  return <Component {...props} />;
};

export default Dropdown;
