/* eslint-disable react/forbid-component-props */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import commonStyles from '@css/outline.scss';

import styles from './caption.scss';

const { bool, node, string } = PropTypes;

export function mainContainerStyles({ containerStyles, hasBorderStyle, hasHoverStyle }) {
  return classNames(styles.caption, containerStyles, {
    [commonStyles.hasBorder]: hasBorderStyle,
    [commonStyles.hasHover]: hasHoverStyle
  });
}

export default function Caption(props) {
  const { ariaHidden, children, containerElement, containerStyles, hasBorderStyle, hasHoverStyle } =
    props;
  const captionContainerStyles = mainContainerStyles({
    containerStyles,
    hasBorderStyle,
    hasHoverStyle
  });
  const Container = containerElement;
  return (
    <Container aria-hidden={ariaHidden} className={captionContainerStyles}>
      {children}
    </Container>
  );
}

Caption.propTypes = {
  ariaHidden: bool,
  children: node,
  containerElement: string,
  containerStyles: string,
  hasBorderStyle: bool,
  hasHoverStyle: bool
};

Caption.defaultProps = {
  containerElement: 'div'
};
