/* eslint-disable camelcase */

/* eslint-disable react/forbid-component-props */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Table from '@commons/Table';
import Caption from '@commons/Table/Caption';
import Cell from '@commons/Table/Cell';
import Row from '@commons/Table/Row';
import ValidationMessage from '@commons/ValidationMessage';

import Anchor from '@components/RatingGrid/Anchor';
import AnchorBar from '@components/RatingGrid/AnchorBar';
import MobileNaOption from '@components/RatingGrid/MobileNaOption';
import SelectionBar from '@components/RatingGrid/SelectionBar';
import SelectionCell from '@components/RatingGrid/SelectionCell';

import {
  isAdvancedAccessibilityEnabled,
  isLabelForEachOptionClassEnabled,
  isRowOptionErrorStateEnabled,
  isWCAG21Enabled,
  isWCAG21_3_Enabled_FF
} from '@services/featureFlags';
import { getTranslation } from '@services/translations';

import { getOptionDescribedBy } from './helpers';
import styles from './rowOption.scss';

export const TOP = 'TOP';
export const LEFT = 'LEFT';

const rowOptionContainerStyles = (qField, anchors, options, hasSiblings) =>
  classNames(styles.rowOptionContainer, 'ratingGridRow', qField && `container_${qField}`, {
    rowOption_hasAnchorForEachOption:
      isLabelForEachOptionClassEnabled() && anchors.size === options.size && !hasSiblings
  });

function RowOption(props) {
  const {
    id,
    anchors,
    questionId,
    qField,
    caption,
    hasNaOption,
    isDesktopMedia,
    isFirstRow,
    isMobileMedia,
    naOption,
    naOptionCaption,
    options,
    showNumbers,
    showStars,
    useFieldset,
    hiddenLegendForADA,
    captionPosition,
    isRequired,
    isNaSelected,
    hasSiblings,
    isMultiValued,
    isSubComponent,
    hasValidationFailure,
    hasValidationFailureForEmpty
  } = props;

  const hasCaption = Boolean(caption);
  const tableContainerElement = useFieldset ? 'fieldset' : 'div';
  const rowCaptionContainer = (
    <span id={id} className={styles.topCaption} dangerouslySetInnerHTML={{ __html: caption }} />
  );
  const isCaptionAtTop = hasCaption && captionPosition === TOP;
  const isCaptionAtLeft = hasCaption && captionPosition === LEFT;
  const ariaRole = isAdvancedAccessibilityEnabled() ? { ariaRole: 'radiogroup' } : {};

  let optionDescribedBy;
  let ariaDescribedByForNaOptions;

  if (isWCAG21Enabled()) {
    optionDescribedBy = getOptionDescribedBy(props);
    ariaDescribedByForNaOptions = getOptionDescribedBy(props);
  } else {
    optionDescribedBy = questionId && id ? `${questionId} ${id}` : null;
  }

  if (isWCAG21_3_Enabled_FF()) {
    optionDescribedBy = undefined;
    ariaDescribedByForNaOptions = undefined;
  }

  const requiredFieldTooltip = getTranslation('REQUIRED_FIELD_TOOLTIP');
  const validationCaption = (hasValidationFailureForEmpty && requiredFieldTooltip) || '';
  const shouldRenderValidationMessage = hasSiblings && isRowOptionErrorStateEnabled();

  return (
    <Table
      containerElement={tableContainerElement}
      containerStyles={rowOptionContainerStyles(qField, anchors, options, hasSiblings)}
      hasFixedLayoutStyle={false}
      ariaRequired={isRequired}
      {...ariaRole}>
      {shouldRenderValidationMessage && (
        <ValidationMessage
          key={validationCaption}
          isError={hasValidationFailure}
          caption={validationCaption}
          shouldRender={Boolean(validationCaption)}
        />
      )}
      {useFieldset && <legend className={styles.hiddenAriaLegend}>{hiddenLegendForADA}</legend>}
      {!isSubComponent && isCaptionAtTop && (
        <Caption containerStyles={styles.topCaptionContainer}>{rowCaptionContainer}</Caption>
      )}
      {(!isSubComponent || isMobileMedia) && (
        <Row ariaHidden>
          {
            /** this cell is only to create an empty space between the start of the page
             * and the row options to align anchors **/
            isCaptionAtLeft && (
              <Cell containerStyles={styles.leftCaptionContainer} data-test-name={'empty-cell'} />
            )
          }
          <Cell containerStyles={styles.anchorsContainer}>
            <AnchorBar
              rowId={id}
              questionId={questionId}
              isFirstRow={isFirstRow}
              isForcedDesktopShown={isCaptionAtTop}
            />
          </Cell>
          {hasNaOption && (
            <Anchor
              isNaOption
              caption={naOptionCaption}
              containerStyles={styles.naAnchorContainer}
              isForcedShown={isCaptionAtTop}
              isOnFirstRow={isFirstRow}
              isSelected={!hasSiblings && isNaSelected}
              questionId={questionId}
            />
          )}
        </Row>
      )}
      <Row>
        {isCaptionAtLeft && (
          <Cell
            data-test-name={'left-caption'}
            ariaHidden
            containerStyles={styles.leftCaptionContainer}>
            {rowCaptionContainer}
          </Cell>
        )}
        <Cell containerStyles={styles.selectionUnitsContainer}>
          <div className={styles.selectionUnits}>
            <SelectionBar
              optionIds={options}
              showStars={showStars}
              showNumbers={showNumbers}
              optionDescribedBy={optionDescribedBy}
              isMultiValued={isMultiValued}
            />
            {hasNaOption && isMobileMedia && (
              <MobileNaOption
                id={naOption}
                containerStyles={styles.mobileNaOptionContainer}
                ariaDescribedBy={ariaDescribedByForNaOptions}
              />
            )}
          </div>
        </Cell>
        {hasNaOption && isDesktopMedia && (
          <SelectionCell optionId={naOption} optionDescribedBy={ariaDescribedByForNaOptions} />
        )}
      </Row>
    </Table>
  );
}

const { bool, instanceOf, string, oneOf } = PropTypes;

RowOption.propTypes = {
  id: string,
  questionId: string,
  caption: string, // caption will be null if the RatingGrid is a scaleQuestion
  captionPosition: oneOf([TOP, LEFT]),
  hiddenLegendForADA: string,
  hasNaOption: bool,
  isDesktopMedia: bool,
  isFirstRow: bool,
  isRequired: bool,
  isMobileMedia: bool,
  naOption: string,
  naOptionCaption: string,
  options: instanceOf(Set),
  showNumbers: bool,
  showStars: bool,
  useFieldset: bool,
  qField: string,
  isSubComponent: bool,
  hasValidationFailure: bool,
  hasValidationFailureForEmpty: bool
};

RowOption.defaultProps = {
  id: null,
  questionId: null,
  caption: '',
  captionPosition: TOP,
  hasValidationFailure: false,
  hasValidationFailureForEmpty: false,
  hasNaOption: false,
  hiddenLegendForADA: '',
  isDesktopMedia: true,
  isMobileMedia: false,
  isFirstRow: false,
  isRequired: false,
  naOption: '',
  naOptionCaption: '',
  options: new Set([]),
  showNumbers: false,
  showStars: false,
  useFieldset: false,
  qField: null,
  isSubComponent: false
};

export default RowOption;
