/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React from 'react';

import Icon, { ICON_TYPES } from '@commons/Icon';

import { cookieBannerVersionPropType, isV1 } from '@components/CookieBanner/shared/utils';

import styles from './cookieBannerPanelLink.scss';

const CookieBannerPanelLink = ({ version, onClick, children }) => (
  <a href="#" className={styles.anchor} onClick={onClick}>
    {/* eslint-disable-next-line react/no-danger */}
    <span dangerouslySetInnerHTML={{ __html: children }} />
    {isV1(version) && <Icon type={ICON_TYPES.ARROW_RIGHT} />}
  </a>
);

CookieBannerPanelLink.propTypes = {
  version: cookieBannerVersionPropType.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default CookieBannerPanelLink;
