import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React from 'react';

import styles from '@commons/Dropdown/dropdown.scss';

import stripHtml from '@utils/stripHtml';
import { testName } from '@utils/testUtils';

const NativeLanguageSelector = (props) => {
  const { label, languages, surveyLanguage, onLanguageSelected } = props;

  const onChangeHandler = (event) => {
    onLanguageSelected(event.target.value);
  };

  if (languages.length === 0) {
    return null;
  }

  return (
    <span>
      {label && (
        <label htmlFor="activeLang" className={styles.dropdownLabel_isInactive}>
          {label}
        </label>
      )}
      <select value={surveyLanguage} onChange={onChangeHandler} aria-label={label}>
        {languages.map((langOption) => (
          <option
            value={langOption.key}
            key={langOption.key}
            {...testName(stripHtml(langOption.name), 'option')}
          >
            {stripHtml(langOption.name)}
          </option>
        ))}
      </select>
    </span>
  );
};

NativeLanguageSelector.propTypes = {
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string
    })
  ),
  surveyLanguage: PropTypes.string,
  label: PropTypes.string,
  onLanguageSelected: PropTypes.func
};

NativeLanguageSelector.defaultProps = {
  languages: [],
  surveyLanguage: 'en',
  label: '',
  onLanguageSelected: noop
};

export default NativeLanguageSelector;
