import { connect } from 'react-redux';
import { compose } from 'redux';

import withCondition from '@commons/withCondition';

import { setPageFetchingStatus, setPageReadyStatus } from '@redux/actions/setPageStatus';

import FileUploadContainer from './FileUploadContainer';

const mapStateToProps = ({ mediaQuery }) => ({
  isMobile: mediaQuery.isMobileMedia
});

const mapDispatchToProps = {
  setPageFetchingStatus,
  setPageReadyStatus
};

export default compose(
  withCondition,
  connect(mapStateToProps, mapDispatchToProps)
)(FileUploadContainer);
