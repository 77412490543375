import PropTypes from 'prop-types';
import React from 'react';

import ToggleSwitch from '@commons/ToggleSwitch';

import styles from './cookieCategoryControl.scss';

function CookieCategoryControl({ id, title, value, onChange, disabled, requiredLabel }) {
  return (
    <div className={styles.wrapper}>
      <p>{title}</p>
      {disabled ? (
        <p className={styles.requiredLabel}>{requiredLabel}</p>
      ) : (
        <ToggleSwitch value={value} onChange={() => onChange(id)} disabled={disabled} />
      )}
    </div>
  );
}

CookieCategoryControl.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  requiredLabel: PropTypes.string.isRequired
};

CookieCategoryControl.defaultProps = {
  disabled: false
};

export default CookieCategoryControl;
