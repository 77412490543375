import { NODE_TYPES } from '@services/conditionGenerator/constants';
import constantGenerator from '@services/conditionGenerator/generators/constant';
import fieldGenerator from '@services/conditionGenerator/generators/field';

function eqGenerator(field, value) {
  return {
    type: NODE_TYPES.EQ,
    left: fieldGenerator(field),
    right: constantGenerator(value)
  };
}

function eqConditionStringGenerator(field, value) {
  return `${field} = ${value}`;
}

export { eqConditionStringGenerator };
export default eqGenerator;
