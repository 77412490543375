import classNames from 'classnames';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Question from '@commons/Question';

import layoutStyles from '@css/layout.scss';

import { isTextfieldWithoutFieldsetEnabled } from '@services/featureFlags';

import { isEnterKey } from '@utils/detectKey';
import stripHtml from '@utils/stripHtml';

import styles from './textField.scss';

export default class TextField extends Component {
  static propTypes = {
    formkey: PropTypes.string,
    caption: PropTypes.string,
    maxChars: PropTypes.number,
    validationEmpty: PropTypes.string,
    validationFailed: PropTypes.string,
    requiredField: PropTypes.string,
    validationMessages: PropTypes.arrayOf(PropTypes.string),
    setComponentAnswer: PropTypes.func,
    isSubComponent: PropTypes.bool
  };

  static defaultProps = {
    formkey: '',
    caption: '',
    validationEmpty: null,
    validationFailed: null,
    validationMessages: [],
    requiredField: null,
    isSubComponent: false
  };

  constructor(props) {
    super(props);

    const currentCount = (props.value && props.value.length) || 0;
    this.state = {
      currentCount,
      value: props.value || '',
      ariaLive: null,
      ariaAtomic: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.updateCharacterCount = this.updateCharacterCount.bind(this);
    this.debouncedSetAnswer = debounce(this.setAnswer.bind(this), 300);
  }

  setAnswer(value) {
    this.props.setComponentAnswer(value);
  }

  handleChange(e) {
    const currentText = e.target.value;
    // recalculate the number of characters typed in so far
    this.updateCharacterCount(currentText);
    const characterCount = currentText.length;
    const { maxChars } = this.props;
    let ariaBusyOnChange = null;

    if (maxChars) {
      const assertiveFlag = maxChars * 0.9;

      if (characterCount >= assertiveFlag) {
        ariaBusyOnChange = { 'aria-busy': 'false' };
      } else {
        if (characterCount % 50 === 0) {
          ariaBusyOnChange = { 'aria-busy': 'false' };
        } else {
          ariaBusyOnChange = { 'aria-busy': 'true' };
        }
      }
    }
    this.setState({
      value: currentText,
      ariaBusyOnChange,
    });
    this.debouncedSetAnswer(currentText);
  }

  updateCharacterCount(newText) {
    const currentCount = (newText && newText.length) || 0;
    this.setState({ currentCount });
  }

  onKeyPress(e) {
    return isEnterKey(e) && e.preventDefault();
  }

  render() {
    const {
      formkey,
      caption,
      maxChars,
      validationEmpty,
      validationFailed,
      requiredField,
      validationMessages,
      isSubComponent
    } = this.props;
    const ariaRequired = (requiredField || validationEmpty) && { 'aria-required': true };
    const ariaInvalid = (validationFailed || validationEmpty) && { 'aria-invalid': true };
    const maxLength = maxChars ? { maxLength: maxChars } : {};
    const textFieldContainerStyles = classNames(styles.textfieldContainer, layoutStyles.answer);
    const { ariaBusyOnChange } = this.state;

    let ariaLive, ariaAtomic, ariaBusy;

    ariaLive = { 'aria-live': 'assertive' };
    ariaAtomic = { 'aria-atomic': true };

    if (!ariaBusyOnChange) {
      ariaBusy = { 'aria-busy': 'true' };
    } else {
      ariaBusy = ariaBusyOnChange;
    }

    const charCountId = `${formkey}_CharCount`;

    const component = [
      !isSubComponent && (
        <Question
          key="question"
          ariaId={formkey}
          hasLegendCaption={!isTextfieldWithoutFieldsetEnabled()}
          caption={caption}
          validationEmpty={validationEmpty}
          validationFailed={validationFailed}
          requiredField={requiredField}
          validationMessages={validationMessages}
        />
      ),
      <div key="input" className={classNames({ [layoutStyles.answers]: !isSubComponent })}>
        <div className={textFieldContainerStyles}>
          <input
            id={formkey}
            name={formkey}
            type="text"
            title={stripHtml(caption)}
            className={styles.textField}
            onChange={this.handleChange}
            value={this.state.value}
            onKeyPress={this.onKeyPress}
            {...maxLength}
            {...ariaRequired}
            {...ariaInvalid}
          />
        </div>
        {maxChars && (
          <div
            id={charCountId}
            className={styles.textfieldCount}
            {...ariaLive}
            {...ariaAtomic}
            {...ariaBusy}>
            {`${this.state.currentCount} / ${maxChars}`}
            <span className={styles.textfieldCharacterTyped}> Characters Typed</span>
          </div>
        )}
      </div>
    ];

    if (isTextfieldWithoutFieldsetEnabled()) {
      return <div className="questionBlock textfieldQuestion">{component}</div>;
    }

    return (
      <div className="questionBlock textfieldQuestion">
        <fieldset>{component}</fieldset>
      </div>
    );
  }
}
