import PropTypes from 'prop-types';
import React from 'react';

import CookieList from '@components/CookieBanner/V3/CookieList';
import { COOKIE_CATEGORY, getCategoriesDetails } from '@components/CookieBanner/V3/utils';
import CookieBannerPanel from '@components/CookieBanner/shared/CookieBannerPanel';

const PanelCategoryDetails = ({ category, rawCookieBanner }) => {
  const { cookieInfoLabels } = rawCookieBanner;
  const { title, description, cookies } = getCategoriesDetails(
    rawCookieBanner,
    category,
    cookieInfoLabels
  );

  return (
    <CookieBannerPanel.Root>
      <CookieBannerPanel.Title>{title}</CookieBannerPanel.Title>
      <CookieBannerPanel.Description isMessage parseHTML>
        {description}
      </CookieBannerPanel.Description>
      <CookieList items={cookies} />
    </CookieBannerPanel.Root>
  );
};

PanelCategoryDetails.propTypes = {
  category: PropTypes.oneOf(Object.keys(COOKIE_CATEGORY)).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rawCookieBanner: PropTypes.object.isRequired
};

export default PanelCategoryDetails;
