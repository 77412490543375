/* eslint-disable react/forbid-component-props */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import withCondition from '@commons/withCondition';

import commonStyles from '@css/outline.scss';

import { isGridRowCellAlignmentEnabled } from '@services/featureFlags';
import styles from './row.scss';

function mainContainerStyles({ containerStyles, hasBorderStyle, hasHoverStyle }) {
  return classNames(styles.row, containerStyles, {
    [commonStyles.hasBorder]: hasBorderStyle,
    [commonStyles.hasHover]: hasHoverStyle,
    [styles.isGridRowCellAlignmentEnabled]: isGridRowCellAlignmentEnabled()
  });
}

export default function Row(props) {
  const {
    ariaHidden,
    ariaId,
    ariaLabel,
    ariaLabelledBy,
    ariaRole,
    children,
    containerElement,
    containerStyles,
    hasBorderStyle,
    hasHoverStyle,
    tabIndex
  } = props;
  const rowContainerStyles = mainContainerStyles({
    containerStyles,
    hasBorderStyle,
    hasHoverStyle
  });
  const Container = containerElement;
  return (
    <Container
      id={ariaId}
      aria-hidden={ariaHidden}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      className={rowContainerStyles}
      role={ariaRole}
      tabIndex={tabIndex}>
      {children}
    </Container>
  );
}

const { bool, node, number, string } = PropTypes;

Row.propTypes = {
  ariaHidden: bool,
  ariaId: string,
  ariaLabel: string,
  ariaLabelledBy: string,
  ariaRole: string,
  children: node,
  containerElement: string,
  containerStyles: string,
  hasBorderStyle: bool,
  hasHoverStyle: bool,
  tabIndex: number
};

Row.defaultProps = {
  containerElement: 'div'
};

export const ConditionedRow = withCondition(Row);
