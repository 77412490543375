export const SET_COMPONENT_VALIDATIONS = 'SET_COMPONENT_VALIDATIONS';

const setComponentValidations = (componentId, validations) => (dispatch) => {
  dispatch({
    type: SET_COMPONENT_VALIDATIONS,
    payload: {
      componentId,
      validations
    }
  });
};

export default setComponentValidations;
