/*
  The aim of this registry is to avoid a circular dependency between the evaluators and the
  evaluateNode function.

  - Evaluators will register themselves using registerEvaluatorForNodeType. They will also depend
  on evaluateNode as they will need to evaluate their child nodes.

  - evaluateNode function will obtain an evaluator for the given node using
  getEvaluatorForNodeType. It won't depend on the evaluators directly, breaking the
  circular dependency.
*/
export class EvaluatorRegistry {
  constructor() {
    this.evaluators = {};
  }

  registerEvaluatorForNodeType(nodeType, evaluator) {
    this.evaluators[nodeType] = evaluator;
  }

  getEvaluatorForNodeType(nodeType) {
    const evaluator = this.evaluators[nodeType];

    if (!evaluator) {
      throw new Error(`Evaluator is not available for operation ${nodeType}`);
    }

    return evaluator;
  }
}

export default new EvaluatorRegistry();
