import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import styles from '@css/layout.scss';

// eslint-disable-next-line camelcase
import { isWCAG21_3_Enabled_FF } from '@services/featureFlags';

const { bool, string } = PropTypes;

export default class ErrorBlock extends PureComponent {
  constructor(props) {
    super(props);

    this.ref = null;
    this.setRef = (element) => {
      this.ref = element;
    };
  }

  componentDidMount() {
    const { shouldFocus } = this.props;

    if (this.ref && shouldFocus) {
      // Timeout here is to fix a bug on safari not reading error message without it
      setTimeout(() => this.ref.focus(), 100);
    }
  }

  render() {
    const { text } = this.props;

    if (isWCAG21_3_Enabled_FF()) {
      return (
        <div
          className={classNames(styles.globalErrorBlock, styles.globalErrorBlockFocusNone)}
          tabIndex="-1"
          ref={this.setRef}
          aria-live="assertive">
          {text}
        </div>
      );
    }

    return <div className={styles.globalErrorBlock}>{text}</div>;
  }
}

ErrorBlock.propTypes = {
  shouldFocus: bool,
  text: string.isRequired
};

ErrorBlock.defaultProps = {
  shouldFocus: false
};
