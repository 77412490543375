import PropTypes from 'prop-types';
import React from 'react';

import styles from './cookieBannerContent.scss';

function CookieBannerContent(props) {
  return <div className={styles.wrapper}>{props.children}</div>;
}

CookieBannerContent.propTypes = {
  children: PropTypes.node.isRequired
};

export default CookieBannerContent;
