import PropTypes from 'prop-types';
import React from 'react';

import styles from './cookieBannerPanelActionArea.scss';

function CookieBannerPanelActionArea({ children }) {
  return <div className={styles.wrapper}>{children}</div>;
}

CookieBannerPanelActionArea.propTypes = {
  children: PropTypes.node.isRequired
};

export default CookieBannerPanelActionArea;
