/* eslint-disable no-param-reassign */

/* eslint-disable no-shadow */
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { RANK_ITEM, UNRANK_ITEM, VARIABLE_TO_REPLACE } from '@components/RankingOrder/constants';

import { getTranslationWithVariables } from '@services/translations';

import styles from './DropdownLayout.scss';
import List from './List';

export const EMPTY_VALUE = '-1'; // distinct from regular ids which are positive numbers
export const EMPTY_TEXT = '—'; // this is "Em Dash" U+2014

export const TEST_NAME_DROPDOWN_LAYOUT = 'TEST_NAME_DROPDOWN_LAYOUT';
export const TEST_NAME_ARIA_LIVE_MESSAGE = 'TEST_NAME_ARIA_LIVE_MESSAGE';

export const CSS_TRANSITION_MS = 300;

const { arrayOf, shape, string, func } = PropTypes;

export default class DropdownLayout extends Component {
  static propTypes = {
    originalItems: arrayOf(
      shape({
        id: string,
        text: string
      })
    ).isRequired,
    selectedItems: arrayOf(
      shape({
        id: string,
        text: string
      })
    ).isRequired,
    setAlternativeAt: func.isRequired,
    unselectAlternative: func.isRequired
  };

  static getItemById(id, items) {
    const itemById = items.find((item) => item.id === id);
    return itemById;
  }

  static getItemsWithDefaultPosition(items) {
    return items.map((item, idx) => ({
      ...item,
      defaultPosition: idx
    }));
  }

  static getItemsWithPosition(originalItems, selectedItems) {
    return originalItems.map((original) => {
      const positionIdx = selectedItems.findIndex(
        (selected) => selected !== null && selected.id === original.id
      );
      const position = positionIdx !== -1 ? positionIdx : null;
      return {
        ...original,
        position
      };
    });
  }

  static getItemsWithSelected(items) {
    return items.map((item) => {
      let selected = { id: EMPTY_VALUE, text: EMPTY_TEXT };

      if (item.position !== null) {
        const val = item.position + 1;
        selected = { id: `${val}`, text: `${val}` };
      }

      return {
        ...item,
        selected
      };
    });
  }

  static getItemsWithRef(newPropsItems, items) {
    return newPropsItems.map((newItem) => {
      const item = items.find((item) => item.id === newItem.id);
      const ret = { ...newItem };
      if (item) {
        ret.ref = item.ref;
      }
      return ret;
    });
  }

  static getItemsWithBoundingBox(items) {
    return items.map((item) => {
      const updated = { ...item };
      if (updated.ref) {
        updated.boundingBox = updated.ref.getBoundingClientRect();
      }
      return updated;
    });
  }

  static getSortedItems(items) {
    return sortBy(items, ['position', 'defaultPosition', 'id']);
  }

  /* istanbul ignore next */
  static animateItemsPositions(prevStateItems, items, listRef) {
    if (!listRef) {
      return;
    }

    /*
      set list as a 3D space for all transformations,
      this will allow to properly simulate z-index with Z axis of translate3D
    */
    listRef.style.transformStyle = 'preserve-3d';

    prevStateItems.forEach((prevItem, idx, arr) => {
      const { ref } = prevItem;
      const currItem = items.find((item) => item.id === prevItem.id);
      const oldBox = ref.getBoundingClientRect();
      const item = DropdownLayout.getItemById(prevItem.id, items);

      if (!item) {
        return;
      }

      const newBox = item.boundingBox;

      if (!newBox) {
        return;
      }

      const deltaY = newBox.top - oldBox.top;

      // first on top, last on the bottom
      const deltaZ = currItem.position !== null ? arr.length - currItem.position : 0;

      requestAnimationFrame(() => {
        /*
          Put new items on old positions before first paint.
          Do so instantly.
        */
        ref.style.transform = `translate3D(0, ${deltaY}px, ${deltaZ}px)`;
        ref.style.transition = 'transform 0ms';
        requestAnimationFrame(() => {
          // animate new items back to new positions on first paint
          ref.style.transform = `translate3d(0, 0, ${deltaZ}px)`;
          ref.style.transition = `transform ${CSS_TRANSITION_MS}ms`;
          requestAnimationFrame(() => {
            /*
              Remove transformation and transformStyle
              to restore native z-index after animation.

              Not having this will break visibility
              of opened options list in Dropdown
            */
            ref.style.transform = 'none';
            if (idx === arr.length - 1) {
              setTimeout(() => {
                listRef.style.transformStyle = 'flat';
              }, CSS_TRANSITION_MS);
            }
          });
        });
      });
    });
  }

  constructor(props) {
    super(props);

    const { originalItems, selectedItems } = props;

    let items = DropdownLayout.getItemsWithPosition(originalItems, selectedItems);
    items = DropdownLayout.getItemsWithDefaultPosition(items);
    items = DropdownLayout.getItemsWithSelected(items);

    this.state = {
      listRef: null,
      items
    };

    this.setAriaLiveMessage = this.setAriaLiveMessage.bind(this);
    this.setItemRef = this.setItemRef.bind(this);
    this.setListRef = this.setListRef.bind(this);
    this.updatePositions = this.updatePositions.bind(this);
  }

  /* istanbul ignore next */
  componentWillReceiveProps(nextProps) {
    const { originalItems, selectedItems } = nextProps;

    let items = DropdownLayout.getItemsWithPosition(originalItems, selectedItems);
    items = DropdownLayout.getItemsWithDefaultPosition(items);
    items = DropdownLayout.getSortedItems(items);
    items = DropdownLayout.getItemsWithRef(items, this.state.items);
    items = DropdownLayout.getItemsWithBoundingBox(items);
    items = DropdownLayout.getItemsWithSelected(items);
    this.setState({ items });
  }

  componentDidUpdate(previousProps, prevState) {
    DropdownLayout.animateItemsPositions(prevState.items, this.state.items, this.state.listRef);
  }

  setItemRef(itemId, ref) {
    const items = [...this.state.items];
    const item = DropdownLayout.getItemById(itemId, items);

    if (item && !item.ref) {
      item.ref = ref;

      /*
        use callback variant of setState to account for multiple,
        parallel, calls of this function from children
      */
      this.setState((prevState) => ({
        ...prevState,
        items
      }));
    }
  }

  setListRef(listRef) {
    if (!this.state.listRef) {
      this.setState({ listRef });
    }
  }

  setAriaLiveMessage(text, position) {
    const ariaLiveMessage =
      position !== null
        ? getTranslationWithVariables(RANK_ITEM, VARIABLE_TO_REPLACE, [text, position])
        : getTranslationWithVariables(UNRANK_ITEM, VARIABLE_TO_REPLACE, [text]);

    this.setState({ ariaLiveMessage });
  }

  updatePositions(itemId, toPosition) {
    const { setAlternativeAt, unselectAlternative } = this.props;
    if (toPosition === null) {
      unselectAlternative(itemId);
    } else {
      setAlternativeAt(itemId, toPosition);
    }
  }

  render() {
    const {
      setAriaLiveMessage,
      setItemRef,
      setListRef,
      updatePositions,
      state: { ariaLiveMessage, items }
    } = this;

    return (
      <div data-test-name={TEST_NAME_DROPDOWN_LAYOUT}>
        {ariaLiveMessage && (
          <span
            aria-live="assertive"
            className={styles.assistiveText}
            data-test-name={TEST_NAME_ARIA_LIVE_MESSAGE}>
            {ariaLiveMessage}
          </span>
        )}
        <List
          items={items}
          setAriaLiveMessage={setAriaLiveMessage}
          setItemRef={setItemRef}
          setListRef={setListRef}
          updatePositions={updatePositions}
        />
      </div>
    );
  }
}
