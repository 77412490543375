export default function validateAttributesType(obj, typings) {
  if (!typings) return false;
  if (typeof typings !== 'object') {
    if (process.env.NODE_ENV === 'development') {
      throw new Error(`[${obj.constructor.name}]: typings must be an object!`);
    }
    return false;
  }
  const keys = Object.keys(typings);
  for (let i = 0; i < keys.length; i++) {
    const attr = keys[i];
    const type = typings[attr];
    if (obj[attr] instanceof type === false) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log(`[${obj.constructor.name}]: ${attr} must be of type ${type}`);
      }
      return false;
    }
  }
  return true;
}
