import get from 'lodash/get';

export const PANEL_ID_MAIN = 'V3_PANEL_MAIN';
export const PANEL_ID_LEGAL_NOTICE = 'V3_PANEL_LEGAL_NOTICE';
export const PANEL_ID_COOKIE_CONSENT = 'V3_PANEL_COOKIE_CONSENT';
export const PANEL_ID_COOKIE_SETTINGS = 'V3_PANEL_COOKIE_SETTINGS';

// Cookie Categories

export const COOKIE_CATEGORY = {
  TECHNICALLY_NECESSARY: 'TECHNICALLY_NECESSARY',
  STATISTICS: 'STATISTICS',
  USER_EXPERIENCE: 'USER_EXPERIENCE'
};

export const PANEL_ID_CATEGORY = Object.values(COOKIE_CATEGORY)
  .map((category) => ({
    label: `V3_PANEL_COOKIE_CATEGORY_${category}`,
    key: category
  }))
  .reduce((acc, value) => ({ ...acc, [value.key]: value.label }), {});

export const isCookieCategoryPanel = (pathname) =>
  Object.values(PANEL_ID_CATEGORY).includes(pathname);

// Normalizers

export const getCategories = (cookieBanner) => {
  const technicallyNecessary = get(cookieBanner, 'technicallyNecessaryCookiesInfo', {});
  const optional = get(cookieBanner, 'optionalCategories', []);

  return [
    {
      ...technicallyNecessary,
      categoryType: COOKIE_CATEGORY.TECHNICALLY_NECESSARY,
      consent: true
    },
    ...optional
  ];
};

const mapCookieInfo = (cookie, cookieInfoLabels) => {
  const { name, duration, provider, purpose, type } = cookie;
  const { durationText, providerText, purposeText, typeText } = cookieInfoLabels;

  return {
    id: name,
    properties: {
      [durationText]: duration,
      [providerText]: provider,
      [purposeText]: purpose,
      [typeText]: type
    }
  };
};

export const getCategoriesDetails = (cookieBanner, category, cookieInfoLabels) => {
  const { technicallyNecessaryCookiesInfo, optionalCategories } = cookieBanner;

  if (category === COOKIE_CATEGORY.TECHNICALLY_NECESSARY) {
    return {
      title: technicallyNecessaryCookiesInfo.title,
      description: technicallyNecessaryCookiesInfo.message,
      cookies: technicallyNecessaryCookiesInfo.cookiesInfo.map((cookie) =>
        mapCookieInfo(cookie, cookieInfoLabels)
      )
    };
  }

  const categoryInfo = optionalCategories.find((item) => item.categoryType === category);

  return {
    title: categoryInfo.title,
    description: categoryInfo.message,
    cookies: categoryInfo.cookiesInfo.map((cookie) => mapCookieInfo(cookie, cookieInfoLabels))
  };
};
