export default function isDefined({ err, multipleValues = [], value }) {
  const values = value === undefined ? multipleValues : [value];
  for (let i = 0; i < values.length; i++) {
    const val = values[i];
    if (process.env.NODE_ENV === 'development') {
      if ((val === undefined || val === null) && err) throw new Error(err);
    }
    if (val === undefined || val === null) return false;
  }
  return true;
}
