import PropTypes from 'prop-types';
import React from 'react';

import { ARIA_TITLE_ID } from '@components/CookieBanner/shared/utils';

import styles from './cookieBannerPanelTitle.scss';

const Title = ({ children }) => (
  <h1
    className={styles.title}
    id={ARIA_TITLE_ID}
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{ __html: children }}
  />
);

Title.propTypes = {
  children: PropTypes.node.isRequired
};

export default Title;
