import evaluateNode from '@services/conditionEngine';
import { NODE_TYPES } from '@services/conditionEngine/constants';
import evaluatorRegistry from '@services/conditionEngine/evaluators/registry';

import { COUNT_OPERATIONS } from './constant';

const OPERATIONS = {
  [COUNT_OPERATIONS.LESS]: {
    compareFn: (positiveResultsCounter, compareTo) => positiveResultsCounter < compareTo,
    shortCircuitFn: (positiveResultsCounter, compareTo, childNodesLeft) =>
      positiveResultsCounter >= compareTo || positiveResultsCounter + childNodesLeft < compareTo
  },
  [COUNT_OPERATIONS.LESS_OR_EQUAL]: {
    compareFn: (positiveResultsCounter, compareTo) => positiveResultsCounter <= compareTo,
    shortCircuitFn: (positiveResultsCounter, compareTo, childNodesLeft) =>
      positiveResultsCounter > compareTo || positiveResultsCounter + childNodesLeft <= compareTo
  },
  [COUNT_OPERATIONS.EQUAL]: {
    compareFn: (positiveResultsCounter, compareTo) => positiveResultsCounter === compareTo,
    shortCircuitFn: (positiveResultsCounter, compareTo, childNodesLeft) =>
      positiveResultsCounter > compareTo || positiveResultsCounter + childNodesLeft < compareTo
  },
  [COUNT_OPERATIONS.GREATER_OR_EQUAL]: {
    compareFn: (positiveResultsCounter, compareTo) => positiveResultsCounter >= compareTo,
    shortCircuitFn: (positiveResultsCounter, compareTo, childNodesLeft) =>
      positiveResultsCounter >= compareTo || positiveResultsCounter + childNodesLeft < compareTo
  },
  [COUNT_OPERATIONS.GREATER]: {
    compareFn: (positiveResultsCounter, compareTo) => positiveResultsCounter > compareTo,
    shortCircuitFn: (positiveResultsCounter, compareTo, childNodesLeft) =>
      positiveResultsCounter > compareTo || positiveResultsCounter + childNodesLeft <= compareTo
  }
};

function compareEvaluator(node, values) {
  if (!OPERATIONS[node.op]) {
    throw new Error(`Evaluator not available for compare operation ${node.op}`);
  }

  const { compareFn, shortCircuitFn } = OPERATIONS[node.op];
  const totalChildNodes = node.children.length;

  let positiveResultsCounter = 0;

  for (let i = 0; i < totalChildNodes; i++) {
    const childNode = node.children[i];
    const childNodesLeft = totalChildNodes - i - 1;

    const childResult = evaluateNode(childNode, values);

    // If the evaluation results to true, we need to increase the positiveResultsCounter
    // to be able to later compare it with the compare_to value
    if (childResult === true) {
      positiveResultsCounter += 1;
    }

    // Depending on the operation, the evaluation can be short-circuited to optimize evaluation
    // Short-circuit can be done based on two different criteria.
    // - positiveResultsCounter:
    //     Depending on the operation, based on the current positiveResultsCounter,
    //     the algorithm can realize early that the condition can not be met.
    //     (i.e. for equal operation, if compareTo < positiveResultsCounter
    //     we can be sure that the condition can't be met)
    // - childNodesLeft:
    //     By assuming that all the conditions that are still left to evaluate
    //     will result to true, the algorithm can realize early if the condition will not be met.
    //     (i.e. for equal operation, positiveResultsCounter is N and at most M childNodesLeft
    //     will evaluate to true, if compareTo > N + M, then we are sure that the condition
    //     will not be met)
    if (shortCircuitFn(positiveResultsCounter, node.compare_to, childNodesLeft)) {
      break;
    }
  }

  // After evaluating the childNodes we need to use the compareFn to check the countCondition result
  return compareFn(positiveResultsCounter, node.compare_to);
}

evaluatorRegistry.registerEvaluatorForNodeType(NODE_TYPES.COUNT, compareEvaluator);
export { COUNT_OPERATIONS };
export default compareEvaluator;
