import { isEqual, isNil, isNull } from 'lodash';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import React, { Component, createRef } from 'react';
import { BlockLivingLens, BlockLivingLensFields } from 'types/SurveyData';

import Checkbox from '@commons/Checkbox';
import LivingLensV0 from '@commons/LivingLensV0';
import LivingLensV1 from '@commons/LivingLensV1';
import Question from '@commons/Question';
import ValidationMessage from '@commons/ValidationMessage';

import Text from '@components/Text';

import { ERROR_CODES } from '@services/LivingLens';
import ScrollServiceInstance from '@services/scroll';
import { setAttribute } from '@services/telemetry';
import { getTranslation } from '@services/translations';
import ValidationsServiceInstance from '@services/validations';

import styles from './multimediaFeedback.scss';
import { MultimediaFeedbackStatus } from './types';

// # ----------------------------------------------------------------------
// # UTILS
// # ----------------------------------------------------------------------

const ERROR_CODE_MESSAGE = 'Error code: ';
const CHECKBOX_LABEL_FALLBACK = 'I’m experiencing technical issues';

const LivingLensWidgets = {
  V0: LivingLensV0,
  V1: LivingLensV1
};

const initialState = {
  forceLivingLensV0: false
};

// # ----------------------------------------------------------------------
// # TYPES
// # ----------------------------------------------------------------------

type AnswerQuestion = <QuestionType>(question: {
  questionId: number;
  componentId: number;
  htmlInputId: string;
  value: QuestionType;
}) => void;

type SetComponentData = (
  id: number,
  data: {
    mediaStatus: MultimediaFeedbackStatus;
  }
) => void;

type MultimediaFeedbackProps = BlockLivingLens & {
  // MPC-5193 LivingLens Upload Validation
  uploadValidationPass: boolean;
  uploadValidationRetries: number;
  uploadValidationWarning: string;
  // MPC-7849 Option to Make Video/Audio Question Required
  isRequired: boolean;
  hasValidationFailure: boolean;
  hasValidationFailureForEmpty: boolean;
  // Others
  id: number;
  disableBranding: boolean;
  htmlInputs: Record<string, string | number | boolean>;
  answerQuestion: AnswerQuestion;
  setComponentData: SetComponentData;
  fields: {
    mediaType: string;
    noAnswer: string;
    errorCode: string;
  };
};

type MultimediaFeedbackState = typeof initialState;

// # ----------------------------------------------------------------------
// # COMPONENT
// # ----------------------------------------------------------------------

export default class MultimediaFeedback extends Component<
  MultimediaFeedbackProps,
  MultimediaFeedbackState
> {
  errorMessages: Record<string, string>;
  validationID: number;
  multimediaFeedbackRef = createRef<HTMLDivElement>();

  constructor(props: MultimediaFeedbackProps) {
    super(props);
    this.state = initialState;

    // TODO: Rethink this once we have multiple validations for this component.
    this.validationID = 0;
    this.errorMessages = {
      [ERROR_CODES.ERROR_LOADING_SCRIPT]: getTranslation('LL_LOADING_ISSUE')
    };
  }

  // # ----------------------------------------------------------------------
  // # MISC
  // # ----------------------------------------------------------------------

  getMultimediaComponent = (version: keyof typeof LivingLensWidgets) => {
    if (this.state.forceLivingLensV0) {
      return LivingLensV0;
    }

    // widgetVersion property will be specified by the SE > 20CR4
    // If it's not there, then we're running against an older SE
    // and we should fallback to LL V0 widget
    if (isUndefined(version)) {
      return LivingLensV0;
    }

    return LivingLensWidgets[version] || LivingLensV1;
  };

  collectRetriesTelemetry() {
    const component = ValidationsServiceInstance.components.get(this.props.id);
    const initialRetries = get(component, [this.validationID, 'retries'], 0);
    const performedRetries = initialRetries - this.props.uploadValidationRetries;

    setAttribute('LL_UPLOADED', { retries: performedRetries });
  }

  // # ----------------------------------------------------------------------
  // # STATE UPDATERS
  // # ----------------------------------------------------------------------

  setHasAnswerValue = (hasAnswer: boolean) => {
    this.props.answerQuestion({
      questionId: this.props.id,
      componentId: this.props.id,
      htmlInputId: this.props.fields.noAnswer,
      value: hasAnswer ? '' : true
    });
  };

  setErrorCode = (errorCode: string) => {
    this.props.answerQuestion({
      questionId: this.props.id,
      componentId: this.props.id,
      htmlInputId: this.props.fields.errorCode,
      value: errorCode
    });
  };

  setAnswer = (data: BlockLivingLensFields) => {
    this.props.answerQuestion({
      questionId: this.props.id,
      componentId: this.props.id,
      htmlInputId: this.props.fields.uploadId,
      value: data.uploadId
    });

    this.props.answerQuestion({
      questionId: this.props.id,
      componentId: this.props.id,
      htmlInputId: this.props.fields.mediaId,
      value: data.mediaId
    });

    this.props.answerQuestion({
      questionId: this.props.id,
      componentId: this.props.id,
      htmlInputId: this.props.fields.uploadUrl,
      value: data.mediaUrl
    });

    ValidationsServiceInstance.resetValidationsForComponent(this.props.id);
    this.setHasAnswerValue(true);
    this.setMediaStatus('UPLOADED');

    this.collectRetriesTelemetry();

    const errorCode = this.props.htmlInputs[this.props.fields.errorCode];
    if (errorCode !== ERROR_CODES.WIDGET_VERSION_NOT_AVAILABLE) {
      this.setErrorCode('');
    }
  };

  setMediaStatus = (status: MultimediaFeedbackStatus) => {
    this.props.setComponentData(this.props.id, {
      mediaStatus: status
    });
  };

  setSkipValidation = () => {
    const { id, htmlInputs, fields, answerQuestion } = this.props;
    const currentValue = htmlInputs[fields.skipValidation];
    const newValue = currentValue === '' ? true : '';

    answerQuestion({
      questionId: id,
      componentId: id,
      htmlInputId: fields.skipValidation,
      value: newValue
    });
  };

  // # ----------------------------------------------------------------------
  // # LIVINGLENS EVENTS
  // # ----------------------------------------------------------------------

  onError = (errorCode: string) => {
    if (errorCode === ERROR_CODES.WIDGET_VERSION_NOT_AVAILABLE) {
      this.setState({ forceLivingLensV0: true });
    }

    this.setErrorCode(errorCode);
    this.setHasAnswerValue(false);
  };

  onRecordComplete = () => {
    this.setMediaStatus('RECORDED');
    setAttribute('LL_RECORDED', this.props.id);
  };

  onReadyToRecord = () => {
    this.setMediaStatus('NONE');
    ValidationsServiceInstance.resetValidationsForComponent(this.props.id);
  };

  onRecordReset = () => {
    this.setMediaStatus('NONE');
    ValidationsServiceInstance.resetValidationsForComponent(this.props.id);
    setAttribute('LL_RESET', this.props.id);
  };

  // # ----------------------------------------------------------------------
  // # SUBTREES
  // # ----------------------------------------------------------------------

  renderWidgetLoadingError = () => {
    const { htmlInputs, fields } = this.props;
    const errorCode = htmlInputs[fields.errorCode];

    if (errorCode !== ERROR_CODES.ERROR_LOADING_SCRIPT) {
      return null;
    } else {
      const message = this.errorMessages[errorCode];
      const errorCodeMessage = `${ERROR_CODE_MESSAGE}${errorCode}`;

      return [
        <ValidationMessage
          key="msg"
          isError
          hasIcon={false}
          containerStyles={styles.errorMessage}
          caption={message}
        />,
        <ValidationMessage
          key="code"
          isError
          hasIcon={false}
          containerStyles={styles.errorCode}
          caption={errorCodeMessage}
        />
      ];
    }
  };

  renderWidgetError() {
    const { id, htmlInputs, fields, isRequired } = this.props;

    const skipValidation = htmlInputs[fields.skipValidation];
    const errorCode = htmlInputs[fields.errorCode];

    const label = getTranslation('survey.LL_USER_EXPERIENCING_TECH_ISSUES');

    if (Boolean(errorCode) && isRequired) {
      return (
        <Checkbox
          className={styles.checkbox}
          id={`checkboxSkipValidation-${id}`}
          checked={Boolean(skipValidation)}
          onChange={this.setSkipValidation}>
          {label || CHECKBOX_LABEL_FALLBACK}
        </Checkbox>
      );
    }

    return null;
  }

  renderUploadValidationError = () => {
    const { uploadValidationPass, uploadValidationWarning } = this.props;

    if (uploadValidationPass) {
      return null;
    }

    return (
      <ValidationMessage
        key="val-message"
        isError
        hasIcon={false}
        containerStyles={styles.errorMessage}
        caption={uploadValidationWarning}
      />
    );
  };

  // # ----------------------------------------------------------------------
  // # LIFECYCLE
  // # ----------------------------------------------------------------------

  componentDidMount() {
    if (this.multimediaFeedbackRef.current) {
      ScrollServiceInstance.subscribeComponent(this.props.id, this.multimediaFeedbackRef.current);
    }
  }

  shouldComponentUpdate(nextProps: Readonly<MultimediaFeedbackProps>): boolean {
    return (
      !isEqual(this.props.htmlInputs, nextProps.htmlInputs) ||
      !isEqual(this.props.uploadValidationPass, nextProps.uploadValidationPass)
    );
  }

  render() {
    const {
      apiKey,
      channelId,
      respondentId,
      questionId,
      mediaTypes,
      countryCode,
      languageCode,
      minLength,
      maxLength,
      interfaceLanguageCode,
      minWidth,
      maxWidth,
      caption,
      instructions,
      disclaimer,
      htmlInputs,
      dataUpload,
      singleUse,
      disableBranding,
      widgetDomain,
      widgetEnvironment,
      widgetVersion,
      region,
      // MPC-7849 Option to Make Video/Audio Question Required
      isRequired,
      hasValidationFailure,
      hasValidationFailureForEmpty
    } = this.props;

    const Multimedia = this.getMultimediaComponent(widgetVersion);

    return (
      <div className="questionBlock multimediaFeedback" ref={this.multimediaFeedbackRef}>
        <Question
          caption={caption}
          // ERROR: Server
          isRequired={isRequired}
          hasValidationFailure={hasValidationFailure}
          hasValidationFailureForEmpty={hasValidationFailureForEmpty}>
          {instructions && <Text caption={instructions} />}
          {/* ERROR: Client-Side */}
          {this.renderWidgetLoadingError()}
          {this.renderUploadValidationError()}
          <Multimedia
            apiKey={apiKey}
            channelId={channelId}
            respondentId={respondentId}
            questionId={questionId}
            mediaTypes={mediaTypes}
            mediaCountryCode={countryCode}
            mediaLanguageCode={languageCode}
            mediaMinLength={minLength}
            mediaMaxLength={maxLength}
            interfaceLanguageCode={interfaceLanguageCode}
            minWidth={minWidth}
            maxWidth={maxWidth}
            onUploadSuccess={this.setAnswer}
            onRecordComplete={this.onRecordComplete}
            onReadyToRecord={this.onReadyToRecord}
            onRecordReset={this.onRecordReset}
            onError={this.onError}
            allowFileUpload={dataUpload}
            singleUseMode={singleUse}
            disableBranding={disableBranding}
            widgetDomain={widgetDomain}
            widgetEnvironment={widgetEnvironment}
            region={region}
          />
          {this.renderWidgetError()}
          {disclaimer && <Text caption={disclaimer} />}
        </Question>
        {Object.keys(htmlInputs).map((htmlInput) => (
          <input
            type="hidden"
            name={htmlInput}
            id={htmlInput}
            key={htmlInput}
            value={htmlInputs[htmlInput]}
          />
        ))}
      </div>
    );
  }
}
