import isNil from 'lodash/isNil';

export default class FieldsService {
  constructor() {
    this.store = null;
    this.IPCService = null;

    this.getFieldValue = this.getFieldValue.bind(this);
  }

  setStore(store) {
    this.store = store;
    return this;
  }

  setIPCService(IPCService) {
    this.IPCService = IPCService;
    return this;
  }

  getFieldValue(field) {
    const state = this.store.getState();

    // the field may be controlled by more than one component
    // each component may be conditioned
    // at most one of the conditions should be true at a given time
    // fieldRelationships is a list with object like:
    // { question: <questionId>, option?: <optionId> }
    const fieldRelationships = state.fieldControlledBy[field] || [];

    // first we find the active component
    const activeComponent = fieldRelationships.find(({ question, option }) =>
      [question, option]
        .filter((componentId) => !isNil(componentId) && componentId !== '')
        .every(this.IPCService.shouldShowComponent)
    );

    // if there's no active component that controls this field,
    // or if that type of question is still not available to be used in IPCs,
    // then the current value of the field is null
    if (
      !activeComponent ||
      !state.components[activeComponent.question] ||
      !state.components[activeComponent.question].values
    )
      return null;

    const componentState = state.components[activeComponent.question];
    const fieldValue = componentState.values[field];

    // if the field doesn't have conditioned alternatives (i.e., Rating Grid)
    // we can short-circuit here
    if (!activeComponent.alternativesControlledBy) return fieldValue;

    // Other components uses options as what they are: selectable options by the survey taker
    // For components that may have conditioned alternatives, we need to check if the fieldValue
    // has active alternatives

    // In case of multivalued field, we need to remove from fieldValue the inactive options
    if (state.fields[field].isMultiValued) {
      return fieldValue.filter((alternativeId) =>
        this.isAlternativeActive(activeComponent.alternativesControlledBy, alternativeId)
      );
    }

    // For single value field, we need to check that the alternative is active, otherwise it's null
    return this.isAlternativeActive(activeComponent.alternativesControlledBy, fieldValue)
      ? fieldValue
      : null;
  }

  isAlternativeActive(alternativesControlledBy, alternativeId) {
    const optionThatControlsTheAlternative = alternativesControlledBy[alternativeId];
    return alternativeId && this.IPCService.shouldShowComponent(optionThatControlsTheAlternative);
  }
}
