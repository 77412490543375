import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './toggleSwitch.scss';

function ToggleSwitch({ value, onChange, disabled }) {
  const toggleSwitch = (event) => {
    const { checked } = event.target;
    if (isFunction(onChange)) {
      onChange(checked);
    }
  };

  return (
    <label
      id="toggle-switch"
      htmlFor="toggle-switch"
      className={styles['toggle-switch']}
      // TODO: Fix this workaround.
      onClick={toggleSwitch}
    >
      <input type="checkbox" checked={value} disabled={disabled} onChange={toggleSwitch} />
      <span className={styles.switch} />
    </label>
  );
}

ToggleSwitch.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

ToggleSwitch.defaultProps = {
  disabled: false
};

export default ToggleSwitch;
