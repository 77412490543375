import PropTypes from 'prop-types';
import React from 'react';

const ConsentInput = ({
  enabled,
  consentDateKey,
  consentDate,
  consentCategoriesKey,
  consentCategories
}) => {
  if (!enabled || !consentDate) {
    return null;
  }

  return (
    <div>
      <input type="hidden" value={consentDate} name={consentDateKey} />
      {consentCategories.map((category) => (
        <input key={category} type="hidden" value={category} name={consentCategoriesKey} />
      ))}
    </div>
  );
};

ConsentInput.defaultProps = {
  consentDate: ''
};

ConsentInput.propTypes = {
  enabled: PropTypes.bool.isRequired,
  consentDate: PropTypes.string,
  consentDateKey: PropTypes.string.isRequired,
  consentCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  consentCategoriesKey: PropTypes.string.isRequired
};

export default ConsentInput;
