export default function validateAttributesValue(obj, attributes) {
  if (!attributes) return false;
  if (typeof attributes !== 'object') {
    if (process.env.NODE_ENV === 'development') {
      throw new Error(`[${obj.constructor.name}]: attributes must be an object!`);
    }
    return false;
  }
  const keys = Object.keys(attributes);
  for (let i = 0; i < keys.length; i++) {
    const attr = keys[i];
    const validationValues = attributes[attr];
    if (validationValues.constructor !== Array) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log('Validated values must be an array');
      }
      return false;
    }
    if (validationValues.indexOf(obj[attr]) < 0) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log(`[${obj.constructor.name}]: Value must be one of: ${validationValues}`);
      }
      return false;
    }
  }
  return true;
}
