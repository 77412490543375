import PropTypes from 'prop-types';
import React from 'react';

import Icon, { ICON_TYPES } from '@commons/Icon';

import { testName } from '@utils/testUtils';

import styles from './calendarTitle.scss';

const { bool, func, string } = PropTypes;

export default function CalendarTitle(props) {
  const {
    calendarTitle,
    prevMonthAriaLabel,
    nextMonthAriaLabel,
    onClickNextMonth,
    onClickPrevMonth,
    shouldShowNextMonthButton
  } = props;

  return (
    <tr className={styles.calendarTitleContainer}>
      <td>
        <a
          href=""
          aria-label={prevMonthAriaLabel}
          className={styles.prevMonthLink}
          onClick={onClickPrevMonth}
          tabIndex="-1"
          {...testName('prev-month', 'button')}
        >
          <Icon type={ICON_TYPES.ARROW_LEFT} />
        </a>
      </td>
      <td className={styles.calendarTitleLabel} colSpan="5">
        {calendarTitle}
      </td>
      <td>
        {shouldShowNextMonthButton && (
          <a
            href=""
            aria-label={nextMonthAriaLabel}
            className={styles.nextMonthLink}
            onClick={onClickNextMonth}
            tabIndex="-1"
            {...testName('next-month', 'button')}
          >
            <Icon type={ICON_TYPES.ARROW_RIGHT} />
          </a>
        )}
      </td>
    </tr>
  );
}

CalendarTitle.propTypes = {
  calendarTitle: string,
  prevMonthAriaLabel: string,
  nextMonthAriaLabel: string,
  onClickNextMonth: func,
  onClickPrevMonth: func,
  shouldShowNextMonthButton: bool
};
