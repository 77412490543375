import PropTypes from 'prop-types';
import React, { Children, cloneElement } from 'react';

import StackNavigatorView from './StackNavigatorView';
import { propTypes } from './utils';

//----------------------------------------------------------------------
// UTILS
//----------------------------------------------------------------------

const filterChildren = (elm) => elm.type === StackNavigatorView;

const enhanceChildren = (element, navigation) =>
  cloneElement(element, {
    ...element.props,
    navigation
  });

const navigationEnhancer = (elements, navigation) => {
  const children = Children.toArray(elements);
  return children.filter(filterChildren).map((elm) => enhanceChildren(elm, navigation));
};

//----------------------------------------------------------------------
// COMPONENT
//----------------------------------------------------------------------

// TODO: Try to remove this extra div element.
const StackNavigatorConsumer = (props) => (
  <div>{navigationEnhancer(props.children, props.navigation)}</div>
);

StackNavigatorConsumer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  navigation: propTypes.navigation.isRequired
};

export default StackNavigatorConsumer;
