import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import IndicatorRadio from '@commons/IndicatorRadio';
import Input from '@commons/Input';
import Table from '@commons/Table';
import Cell from '@commons/Table/Cell';
import Row from '@commons/Table/Row';

import layoutStyles from '@css/layout.scss';

import {
  isAddingSelectedClassEnabled,
  isAdvancedAccessibilityEnabled
} from '@services/featureFlags';

import styles from './mobileNaOption.scss';

const { any, bool, func, number, string } = PropTypes;

class MobileNaOption extends PureComponent {
  static propTypes = {
    id: string,
    answerQuestion: func,
    ariaLabel: string,
    ariaDescribedBy: string,
    caption: string,
    containerStyles: string,
    hasAnswer: bool,
    htmlInput: string,
    isRequired: bool,
    question: number,
    value: any
  };

  constructor(props) {
    super(props);
    this.handleOnChangeAnswerQuestion = this.handleOnChangeAnswerQuestion.bind(this);
  }

  handleOnChangeAnswerQuestion() {
    const { id, htmlInput, question, value, answerQuestion } = this.props;
    answerQuestion({
      htmlInputId: htmlInput,
      optionId: id,
      questionId: question,
      value
    });
  }

  render() {
    const {
      ariaLabel,
      ariaDescribedBy,
      caption,
      containerStyles,
      hasAnswer,
      htmlInput,
      value,
      isRequired
    } = this.props;

    const mainContainerStyles = classNames(
      styles.mobileNaOptionMainContainer,
      containerStyles,
      layoutStyles.answer,
      { answer_isSelected: isAddingSelectedClassEnabled() && hasAnswer }
    );
    return (
      <div className={mainContainerStyles}>
        <Input
          isRadio
          ariaLabel={ariaLabel}
          ariaRequired={isRequired && !isAdvancedAccessibilityEnabled()}
          ariaDescribedBy={ariaDescribedBy}
          checked={hasAnswer}
          containerStyles={styles.input}
          formKey={htmlInput}
          formValue={value}
          onChange={this.handleOnChangeAnswerQuestion}
        />
        <Table ariaHidden hasFixedLayoutStyle>
          <Row>
            <Cell containerStyles={styles.mobileNaOptionContainer}>
              <IndicatorRadio containerStyles={styles.indicator} isSelected={hasAnswer} />
            </Cell>
            <Cell>
              <p
                className={styles.mobileNaOptionCaption}
                dangerouslySetInnerHTML={{ __html: caption }}
              />
            </Cell>
          </Row>
        </Table>
      </div>
    );
  }
}

export default MobileNaOption;
