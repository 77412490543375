import { withData } from '@services/surveyDataProvider';

import PanelTechnicallyNecessary from './PanelTechnicallyNecessary';

export const mapSurveyDataToProps = (surveyData) => {
  const { title, message, cookiesInfo } = surveyData.cookieBanner.technicallyNecessaryCookiesInfo;
  const { cookieInfoLabels } = surveyData.cookieBanner;

  return {
    title,
    message,
    cookiesInfo,
    cookieInfoLabels
  };
};

export default withData(mapSurveyDataToProps)(PanelTechnicallyNecessary);
