import isFunction from 'lodash/isFunction';

import loadScript from '@services/loadScript';

export const ERROR_CODES = {
  ERROR_LOADING_SCRIPT: 'LL001',
  ERROR_LOADING_WIDGET: 'LL002',
  PUBLISH_FAILED: 'LL003',
  UPLOAD_FAILED: 'LL004',
  UPLOAD_STALLED: 'LL005',
  NO_RECORDING_DEVICE: 'LL006',
  WIDGET_VERSION_NOT_AVAILABLE: 'LL007',
  RECORDING_TIMEOUT: 'LL008',
  APPEND_FILTERS_FAILED: 'LL009'
};

const LIVING_LENS_SCRIPT = 'index.js';
const LIVING_LENS_DEFAULT_DOMAIN = 'https://embed.livinglens.tv';

export default class LivingLensService {
  constructor(widgetDomain, onErrorCallback) {
    this.widgetDomain = widgetDomain || LIVING_LENS_DEFAULT_DOMAIN;
    this.livingLensScriptSrc = `${this.widgetDomain}/${LIVING_LENS_SCRIPT}`;
    this.errorCallback = onErrorCallback;

    this.mockFunction = this.mockFunction.bind(this);
    this.runMockedFunctionsOnLoad = this.runMockedFunctionsOnLoad.bind(this);
    this.errorLoadingScript = this.errorLoadingScript.bind(this);

    this.livingLensMockQueue = [];
    this.LivingLensInstance = {
      startComponent: this.mockFunction('startComponent'),
      startMediaCapture: this.mockFunction('startMediaCapture'),
      startMedalliaMediaCapture: this.mockFunction('startMedalliaMediaCapture'),
      startMediaCaptureTroubleshooter: this.mockFunction('startMediaCaptureTroubleshooter')
    };

    loadScript(this.livingLensScriptSrc, this.runMockedFunctionsOnLoad, this.errorLoadingScript);
  }

  mockFunction(functionName) {
    return (configObject) => {
      this.livingLensMockQueue.push({
        functionName,
        configObject
      });
    };
  }

  runMockedFunctionsOnLoad() {
    this.livingLensMockQueue.forEach(({ functionName, configObject }) => {
      if (isFunction(window.LivingLens[functionName])) {
        window.LivingLens[functionName](configObject);
      } else {
        this.errorCallback(ERROR_CODES.WIDGET_VERSION_NOT_AVAILABLE);
      }
    });

    this.LivingLensInstance = window.LivingLens;
    this.livingLensMockQueue = []; // free up memory
  }

  errorLoadingScript() {
    this.errorCallback(ERROR_CODES.ERROR_LOADING_SCRIPT);
  }

  getInstance() {
    return this.LivingLensInstance;
  }
}
