import PropTypes from 'prop-types';
import React from 'react';

export default class StackNavigator extends React.PureComponent {
  static propTypes = {
    initialRouteName: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { history: [props.initialRouteName] };
  }

  getLastEntry = () => {
    const { history } = this.state;
    return history[history.length - 1];
  };

  push = (id) => {
    this.setState((state) => ({ ...state, history: [...state.history, id] }));
  };

  pop = () => {
    if (this.state.history.length > 1) {
      this.setState((state) => ({
        ...state,
        history: state.history.slice(0, -1)
      }));
    }
  };

  render() {
    const { push, pop, getLastEntry } = this;
    return this.props.children({ push, pop, pathname: getLastEntry() });
  }
}
