import classNames from 'classnames';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React from 'react';

import Dropdown from '@commons/Dropdown/Simple';

import { isDropdownAccessibilityEnabled } from '@services/featureFlags';

import styles from './languageDropdown.scss';

const LanguageDropdown = (props) => {
  const { isCookieBannerOpened, languages, surveyLanguage, onLanguageSelected, label } = props;

  const dropdownList = languages.map((lang) => ({
    text: lang.name,
    id: lang.key,
    langKey: lang.key,
    active: lang.active
  }));
  const optionSelected = dropdownList.find((lang) => lang.id === surveyLanguage);
  const shouldRender = dropdownList.length > 1;
  const elementIdObject = isDropdownAccessibilityEnabled()
    ? { elementId: 'language-dropdown' }
    : {};
  const containerStyles = classNames({
    [styles.dropdown_withCookieBanner]: isCookieBannerOpened
  });

  if (!shouldRender) {
    return null;
  }

  return (
    <Dropdown
      containerStyles={containerStyles}
      dropdownList={dropdownList}
      selected={optionSelected}
      onChange={onLanguageSelected}
      label={label}
      isDropdownLabelInactive={!isDropdownAccessibilityEnabled()}
      selectByWordWhenIsOpenOnly={isDropdownAccessibilityEnabled()}
      {...elementIdObject}
    />
  );
};

LanguageDropdown.propTypes = {
  isCookieBannerOpened: PropTypes.bool.isRequired,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string
    })
  ),
  surveyLanguage: PropTypes.string,
  label: PropTypes.string,
  onLanguageSelected: PropTypes.func
};

LanguageDropdown.defaultProps = {
  languages: [],
  surveyLanguage: 'en',
  label: '',
  onLanguageSelected: noop
};

export default LanguageDropdown;
