const POSSIBLE_CHARACTERS = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

export default class RandomStringGenerator {
  // make this singleton
  constructor() {
    return RandomStringGenerator;
  }

  static HIJACKED_VALUES = [];

  /**
   * Use this function to highjack the RandomStringGenerator.hijack() function and force
   * it to return a set of values in order.
   *
   * THIS IS MAINLY USED FOR TESTING.
   *
   * When a Field is created, it needs an ID. However, it is a UI component and there
   * is no ID given by the backend, so we have to generate a unique hash as the ID
   * using RandomStringGenerator.generate(). But for testing, we need to match it the ID
   * to the specific data stub, and we cannot use a random value generated by
   * RandomStringGenerator.generate(). This is where hijacking comes in. We hijack
   * RandomStringGenerator.generate() to force it to return a set of unique IDs rather than
   * a set of random hashes.
   *
   * Example:
   *
   * RandomStringGenerator.generate(4) // => '_2g5h'
   * RandomStringGenerator.generate(4) // => '_d84h'
   * RandomStringGenerator.generate(4) // => '_Hef7'
   *
   * RandomStringGenerator.hijack(['hijacked_id_1', 'hijacked_id_2']);
   * RandomStringGenerator.generate(4) // => 'hijacked_id_1'
   * RandomStringGenerator.generate(4) // => 'hijacked_id_2'
   * RandomStringGenerator.generate(4) // => '_68dh'
   * RandomStringGenerator.generate(4) // => '_Fhe8'
   */
  static hijack(values) {
    this.HIJACKED_VALUES = this.HIJACKED_VALUES.concat(values);
  }

  static generate(length = 6, prefix = '_') {
    // allow hijacking for testing
    if (this.HIJACKED_VALUES.length > 0) return this.HIJACKED_VALUES.shift();

    let randomString = prefix ? `${prefix}` : '';
    for (let i = length; i > 0; i--) {
      const randomIndex = Math.round(Math.random() * (POSSIBLE_CHARACTERS.length - 1));
      randomString += POSSIBLE_CHARACTERS[randomIndex];
    }
    return randomString;
  }
}
