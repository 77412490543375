import PropTypes from 'prop-types';
import React from 'react';

import { CookieBannerConsentInput } from '@components/CookieBanner';
import ErrorBlock from '@components/ErrorBlock';

import styles from '@css/layout.scss';

import { getTranslation } from '@services/translations';

const { bool, number, oneOf, oneOfType, string } = PropTypes;

export default function PageField({
  ballotVersion,
  ballotVersionNotFound,
  customMessageHandling,
  defPgrAction,
  fieldname,
  fieldval,
  nodeId,
  pageIdxBiggerThanLastShown,
  pagerValidationFailed,
  pageNumber,
  showBackButtonDisabledMessage
}) {
  let ballotVersionNotFoundError;
  let showBackButtonDisabledMessageError;
  let pageIdxBiggerThanLastShownError;
  let pagerValidationFailedError;
  let pageNumberInput;
  let defPgrActionInput;
  let firstError = true;

  if (ballotVersionNotFound) {
    ballotVersionNotFoundError = (
      <ErrorBlock shouldFocus={firstError} text={getTranslation('BALLOT_VERSION_NOT_FOUND')} />
    );
    firstError = false;
  }

  // This error message is covered by a functional test case but it seems not to be in use
  if (showBackButtonDisabledMessage) {
    showBackButtonDisabledMessageError = (
      <ErrorBlock
        shouldFocus={firstError}
        text={getTranslation('showBackButtonDisabledMessageText')}
      />
    );
    firstError = false;
  }

  if (pageIdxBiggerThanLastShown) {
    pageIdxBiggerThanLastShownError = (
      <ErrorBlock shouldFocus={firstError} text={getTranslation('PAGE_IDX_BIGGER_THAN_SHOWN')} />
    );
    firstError = false;
  }

  // customMessageHandling indicates that the TripAdvisorEndPage will show the message by itself
  if (pagerValidationFailed && !customMessageHandling) {
    pagerValidationFailedError = (
      <ErrorBlock shouldFocus={firstError} text={getTranslation('VALIDATION_WARNING_LONG')} />
    );
    firstError = false;
  }

  if (defPgrAction) {
    defPgrActionInput = <input type="hidden" name="defPgrAction" value={defPgrAction} />;
  }

  if (pageNumber !== null) {
    pageNumberInput = <input type="hidden" name="currentPage" value={pageNumber} />;
  }

  return (
    <div className={styles.layoutContainer}>
      {ballotVersionNotFoundError}
      {showBackButtonDisabledMessageError}
      {pageIdxBiggerThanLastShownError}
      {pagerValidationFailedError}

      <input type="hidden" name={fieldname} value={fieldval} />
      <input type="hidden" name="nodeId" value={nodeId} />
      <input type="hidden" name="ballotVer" value={ballotVersion} />
      {pageNumberInput}
      {defPgrActionInput}
      <CookieBannerConsentInput />
    </div>
  );
}

PageField.propTypes = {
  ballotVersion: string,
  ballotVersionNotFound: bool,
  showBackButtonDisabledMessage: bool,
  pageIdxBiggerThanLastShown: bool,
  customMessageHandling: bool,
  defPgrAction: string,
  fieldval: number,
  fieldname: string,
  nodeId: string,
  pagerValidationFailed: bool,
  pageNumber: oneOfType([number, oneOf([null])])
};

PageField.defaultProps = {
  ballotVersion: '0',
  ballotVersionNotFound: false,
  showBackButtonDisabledMessage: false,
  pageIdxBiggerThanLastShown: false,
  customMessageHandling: false,
  defPgrAction: '',
  fieldval: 0,
  fieldname: '',
  nodeId: '',
  pagerValidationFailed: false,
  pageNumber: null
};
