import HtmlInput from '@models/htmlInput';

export default function radio(state, action, htmlInput) {
  let { value } = action.payload;
  value = htmlInput.value === value ? undefined : value;
  const newHtmlInput = new HtmlInput({ ...htmlInput, value });
  return {
    ...state,
    [newHtmlInput.id]: newHtmlInput
  };
}
