export default function validateAttributesPresence(obj, attributes) {
  if (!attributes || attributes.length < 0) return false;
  for (let i = 0; i < attributes.length; i++) {
    const attr = attributes[i];
    if (obj[attr] === undefined || obj[attr] === null) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log(`[${obj.constructor.name}]: ${attr} must be defined!`);
      }
      return false;
    }
  }
  return true;
}
