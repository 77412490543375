/* eslint-disable import/prefer-default-export */

/* eslint-disable react/no-danger */
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './DraggableItem.scss';

const { string, number, func, bool } = PropTypes;

const DropPlaceholder = ({ show, height }) => (
  <div
    className={show ? styles.drop_target : ''}
    style={{ height: show ? `${height}px` : '0', overflow: 'hidden' }}
  />
);

DropPlaceholder.propTypes = {
  show: bool,
  height: number
};

DropPlaceholder.defaultProps = {
  height: null,
  show: null
};

export const DraggableItem = ({
  isDragging,
  isPreview,
  isValidDropTarget,
  orderNumber,
  placeholderHeight,
  setRef,
  showDropPlaceholderAbove,
  showDropPlaceholderBellow,
  text,
  width
}) => {
  const isOrderNumber = Number.isInteger(orderNumber);
  const containerStyles = classnames(styles.container, { [styles.preview]: isPreview });
  const orderNumberClasses = classnames(styles.number_container, {
    [styles.is_dragging]: isDragging,
    [styles.not_valid_target]: !isValidDropTarget
  });

  const callSetRef = (ref) => {
    if (ref === null) {
      return;
    }
    if (setRef) {
      setRef(ref);
    }
  };

  return (
    <div>
      <DropPlaceholder show={showDropPlaceholderAbove} height={placeholderHeight} />
      <div
        className={containerStyles}
        ref={callSetRef}
        style={width ? { width: `${width}px` } : {}}
      >
        <div className={styles.icon_container}>
          <svg width="7" height="14">
            <g fill="#A8A8A8" fillRule="nonzero">
              <circle cx="1" cy="1" r="1" />
              <circle cx="1" cy="7" r="1" />
              <circle cx="1" cy="13" r="1" />
              <circle cx="6" cy="1" r="1" />
              <circle cx="6" cy="7" r="1" />
              <circle cx="6" cy="13" r="1" />
            </g>
          </svg>
        </div>
        {isOrderNumber && (
          <div className={orderNumberClasses} aria-hidden>
            {orderNumber + 1}
          </div>
        )}
        <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
      </div>
      <DropPlaceholder show={showDropPlaceholderBellow} height={placeholderHeight} />
    </div>
  );
};

DraggableItem.propTypes = {
  text: string,
  orderNumber: number,
  isPreview: bool,
  setRef: func,
  width: number,
  placeholderHeight: number,
  isDragging: bool,
  showDropPlaceholderAbove: bool,
  showDropPlaceholderBellow: bool,
  isValidDropTarget: bool
};

DraggableItem.defaultProps = {
  text: '',
  orderNumber: null,
  isPreview: false,
  setRef: null,
  width: null,
  placeholderHeight: null,
  isDragging: null,
  showDropPlaceholderAbove: null,
  showDropPlaceholderBellow: null,
  isValidDropTarget: false
};
