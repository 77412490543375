import PropTypes from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const propTypes = {
  navigation: PropTypes.shape({
    pathname: PropTypes.string,
    push: PropTypes.func,
    pop: PropTypes.func
  })
};
