// eslint-disable-next-line import/prefer-default-export
export const NODE_TYPES = {
  OR: 'orCondition',
  AND: 'andCondition',
  EQ: 'eqCondition',
  COMPARISON: 'compareCondition',
  NUMERIC_ALT_COMPARISON: 'numericAltCompareCondition',
  COUNT: 'countCondition',
  IN_SET: 'inSetCondition',
  RANGE: 'rangeCondition',
  NOT: 'notCondition',
  FIELD: 'field',
  CONSTANT: 'constant',
  MULTI_CONSTANT: 'multiConstant',
  TRUE: 'trueCondition',
  FALSE: 'falseCondition',
  MULTI_VALUE_FIELD: 'multiValueField',
  MULTI_VALUED_SET: 'multiValuedSetCondition',
  MULTI_VALUED_COUNT: 'multiValuedCountCondition',
  SUBSTRING: 'substringCondition'
};
