import isObject from 'lodash/isObject';
import memoize from 'lodash/memoize';

import { getFieldsForEvaluation } from '@models/condition';

import evaluatorRegistry from './evaluators/registry';

function getFieldValueForKey(value) {
  return Array.isArray(value) ? `[${value}]` : value;
}

export function cacheKeyResolver(node, values) {
  const nodeId = isObject(node) ? node.id : node;
  const nodeFieldsForEvaluation = getFieldsForEvaluation(node);

  return `${nodeId}|${nodeFieldsForEvaluation
    .map((field) => `${field}:${getFieldValueForKey(values[field])}`)
    .join(',')}`;
}

export function evaluateNodeFn(node, values) {
  const evaluator = evaluatorRegistry.getEvaluatorForNodeType(node.type);

  return evaluator(node, values);
}

export default memoize(evaluateNodeFn, cacheKeyResolver);
