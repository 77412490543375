import className from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Button, { BUTTON_STYLES } from '@commons/Button';

import styles from './cookieBannerPanelAction.scss';

//----------------------------------------------------------------------
// UTILS
//----------------------------------------------------------------------

export const COOKIE_BANNER_PANEL_ACTION_VARIANT = {
  contained: 'CONTAINED',
  outlined: 'OUTLINED',
  text: 'TEXT'
};

const buttonClassNameMapping = {
  [COOKIE_BANNER_PANEL_ACTION_VARIANT.contained]: BUTTON_STYLES.PRIMARY,
  [COOKIE_BANNER_PANEL_ACTION_VARIANT.outlined]: styles.outlined,
  [COOKIE_BANNER_PANEL_ACTION_VARIANT.text]: styles.text
};

//----------------------------------------------------------------------
// COMPONENT
//----------------------------------------------------------------------

function CookieBannerPanelAction({ name, children, onClick, full, variant }) {
  const containerClassName = className(styles.container, {
    [styles.full]: full
  });
  const buttonClassName = className(styles.button, buttonClassNameMapping[variant]);

  return (
    <Button
      type="button"
      caption={children}
      onClickCallBack={onClick}
      styling={buttonClassName}
      containerStyles={containerClassName}
      name={name}
    />
  );
}

CookieBannerPanelAction.defaultProps = {
  full: false,
  variant: COOKIE_BANNER_PANEL_ACTION_VARIANT.contained,
  // eslint-disable-next-line react/default-props-match-prop-types
  name: ''
};

CookieBannerPanelAction.propTypes = {
  // Variants
  variant: PropTypes.oneOf(Object.values(COOKIE_BANNER_PANEL_ACTION_VARIANT)),
  full: PropTypes.bool,
  // Other Props
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
};

export default CookieBannerPanelAction;
