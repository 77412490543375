import mergeWith from 'lodash/mergeWith';

import ChooseManyLogic, { MATCH_CHOOSE_MANY } from '@models/questionNormalizer/chooseMany';
import DropdownLogic, { MATCH_DROPDOWN } from '@models/questionNormalizer/dropdown';
import LongformLogic, { MATCH_LONGFORM } from '@models/questionNormalizer/longform';
import RatingGridLogic, { MATCH_RATING_GRID } from '@models/questionNormalizer/ratingGrid';
import ResourcesRelationshipManager from '@models/resourcesRelationshipManager';

import mergeCustomizerForAllAndCount from '@utils/mergeCustomizerForAllAndCount';

export const MATCH_MATRIX = 'matrix';

const NormalizerByType = {
  [MATCH_CHOOSE_MANY]: ChooseManyLogic,
  [MATCH_LONGFORM]: LongformLogic,
  [MATCH_RATING_GRID]: RatingGridLogic,
  [MATCH_DROPDOWN]: DropdownLogic
};

export default class MatrixLogic {
  // eslint-disable-next-line class-methods-use-this
  shouldNormalize({ questionData }) {
    return questionData.blockType === MATCH_MATRIX;
  }

  normalize({ id, questionData, rawData, normalizedData }) {
    if (!this.shouldNormalize({ questionData })) return undefined;
    const resourcesManager = new ResourcesRelationshipManager();

    const { rows, columns } = questionData;

    const componentData = {
      values: {}
    };

    // Crear condicion matrix
    resourcesManager.createOrUpdateQuestion({
      id,
      type: MATCH_MATRIX
    });

    const matrixCondition = resourcesManager.createCondition(questionData.condition, id);

    const columnConditions = columns.map(({ id: columnId, condition }) => {
      const option = resourcesManager.createOrUpdateOption({ id: columnId });
      return resourcesManager.createCondition(condition, option.id);
    });

    const rowConditions = rows.map(({ id: rowId, condition }) => {
      const option = resourcesManager.createOrUpdateOption({ id: rowId });
      return resourcesManager.createCondition(condition, option.id);
    });

    rows.forEach(({ cells }, rowIndex) => {
      cells.forEach(({ piece }, columnIndex) => {
        const { fieldName, currentValue, blockType, blockId } = piece;
        const CellNormalizerClass = NormalizerByType[blockType];

        if (CellNormalizerClass) {
          const cellNormalizer = new CellNormalizerClass();
          const normalizedCell = cellNormalizer.normalize({
            id: blockId,
            questionData: piece,
            rawData
          });
          if (normalizedCell === undefined) return;

          const cellQuestion = normalizedCell.questions[blockId];

          cellQuestion.addCondition(matrixCondition);
          cellQuestion.addCondition(rowConditions[rowIndex]);
          cellQuestion.addCondition(columnConditions[columnIndex]);

          mergeWith(normalizedData, normalizedCell, mergeCustomizerForAllAndCount);
        }

        // Rating grid handle the component its own q_fields creation
        if (fieldName && blockType !== MATCH_RATING_GRID) {
          componentData.values[fieldName] = currentValue;
        }
      });
    });

    resourcesManager.createComponent(id, componentData);

    return resourcesManager.resources();
  }
}
