import PropTypes from 'prop-types';
import React from 'react';

import CookieDetails from '@components/CookieBanner/V1/CookieDetails';
import CookieBannerPanel from '@components/CookieBanner/shared/CookieBannerPanel';

const PanelTechnicallyNecessary = ({ title, message, cookiesInfo, cookieInfoLabels }) => (
  <CookieBannerPanel.Root>
    <CookieBannerPanel.Title>{title}</CookieBannerPanel.Title>
    <CookieBannerPanel.Description parseHTML>{message}</CookieBannerPanel.Description>
    <CookieDetails cookiesInfo={cookiesInfo} cookieInfoLabels={cookieInfoLabels} />
  </CookieBannerPanel.Root>
);

PanelTechnicallyNecessary.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  cookiesInfo: PropTypes.arrayOf(PropTypes.any).isRequired,
  cookieInfoLabels: PropTypes.objectOf(PropTypes.any).isRequired
};

export default PanelTechnicallyNecessary;
