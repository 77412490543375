import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { acceptCookies, allowAllCookieCategories } from '@redux/actions/cookieBanner';

import { withData } from '@services/surveyDataProvider';

import PanelMain from './PanelMain';

export const mapSurveyDataToProps = (surveyData) => {
  const { cookieBanner } = surveyData;

  return {
    title: get(cookieBanner, 'title', ''),
    description: get(cookieBanner, 'introductionMessage', ''),
    // CookieBannerPanel.Section
    legalInfoLinkText: get(cookieBanner, 'legalInfo.title', ''),
    consentInfoLinkText: get(cookieBanner, 'consentInfo.title', ''),
    // CookieBannerPanel.ActionArea
    allowAllCookiesButtonText: get(cookieBanner, 'allowAllCookiesButtonText', ''),
    customizeCookiesButtonText: get(cookieBanner, 'customizeCookiesButtonText', ''),
    rejectCookiesButtonText: get(cookieBanner, 'rejectCookiesButtonText', '')
  };
};

const mapStateToProps = (state) => ({
  consentGiven: !isNil(state.cookieBanner.consentDate)
});

export const mapDispatchToProps = (dispatch) => ({
  onAllowAll: () => {
    dispatch(allowAllCookieCategories());
    dispatch(acceptCookies());
  },
  onDecline: () => {
    dispatch(acceptCookies());
  }
});

const composedConnect = compose(
  withData(mapSurveyDataToProps),
  connect(mapStateToProps, mapDispatchToProps)
);

export default composedConnect(PanelMain);
