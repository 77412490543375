import PropTypes from 'prop-types';
import React from 'react';

import CookieBannerPanel from '@components/CookieBanner/shared/CookieBannerPanel';

const PanelLegalNotice = ({ title, description }) => (
  <CookieBannerPanel.Root>
    <CookieBannerPanel.Title>{title}</CookieBannerPanel.Title>
    <CookieBannerPanel.Description parseHTML>{description}</CookieBannerPanel.Description>
  </CookieBannerPanel.Root>
);

PanelLegalNotice.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default PanelLegalNotice;
