import { NODE_TYPES } from '@services/conditionEngine/constants';

function multiValueFieldGenerator(field) {
  return {
    type: NODE_TYPES.MULTI_VALUE_FIELD,
    field
  };
}

export default multiValueFieldGenerator;
