import evaluateNode from '@services/conditionEngine';
import { NODE_TYPES } from '@services/conditionEngine/constants';
import evaluatorRegistry from '@services/conditionEngine/evaluators/registry';

function notEvaluator(node, values) {
  const { child } = node;

  return !evaluateNode(child, values);
}

evaluatorRegistry.registerEvaluatorForNodeType(NODE_TYPES.NOT, notEvaluator);
export default notEvaluator;
