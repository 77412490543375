import Action from './Action';
import ActionArea from './ActionArea';
import Description from './Description';
import Link from './Link';
import Root from './Root';
import Section from './Section';
import Title from './Title';

export default {
  Root,
  Title,
  Description,
  Link,
  Section,
  Action,
  ActionArea
};
