import defaultsDeep from 'lodash/defaultsDeep';
import merge from 'lodash/merge';

import RandomStringGenerator from '@models/randomStringGenerator';

import isDefined from '@utils/isDefined';
import validateAttributesPresence from '@utils/validateAttributesPresence';
import validateAttributesType from '@utils/validateAttributesType';
import validateAttributesValue from '@utils/validateAttributesValue';

const CHECKBOX = 'checkbox';
const HIDDEN = 'hidden';
const RADIO = 'radio';
const TEXT = 'text';
const TEXTAREA = 'textarea';
export const HTML_INPUT_TYPES = {
  CHECKBOX,
  HIDDEN,
  RADIO,
  TEXT,
  TEXTAREA
};
const REQUIRED_ATTRIBUTES = ['question', 'type'];
const TYPED_ATTRIBUTES = { options: Set, possibleValues: Set };
const VALIDATED_ATTRIBUTES = { type: Object.values(HTML_INPUT_TYPES) };

function generateDefaultAttributes() {
  return {
    options: new Set(),
    possibleValues: new Set()
  };
}

export default class HtmlInput {
  constructor(args = {}) {
    if (typeof args !== 'object') {
      if (process.env.NODE_ENV === 'development') {
        throw new Error('[HtmlInput]: args must be an object');
      }
      return {};
    }
    merge(this, args);
    defaultsDeep(this, generateDefaultAttributes());
    if (this.id === undefined || this.id === null) this.id = RandomStringGenerator.generate();

    validateAttributesPresence(this, REQUIRED_ATTRIBUTES);
    validateAttributesType(this, TYPED_ATTRIBUTES);
    validateAttributesValue(this, VALIDATED_ATTRIBUTES);
  }

  isCheckboxType() {
    return this.type === CHECKBOX;
  }

  isHiddenType() {
    return this.type === HIDDEN;
  }

  isRadioType() {
    return this.type === RADIO;
  }

  isTextType() {
    return this.type === TEXT;
  }

  isTextareaType() {
    return this.type === TEXTAREA;
  }

  isBooleanAnswer() {
    return this.type === RADIO || this.type === CHECKBOX;
  }

  isTextAnswer() {
    return this.type === TEXT || this.type === TEXTAREA;
  }

  isAnyAnswer() {
    return this.type === HIDDEN;
  }

  isSameType(htmlInput) {
    if (process.env.NODE_ENV === 'development') {
      if (htmlInput instanceof HtmlInput === false) throw new Error('htmlInput must be HtmlInput');
    }
    return this.type === htmlInput.type;
  }

  isNotSameType(htmlInput) {
    return !this.isSameType(htmlInput);
  }

  hasSameId(htmlInputOrId) {
    const id = htmlInputOrId.id ? htmlInputOrId.id : htmlInputOrId;
    return this.id === id;
  }

  hasValue() {
    if (this.isTextAnswer()) return Boolean(this.value);
    return isDefined({ value: this.value });
  }
}
