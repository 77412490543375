import { isSafariMobile } from '@utils/browserAgent';

export const getVisualViewport = () => window.visualViewport || {};

export const isKeyboardClosed = () => {
  const visualViewport = getVisualViewport();
  if (isSafariMobile() && visualViewport.height) {
    return visualViewport.height === window.innerHeight;
  }
  return true;
};

export const waitForClose = (resolve) => () => {
  if (isKeyboardClosed()) {
    return resolve();
  }
  return false;
};

export const afterSafariKeyboardCloses = () => {
  let waitForCloseInstance;
  const visualViewport = getVisualViewport();

  return new Promise((resolve) => {
    if (!isSafariMobile() || isKeyboardClosed()) {
      return resolve();
    }

    if (isSafariMobile() && visualViewport.addEventListener) {
      waitForCloseInstance = waitForClose(resolve);
      setTimeout(() => resolve(), 2000);
      return visualViewport.addEventListener('resize', waitForCloseInstance);
    }

    return resolve();
  }).then(() => {
    if (waitForCloseInstance && visualViewport.removeEventListener) {
      visualViewport.removeEventListener('resize', waitForCloseInstance);
    }
  });
};
