import PropTypes from 'prop-types';
import React from 'react';

import CookieListItem from '@components/CookieBanner/V3/CookieListItem';

import styles from './cookieList.scss';

const CookieList = ({ items }) => (
  <div className={styles.details}>
    {items.map(({ id, properties }) => (
      <CookieListItem key={id} id={id} properties={properties} />
    ))}
  </div>
);

CookieList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      properties: PropTypes.shape({
        duration: PropTypes.string.isRequired,
        provider: PropTypes.string.isRequired,
        purpose: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired
      })
    })
  ).isRequired
};

export default CookieList;
