import { NODE_TYPES } from '@services/conditionEngine/constants';
import compareEvaluator from '@services/conditionEngine/evaluators/compare';
import { numericValueFieldEvaluator } from '@services/conditionEngine/evaluators/field';
import evaluatorRegistry from '@services/conditionEngine/evaluators/registry';

export function transformToNumericValues(node, values, alternatives) {
  if (node.type !== NODE_TYPES.FIELD) return values;

  return {
    ...values,
    [node.field]: numericValueFieldEvaluator(node, values, alternatives)
  };
}

function numericAltCompareEvaluator(node, values) {
  const { left, right, alternatives, ...conditionNodeSubtract } = node;

  const transformedValues = [left, right].reduce(
    (currentValues, currentNode) =>
      transformToNumericValues(currentNode, currentValues, alternatives),
    values
  );

  const compareConditionNode = {
    ...conditionNodeSubtract,
    left,
    right,
    type: NODE_TYPES.COMPARISON
  };

  return compareEvaluator(compareConditionNode, transformedValues);
}

evaluatorRegistry.registerEvaluatorForNodeType(
  NODE_TYPES.NUMERIC_ALT_COMPARISON,
  numericAltCompareEvaluator
);

export default numericAltCompareEvaluator;
