/* eslint-disable react/jsx-closing-tag-location */
// This linting disables are needed to make accesible html

/* eslint-disable jsx-a11y/no-noninteractive-tabindex */

/* eslint-disable jsx-a11y/no-redundant-roles */
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { getEmptyImage } from 'react-dnd-html5-backend';

import styles from './ReorderableItem.scss';
import { withDndReorder } from './dnd-service';

const { shape, bool, func, number, string, instanceOf } = PropTypes;

export class BareReorderableItem extends React.PureComponent {
  static propTypes = {
    /** Draggable item visualization */
    children: shape(),
    /** Whether this item is being dragged at the time. Passed in automatically by DragSource */
    isDragging: bool,
    /** Function to connect this item as a drag source. Passed in automatically by DragSource */
    connectDragSource: func,
    /** Function to set the drag preview for this item. Passed in automatically by DragSource */
    connectDragPreview: func,
    /** Function to connect this item as a drop target. Passed in automatically by DragSource */
    connectDropTarget: func,
    /** Number defining actual index of the draggable item */
    rowIndex: number,
    /** Number defining the position of focused element */
    focusedIndex: number,
    /** Number defining the position of selected element */
    selectedIndex: number,
    /** Function to modify focusedIndex */
    setFocusedIndex: func,
    /** Value defining the if of element describing actual item */
    ariaDescribedBy: string,
    /** List ref */
    listRef: shape({ current: instanceOf(Element) })
  };

  static defaultProps = {
    isDragging: false,
    connectDragSource: null,
    connectDragPreview: null,
    connectDropTarget: null,
    focusedIndex: undefined,
    selectedIndex: undefined,
    setFocusedIndex: () => {},
    ariaDescribedBy: '',
    children: null,
    rowIndex: null
  };

  constructor(props) {
    super(props);

    this.setEntering = this.setEntering.bind(this);
    this.setEntered = this.setEntered.bind(this);

    this.state = {
      isEntering: false
    };
  }

  componentDidUpdate() {
    this.props.connectDragPreview(getEmptyImage());
    const currentRef = this.optionRef;
    if (!currentRef) {
      return;
    }

    this.handleFocus = (event) => {
      if (event.target === currentRef) {
        this.props.setFocusedIndex(this.props.rowIndex);
      }
    };

    currentRef.addEventListener('focus', this.handleFocus, true);
  }

  componentWillUnmount() {
    this.optionRef.removeEventListener('focus', this.handleFocus);
  }

  setEntering() {
    this.setState({ isEntering: true });
  }

  setEntered() {
    this.setState({ isEntering: false });
  }

  handleFocus = null;

  render() {
    const {
      children,
      isDragging,
      connectDragSource,
      connectDropTarget,
      ariaDescribedBy,
      focusedIndex,
      selectedIndex,
      rowIndex,
      listRef
    } = this.props;

    const isSelected =
      selectedIndex !== undefined
        ? !Number.isNaN(selectedIndex) && focusedIndex === rowIndex
        : false;

    const itemStyles = classnames(styles.draggable_item, {
      [styles.dragging]: isDragging || this.state.isEntering,
      [styles.selected]: isSelected
    });

    return listRef
      ? connectDropTarget(
          <li
            ref={(e) => {
              this.optionRef = e;
            }}
            listRef={listRef}
            tabIndex={0}
            aria-describedby={ariaDescribedBy}
            role="listitem"
            className={classnames({
              [styles.listItemDragging]: isDragging
            })}
          >
            <div className={itemStyles}>
              {connectDragSource(<div className={styles.draggable_item_content}>{children}</div>)}
            </div>
          </li>
        )
      : null;
  }
}

export const ReorderableItem = withDndReorder(BareReorderableItem, 'reorderableItem');
