import { connect } from 'react-redux';

import { pageStatuses } from '@redux/reducers/surveyStatus';

import NavigationButtons from './NavigationButtons';

function mapStateToProps(state) {
  const navigationState = state.navigation || {};
  return {
    ...navigationState,
    moveForwardIsLoading: state.surveyStatus.pageStatus === pageStatuses.FETCHING
  };
}

export default connect(mapStateToProps)(NavigationButtons);
