// classList shim is not included in our polyfill. See:
// https://github.com/zloirock/core-js/issues/287#issuecomment-291679350

export function addClass(domElement, cssClass) {
  if (!domElement) return;

  if (domElement.classList) {
    domElement.classList.add(cssClass);
  } else {
    const classes = domElement.className;
    if (!classes.includes(cssClass)) {
      // eslint-disable-next-line no-param-reassign
      domElement.className += `${classes.length > 0 ? ' ' : ''}${cssClass}`;
    }
  }
}

export function removeClass(domElement, cssClass) {
  if (!domElement) return;

  if (domElement.classList) {
    domElement.classList.remove(cssClass);
  } else {
    const classes = domElement.className;
    if (classes.includes(cssClass)) {
      // eslint-disable-next-line no-param-reassign
      domElement.className = classes
        .split(' ')
        .filter((className) => className !== cssClass)
        .join(' ');
    }
  }
}
