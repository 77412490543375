import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Icon, { ICON_TYPES } from '@commons/Icon';
import Spinner from '@commons/Spinner';

import styles from './button.scss';

const { bool, string, func } = PropTypes;

export const BUTTON_STYLES = {
  PRIMARY: classNames(styles.button, styles.button_isPrimary),
  SECONDARY: classNames(styles.button, styles.button_isSecondary),
  TERTIARY: classNames(styles.button, styles.button_isTertiary)
};

export default function Button(props) {
  const {
    caption,
    containerStyles,
    domId,
    formKey,
    hasSpinner,
    name,
    onClickCallBack,
    showArrowIcon,
    styling,
    type
  } = props;
  const buttonClassNames = `${name} ${styling}`;
  const onClickCB = typeof onClickCallBack === 'function' && { onClick: onClickCallBack };
  const disabled = { disabled: hasSpinner };

  const getIcon = () => {
    if (hasSpinner) {
      return <Spinner />;
    }
    if (showArrowIcon) {
      return <Icon containerStyles={styles.buttonArrowIcon} type={ICON_TYPES.ARROW_RIGHT} />;
    }
  };

  return (
    <div className={containerStyles}>
      <button
        type={type}
        className={buttonClassNames}
        id={domId}
        value={caption}
        name={formKey}
        {...disabled}
        {...onClickCB}>
        <span className={styles.buttonCaption}>{caption}</span>
        {getIcon()}
      </button>
    </div>
  );
}

Button.propTypes = {
  caption: string,
  containerStyles: string,
  domId: string,
  formKey: string,
  hasSpinner: bool,
  name: string,
  onClickCallBack: func,
  showArrowIcon: bool,
  styling: string
};

Button.defaultProps = {
  type: 'submit',
  hasSpinner: false
};
