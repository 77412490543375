import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ARIA_DESCRIPTION_ID } from '@components/CookieBanner/shared/utils';

import styles from './cookieBannerPanelDescription.scss';

function CookieBannerPanelDescription({ children, isMessage, isShrinkedHeight, parseHTML }) {
  const containerStyles = classNames(styles.container, {
    [styles.container_isShrinkedHeight]: isShrinkedHeight
  });
  const textStyles = classNames(styles.text, {
    [styles.text_message]: isMessage
  });
  return (
    <div className={containerStyles}>
      {parseHTML ? (
        <p
          className={textStyles}
          id={ARIA_DESCRIPTION_ID}
          dangerouslySetInnerHTML={{ __html: children }}
        />
      ) : (
        <p className={textStyles} id={ARIA_DESCRIPTION_ID}>
          {children}
        </p>
      )}
    </div>
  );
}

CookieBannerPanelDescription.propTypes = {
  children: PropTypes.node.isRequired,
  isMessage: PropTypes.bool,
  isShrinkedHeight: PropTypes.bool,
  parseHTML: PropTypes.bool
};

CookieBannerPanelDescription.defaultProps = {
  isMessage: false,
  isShrinkedHeight: false,
  parseHTML: false
};

export default CookieBannerPanelDescription;
