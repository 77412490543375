import { connect } from 'react-redux';
import { compose } from 'redux';

import withCondition from '@commons/withCondition';

import { isSurveyAPIEnabled } from '@services/featureFlags';
import { withData } from '@services/surveyDataProvider';

import { formKeyToQField } from '@utils/formKeyAndQFieldTransformations';

import Longform from './Longform';

export function mapStateToProps(state, ownProps) {
  const questions = state.questions || {};
  const question = questions[ownProps.id];
  if (!question) return {};

  const qFields =
    isSurveyAPIEnabled() && question.htmlInputs
      ? [...question.htmlInputs].map(formKeyToQField).filter(Boolean)
      : [];

  return {
    isRequired: question.isRequired,
    ariaLabel: question.ariaLabel,
    caption: !ownProps.isSubComponent ? question.caption : '',
    hasValidationFailure: question.hasValidationFailure,
    hasValidationFailureForEmpty: question.hasValidationFailureForEmpty,
    isMultiSelect: question.isMultiSelect,
    isMultiSelectWithManyOptions: question.isMultiSelectWithManyOptions,
    isSingleSelect: question.isSingleSelect,
    options: question.options,
    selectAllThatApplyMessage: question.selectAllThatApplyMessage,
    qFields
  };
}

export const mapSurveyDataToProps = (surveyData, ownProps) => ({
  validationMessages: ownProps.isSubComponent
    ? []
    : surveyData.blocks[ownProps.id].validationMessages
});

export default compose(
  withCondition,
  withData(mapSurveyDataToProps),
  connect(mapStateToProps)
)(Longform);
