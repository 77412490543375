export const SET_FETCHING_STATUS = 'SET_FETCHING_STATUS';
export const SET_READY_STATUS = 'SET_READY_STATUS';
export const SET_FOCUSED_ELEMENT = 'SET_FOCUSED_ELEMENT';
export const CLEAR_FOCUS = 'CLEAR_FOCUS';

export function setPageFetchingStatus() {
  return {
    type: SET_FETCHING_STATUS
  };
}

export function setPageReadyStatus() {
  return {
    type: SET_READY_STATUS
  };
}

export function setFocusedElement(element) {
  return {
    type: SET_FOCUSED_ELEMENT,
    payload: {
      element
    }
  };
}

export function clearFocus() {
  return {
    type: CLEAR_FOCUS
  };
}

export default setPageFetchingStatus;
