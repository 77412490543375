/* eslint-disable jsx-a11y/no-noninteractive-tabindex */

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

/* eslint-disable import/prefer-default-export */
import clamp from 'lodash/clamp';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React from 'react';

import { getTranslation, getTranslationWithVariables } from '@services/translations';

import { CustomDragLayer } from './CustomDragLayer';
import { ReorderableItem } from './ReorderableItem';
import styles from './ReorderableList.scss';
import {
  GRABBED_ITEM_DROP,
  GRABBED_ITEM_MOVE,
  ITEM_GRABBED,
  REORDER_CANCELED,
  VARIABLE_TO_REPLACE
} from './constants';
import { defaultReorderHandler } from './helpers';

const RESERVED_KEYS = ['ArrowDown', 'ArrowUp', 'ArrowRight', 'ArrowLeft', 'Escape', 'Enter', ' '];

export class ReorderableList extends React.PureComponent {
  static propTypes = {
    /** List of items that will be rendered */
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    ).isRequired,
    /** Function that receives the element index and returns the rendered item */
    children: PropTypes.func,
    /** Function that receives the element index and returns the preview for that element */
    getPreview: PropTypes.func,
    /** Callback to handle reordering, passed in automatically */
    onReorder: PropTypes.func.isRequired,
    /** Method to get DOM ref of dragged item */
    getRef: PropTypes.func
  };

  static defaultProps = {
    children: noop,
    getPreview: null,
    getRef: null
  };

  // ReorderableList.Preview = Preview;
  static defaultReorderHandler = defaultReorderHandler;

  constructor(props) {
    super(props);
    this.onDragTargetUpdate = this.onDragTargetUpdate.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onReorder = this.onReorder.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.resetState = this.resetState.bind(this);
    this.setFocusedIndex = this.setFocusedIndex.bind(this);
    this.setSelectedIndex = this.setSelectedIndex.bind(this);
    this.setItemOrder = this.setItemOrder.bind(this);
    this.setAriaLiveMessage = this.setAriaLiveMessage.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.setIsDragging = this.setIsDragging.bind(this);

    this.state = {
      dropTargetIndex: null,
      dragStartIndex: null,
      ariaLiveMessage: '',
      itemOrder: [],
      selectedIndex: undefined,
      focusedIndex: undefined,
      showDragLayer: false
    };
  }

  componentDidUpdate() {
    const { focusedIndex } = this.state;
    if (this.state.listRef) {
      if (this.handleBlur === null) {
        this.handleBlur = () => {
          /**
           * Without requestAnimationFrame (or setTimeout),
           * document.activeElement always returns <body> regardless of event target
           * Reference: https://developer.mozilla.org/en-US/docs/Web/API/DocumentOrShadowRoot/activeElement */
          requestAnimationFrame(() => {
            if (!this.state.listRef.contains(document.activeElement)) {
              this.setFocusedIndex(undefined);
              this.setSelectedIndex(undefined);
            }
          });
        };
        this.state.listRef.addEventListener('blur', this.handleBlur, true);
      }
      /* find all child draggable elements */
      const foundItems = this.state.listRef.querySelectorAll('[role="listitem"]');
      /* focus child element, if exists */
      if (foundItems && focusedIndex !== undefined && !Number.isNaN(focusedIndex)) {
        const currentFocusedItem = foundItems.item(focusedIndex);
        if (currentFocusedItem) {
          currentFocusedItem.focus();
        }
      }
    }
  }

  componentWillUnmount() {
    this.state.listRef.removeEventListener('blur', this.handleBlur);
  }

  onDragTargetUpdate(updated) {
    this.setState(updated);
  }

  onDragStart(rowIndex) {
    this.setState({
      dragStartIndex: rowIndex,
      focusedIndex: undefined,
      selectedIndex: undefined
    });

    setTimeout(() => {
      this.setState({ showDragLayer: true });
    }, 0);
  }

  onReorder(rowIndex, targetIndex) {
    const focusIndex = targetIndex !== null ? targetIndex : rowIndex;
    this.props.onReorder(rowIndex, targetIndex);
    this.onDrop();
    this.resetState();
    this.setFocusedIndex(focusIndex);
  }

  onDrop() {
    this.setState({
      dropTargetIndex: null,
      dragStartIndex: null
    });

    setTimeout(() => {
      this.setState({ showDragLayer: false });
    }, 0);
  }

  setFocusedIndex(index) {
    this.setState({ focusedIndex: index });
  }

  setSelectedIndex(index) {
    this.setState({ selectedIndex: index });
  }

  setItemOrder(values) {
    this.setState({ itemOrder: values });
  }

  setAriaLiveMessage(message) {
    this.setState({ ariaLiveMessage: message });
  }

  setIsDragging(isDragging) {
    if (isDragging === this.state.isDragging) return;

    this.setState((prevState) => ({
      ...prevState,
      isDragging
    }));
  }

  resetState() {
    this.setState({ selectedIndex: undefined, itemOrder: [] });
  }

  handleBlur = null;

  handleKeyDown = (event) => {
    if (RESERVED_KEYS.indexOf(event.key) === -1) {
      return;
    }
    const { focusedIndex, selectedIndex, itemOrder } = this.state;
    const { items } = this.props;
    const actualItems = itemOrder.length ? itemOrder : items;
    event.preventDefault();
    switch (event.key) {
      case ' ':
        /* Space bar will initiate the select operation or drop the item */
        if (selectedIndex !== undefined && focusedIndex !== undefined) {
          this.setAriaLiveMessage(
            getTranslationWithVariables(GRABBED_ITEM_DROP, VARIABLE_TO_REPLACE, [
              actualItems[focusedIndex].text,
              focusedIndex + 1,
              items.length
            ])
          );
          this.onReorder(selectedIndex, focusedIndex);
        } else {
          this.setSelectedIndex(focusedIndex);
          this.setAriaLiveMessage(
            getTranslationWithVariables(ITEM_GRABBED, VARIABLE_TO_REPLACE, [
              actualItems[focusedIndex].text,
              focusedIndex + 1,
              items.length
            ])
          );
        }
        break;
      case 'ArrowUp': {
        /* If a selected item exists, decrements position. Always updates focus */
        const prevSelectedItem = clamp(
          focusedIndex !== undefined ? focusedIndex - 1 : 0,
          0,
          items.length - 1
        );
        if (selectedIndex !== undefined && focusedIndex !== undefined) {
          this.setAriaLiveMessage(
            getTranslationWithVariables(GRABBED_ITEM_MOVE, VARIABLE_TO_REPLACE, [
              actualItems[focusedIndex].text,
              prevSelectedItem + 1,
              items.length
            ])
          );
          this.setItemOrder(defaultReorderHandler(actualItems, focusedIndex, prevSelectedItem));
        }
        this.setFocusedIndex(prevSelectedItem);
        break;
      }
      case 'ArrowDown': {
        /* If a selected item exists, increments position. Always updates focus */
        const nextSelectedItem = clamp(
          focusedIndex !== undefined ? focusedIndex + 1 : 1,
          0,
          items.length - 1
        );
        if (selectedIndex !== undefined && focusedIndex !== undefined) {
          this.setAriaLiveMessage(
            getTranslationWithVariables(GRABBED_ITEM_MOVE, VARIABLE_TO_REPLACE, [
              actualItems[focusedIndex].text,
              nextSelectedItem + 1,
              items.length
            ])
          );
          this.setItemOrder(defaultReorderHandler(actualItems, focusedIndex, nextSelectedItem));
        }
        this.setFocusedIndex(nextSelectedItem);
        break;
      }
      case 'Escape':
        this.setAriaLiveMessage(
          getTranslationWithVariables(REORDER_CANCELED, VARIABLE_TO_REPLACE, [
            actualItems[focusedIndex].text
          ])
        );
        this.resetState();
        break;
      case 'Enter':
        if (selectedIndex !== undefined && focusedIndex !== undefined) {
          this.setAriaLiveMessage(
            getTranslationWithVariables(GRABBED_ITEM_DROP, VARIABLE_TO_REPLACE, [
              actualItems[focusedIndex].text,
              focusedIndex + 1,
              items.length
            ])
          );
          this.onReorder(selectedIndex, focusedIndex);
        }
        break;
      default:
    }
  };

  render() {
    const {
      dragStartIndex,
      dropTargetIndex,
      isDraggingDown,
      isDraggingUp,
      itemOrder,
      listRef,
      renderableDropTargetIndex,
      isDragging,
      isValidDropTarget
    } = this.state;

    const { items, children, getPreview, getRef } = this.props;

    const actualItems = itemOrder.length > 0 ? itemOrder : items;

    const placeholderHeight =
      actualItems[dragStartIndex] &&
      getRef(actualItems[dragStartIndex].id).getBoundingClientRect().height;

    const listBoundingReact = listRef ? listRef.getBoundingClientRect() : {};

    const renderableItems = actualItems.map((item, rowIndex) => {
      const { id, text } = item;

      const showDropPlaceholder =
        isValidDropTarget &&
        dropTargetIndex === rowIndex && // show only for current drop target
        dragStartIndex !== rowIndex && // don't show for dragged item
        dragStartIndex !== renderableDropTargetIndex;

      const showDropPlaceholderBellow = isDraggingDown && showDropPlaceholder;

      const showDropPlaceholderAbove = isDraggingUp && showDropPlaceholder;

      return (
        <ReorderableItem
          key={id + rowIndex}
          rowIndex={rowIndex}
          onDragStart={this.onDragStart}
          onDragTargetUpdate={this.onDragTargetUpdate}
          onReorder={this.onReorder}
          onDrop={this.onDrop}
          ariaDescribedBy="operation"
          selectedIndex={this.state.selectedIndex}
          focusedIndex={this.state.focusedIndex}
          setFocusedIndex={this.setFocusedIndex}
          listRef={listRef}
        >
          {children(
            id,
            isDragging,
            isValidDropTarget,
            placeholderHeight,
            rowIndex,
            showDropPlaceholderAbove,
            showDropPlaceholderBellow,
            text
          )}
        </ReorderableItem>
      );
    });

    return (
      <div>
        <span aria-live="assertive" className={styles.assistive_text}>
          {this.state.ariaLiveMessage}
        </span>

        <ol
          ref={(e) => {
            this.setState({ listRef: e });
          }}
          className={styles.draggable_list}
          onKeyDown={this.handleKeyDown}
          tabIndex={0}
          aria-describedby="operation"
        >
          {renderableItems}
        </ol>

        {this.state.showDragLayer && (
          <CustomDragLayer
            getPreview={getPreview}
            listBoundingReact={listBoundingReact}
            draggedItemHeight={placeholderHeight}
            setIsDragging={this.setIsDragging}
          />
        )}

        <span id="operation" className={styles.assistive_text} tabIndex={-1}>
          {getTranslation('DRAG_DROP_USAGE')}
        </span>
      </div>
    );
  }
}
