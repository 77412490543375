export function runCallbacks(callbacks) {
  while (callbacks.length > 0) {
    const cb = callbacks.shift();
    cb();
  }
}

export const SCRIPT_STATUS = {
  INIT: 'INIT',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
};

export class Script {
  constructor(src, onLoad, onError) {
    this.src = src;
    this.status = SCRIPT_STATUS.INIT;
    this.onLoadCallbacks = [onLoad];
    this.onErrorCallbacks = [onError];
    this.runLoadCallbacks = this.runLoadCallbacks.bind(this);
    this.runErrorCallbacks = this.runErrorCallbacks.bind(this);
  }

  loadDomScript() {
    const domScript = document.createElement('script');
    domScript.src = this.src;
    domScript.type = 'text/javascript';
    domScript.async = true;
    domScript.onload = this.runLoadCallbacks;
    domScript.onerror = this.runErrorCallbacks;
    document.body.appendChild(domScript);
    this.status = SCRIPT_STATUS.LOADING;
  }

  runLoadCallbacks() {
    runCallbacks(this.onLoadCallbacks);
    this.status = SCRIPT_STATUS.SUCCESS;
  }

  runErrorCallbacks() {
    runCallbacks(this.onErrorCallbacks);
    this.status = SCRIPT_STATUS.ERROR;
  }
}

export const scripts = {};

export function createOrGetScript(scriptSrc, onLoad, onError) {
  let script = scripts[scriptSrc];

  if (!script) {
    script = new Script(scriptSrc, onLoad, onError);
    scripts[scriptSrc] = script;
  }

  return script;
}

export default function loadScript(scriptSrc, onLoad, onError) {
  const script = createOrGetScript(scriptSrc, onLoad, onError);

  if (script.status === SCRIPT_STATUS.INIT) {
    script.loadDomScript();
  } else if (script.status === SCRIPT_STATUS.LOADING) {
    script.onLoadCallbacks.push(onLoad);
    script.onErrorCallbacks.push(onError);
  } else if (script.status === SCRIPT_STATUS.SUCCESS) {
    onLoad();
  } else if (script.status === SCRIPT_STATUS.ERROR) {
    onError();
  }
}
