import surveyDataOverrides from '../../../overrides.json';

//  ----------------------------------------------------------------------
//  UTILS
//  ----------------------------------------------------------------------

function isSBX() {
  const urlParts = location.hostname.split('.');
  return urlParts.some((part) => part === 'sbx');
}

function isMerlin() {
  if (window.surveyData && window.surveyData.specId) {
    const { specId } = window.surveyData;
    return specId.includes('merlin');
  }
  return false;
}

function isLocalServer() {
  return location.hostname === 'localhost' || location.hostname === '127.0.0.1';
}

//  ----------------------------------------------------------------------
//  CORE
//  ----------------------------------------------------------------------

export function shouldEnableSurveyDataOverrides() {
  const conditions = [isLocalServer(), isMerlin(), isSBX()];
  return conditions.some((condition) => condition === true);
}

export { surveyDataOverrides };
