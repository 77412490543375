import PropTypes from 'prop-types';
import React from 'react';

import UploadError from './UploadError';
import UploadingInfo from './UploadingInfo';

const FileRow = (props) => {
  const { file } = props;
  if (file.error) return <UploadError {...props} />;
  if (file.filename && !file.deletedOn) return <UploadingInfo {...props} />;
  return null;
};

FileRow.propTypes = {
  fields: PropTypes.shape({ [PropTypes.string]: PropTypes.string }),
  file: PropTypes.shape({ [PropTypes.string]: PropTypes.any }).isRequired,
  onFileDelete: PropTypes.func.isRequired,
  onFileError: PropTypes.func.isRequired
};

FileRow.defaultProps = {
  fields: {}
};

export default FileRow;
