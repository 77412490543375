import PropTypes from 'prop-types';
import React from 'react';

const { string, bool } = PropTypes;

const ConsentInput = ({ enabled, consentKey, consentDate }) => {
  if (!consentKey || !enabled || !consentDate) {
    return null;
  }
  return <input type="hidden" value={consentDate} name={consentKey} />;
};

ConsentInput.defaultProps = {
  consentDate: ''
};

ConsentInput.propTypes = {
  enabled: bool.isRequired,
  consentKey: string.isRequired,
  consentDate: string
};

export default ConsentInput;
