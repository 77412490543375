import events from './events';
import surveyMethods from './surveyMethods';

const survey = {
  ...events,
  ...surveyMethods
};

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line global-require
  const logger = require('./logger.dev');
  Object.assign(survey, logger);
  logger.register(survey);
}

export function enableSurveyAPI() {
  window.survey = survey;
  window.MedalliaSurvey = survey;
}

export default survey;
