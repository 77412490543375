import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withData } from '@services/surveyDataProvider';

import ConsentInput from './ConsentInput';

export const mapSurveyDataToProps = (surveyData) => {
  const enabled = Object.keys(surveyData.cookieBanner).length > 0;
  return {
    enabled,
    consentDateKey: get(surveyData, 'cookieBanner.cookieConsentDateSubmitKey', ''),
    consentCategoriesKey: get(surveyData, 'cookieBanner.cookieConsentCategoriesSubmitKey', '')
  };
};

export const mapStateToProps = (state) => ({
  consentDate: get(state, 'cookieBanner.consentDate', ''),
  consentCategories: get(state, 'cookieBanner.consentCategories', [])
});

const composedConnect = compose(withData(mapSurveyDataToProps), connect(mapStateToProps));

export default composedConnect(ConsentInput);
