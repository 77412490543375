import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { isA11yWfcEnhancementEnabled } from '@services/featureFlags';

import styles from './progressbar.scss';

export const DEFAULT_ARIA_LABEL = 'Survey completion indicator';

export default class ProgressBar extends Component {
  static propTypes = {
    pagerPercent: PropTypes.number
  };

  static defaultProps = {
    pagerPercent: 0
  };

  render() {
    const progressStyle = {
      width: this.props.pagerPercent + '%'
    };

    // TODO: Use a translation in the future.
    // See: https://jira.medallia.com/browse/OA-23873
    // const ariaProgressbarLabel = getTranslation('PROGRESS_BAR_LABEL') || DEFAULT_ARIA_LABEL;

    return (
      <div
        className={styles.progressBar}
        role="progressbar"
        aria-label={DEFAULT_ARIA_LABEL}
        aria-valuenow={this.props.pagerPercent}
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div className={styles.progressBarIndicator} style={progressStyle} />
      </div>
    );
  }
}
