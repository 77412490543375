import PropTypes from 'prop-types';
import React from 'react';

import { itemShape } from '@commons/Dropdown/Simple/types';
import styles from '@commons/Dropdown/dropdown.scss';
import Icon, { ICON_TYPES } from '@commons/Icon';
import IndicatorCheckbox from '@commons/IndicatorCheckbox';
import Cell from '@commons/Table/Cell';
import Row from '@commons/Table/Row';

import stripHtml from '@utils/stripHtml';
import { testName } from '@utils/testUtils';

function MobileItem(props) {
  const { selectItem, isMultiValued, item, selected } = props;

  function select() {
    selectItem(item);
  }

  const renderContent = () => {
    const optionText = stripHtml(item.text);

    return isMultiValued ? (
      <Row>
        <Cell containerStyles={styles.dropdownListItemMultiValuedCheckboxCell}>
          <IndicatorCheckbox isSelected={selected} />
        </Cell>
        <Cell>{optionText}</Cell>
      </Row>
    ) : (
      <span>
        {optionText}
        {selected && <Icon type={ICON_TYPES.CHECKBOX} containerStyles={styles.dropdownIcon} />}
      </span>
    );
  };

  return (
    <li
      role="menuitem"
      tabIndex="-1"
      className={styles.dropdownListItem}
      onClick={select}
      {...testName(stripHtml(item.text), 'option')}>
      {renderContent()}
    </li>
  );
}

MobileItem.propTypes = {
  isMultiValued: PropTypes.bool.isRequired,
  item: itemShape.isRequired,
  selected: PropTypes.bool.isRequired,
  selectItem: PropTypes.func.isRequired
};

export default MobileItem;
