import { connect } from 'react-redux';

import SelectionCell from './SelectionCell';

export function mapStateToProps(state, ownProps) {
  if (!ownProps.optionId || !state.options || !state.options[ownProps.optionId]) return {};

  const option = state.options[ownProps.optionId];
  return {
    hasAnswer: option.hasAnswer,
    isNaOption: option.isNaOption
  };
}

export default connect(mapStateToProps)(SelectionCell);
