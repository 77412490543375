export const Q_FIELD_PATTERN = '(?:q_[a-z][a-z0-9_]{2,}$)';
export const formKeyPrefixes = ['onf', 'spl', 'ch', 'cal', 'dd'];

// This regex matches against strings like 'onf_q_some_question123' or 'spl_q_another_question'
export const formKeyToQFieldRegex = new RegExp(
  `^(?:${formKeyPrefixes.join('|')})_(${Q_FIELD_PATTERN})`
);

export function formKeyToQField(htmlName) {
  if (!htmlName) return null;

  const matches = htmlName.match(formKeyToQFieldRegex);
  let qField = null;

  // The matching group in the regex is the q-field pattern.
  // This means that the second element in the array is the q-field
  if (matches) {
    [, qField] = matches;
  }

  return qField;
}
