import { NODE_TYPES } from '@services/conditionEngine/constants';
import evaluatorRegistry from '@services/conditionEngine/evaluators/registry';

function multiValueFieldEvaluator(node, values) {
  return values[node.field];
}

evaluatorRegistry.registerEvaluatorForNodeType(
  NODE_TYPES.MULTI_VALUE_FIELD,
  multiValueFieldEvaluator
);
export default multiValueFieldEvaluator;
