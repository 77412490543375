import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Icon, { ICON_TYPES } from '@commons/Icon';

import styles from './uploaderror.scss';

export default class UploadError extends PureComponent {
  constructor(props) {
    super(props);
    this.handleFileError = this.handleFileError.bind(this);
  }

  handleFileError(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.onFileError(this.props.file);
  }

  render() {
    const { file } = this.props;
    const containerStyle = classNames(styles.container, styles.containerError);

    return (
      <li className={containerStyle}>
        <span className={styles.errorMessage}>{file.errorMessage}</span>
        <button className={styles.closeButton} onClick={this.handleFileError}>
          <Icon type={ICON_TYPES.CLOSE} containerStyles={styles.closeIcon} />
        </button>
      </li>
    );
  }
}

UploadError.propTypes = {
  file: PropTypes.shape({ [PropTypes.string]: PropTypes.any }).isRequired,
  onFileError: PropTypes.func.isRequired
};
