import { connect } from 'react-redux';
import { compose } from 'redux';

import { withData } from '@services/surveyDataProvider';

import LanguageDropdown from './LanguageDropdown';

export const mapSurveyDataToProps = (surveyData) => ({
  enabled: Object.keys(surveyData.cookieBanner).length > 0
});

export const mapStateToProps = ({ cookieBanner }, { enabled }) => ({
  isCookieBannerOpened: enabled && cookieBanner.isOpen
});

const composedConnect = compose(withData(mapSurveyDataToProps), connect(mapStateToProps));

export default composedConnect(LanguageDropdown);
