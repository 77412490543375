/* eslint-disable no-continue */
import defaultsDeep from 'lodash/defaultsDeep';
import mergeWith from 'lodash/mergeWith';

import mergeCustomizerForAllAndCount from '@utils/mergeCustomizerForAllAndCount';

import CalendarLogic, { MATCH_CALENDAR } from './calendar';
import ChooseManyLogic, { MATCH_CHOOSE_MANY } from './chooseMany';
import DropdownLogic, { MATCH_DROPDOWN } from './dropdown';
import FileUploadLogic, { MATCH_FILE_UPLOAD } from './fileUpload';
import HtmlLogic, { MATCH_HTML } from './html';
import LongformLogic, { MATCH_LONGFORM, MATCH_NDD_LONGFORM } from './longform';
import MatrixLogic, { MATCH_MATRIX } from './matrix';
import MultimediaFeedbackLogic, { MATCH_MULTIMEDIA_FEEDBACK } from './multimediaFeedback';
import RankingOrderLogic, { MATCH_RANKING_ORDER } from './rankingOrder';
import RatingGridLogic, { MATCH_RATING_GRID } from './ratingGrid';
import TextLogic, { MATCH_TEXT, MATCH_TEXT_QUESTION } from './text';
import TextareaLogic, { MATCH_TEXTAREA } from './textarea';
import TextfieldLogic, { MATCH_TEXTFIELD } from './textfield';
import TooltipLogic, { MATCH_TOOLTIP } from './tooltip';
import TripAdvisorLogic, { MATCH_TRIP_ADVISOR } from './tripadvisor';
import ValidationTextLogic, { MATCH_VALIDATION_TEXT } from './validationText';

export const EmptyLogic = {
  // eslint-disable-next-line class-methods-use-this
  normalize() {
    return undefined;
  }
};

export const getMappedNormalizingLogicForQuestionType = (questionType) => {
  switch (questionType) {
    case MATCH_CALENDAR:
      return new CalendarLogic();
    case MATCH_CHOOSE_MANY:
      return new ChooseManyLogic();
    case MATCH_LONGFORM:
      return new LongformLogic();
    case MATCH_NDD_LONGFORM:
      return new LongformLogic();
    case MATCH_HTML:
      return new HtmlLogic();
    case MATCH_RATING_GRID:
      return new RatingGridLogic();
    case MATCH_TEXTAREA:
      return new TextareaLogic();
    case MATCH_TEXTFIELD:
      return new TextfieldLogic();
    case MATCH_TEXT:
      return new TextLogic();
    case MATCH_TEXT_QUESTION:
      return new TextLogic();
    case MATCH_FILE_UPLOAD:
      return new FileUploadLogic();
    case MATCH_TRIP_ADVISOR:
      return new TripAdvisorLogic();
    case MATCH_TOOLTIP:
      return new TooltipLogic();
    case MATCH_VALIDATION_TEXT:
      return new ValidationTextLogic();
    case MATCH_MULTIMEDIA_FEEDBACK:
      return new MultimediaFeedbackLogic();
    case MATCH_RANKING_ORDER:
      return new RankingOrderLogic();
    case MATCH_MATRIX:
      return new MatrixLogic();
    case MATCH_DROPDOWN:
      return new DropdownLogic();

    default:
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log(`QuestionNormalizer: ${questionType} doesn't exist!`);
      }
      return EmptyLogic;
  }
};

export default class QuestionNormalizer {
  // eslint-disable-next-line class-methods-use-this
  normalize({ rawData, normalizedData = {}, questionBlocks }) {
    defaultsDeep(normalizedData, {
      questions: { all: new Set(), count: 0 },
      options: { all: new Set(), count: 0 },
      htmlInputs: { all: new Set(), count: 0 },
      /**
       * New store slice type. Avoid use of Set
       * and remove all and count keys because
       * only added unnecessary complexities and
       * were not used anywhere
       * We're moving on from that.
       */
      components: {},
      conditions: {},
      fields: {},
      fieldControlledBy: {}
    });

    for (let i = 0; i < questionBlocks.length; i++) {
      const questionData = questionBlocks[i];
      const questionNormalizingLogic = getMappedNormalizingLogicForQuestionType(
        questionData.blockType
      );
      const normalizedQuestion = questionNormalizingLogic.normalize({
        id: questionData.id,
        questionData,
        rawData,
        normalizedData
      });

      if (normalizedQuestion === undefined) continue;
      mergeWith(normalizedData, normalizedQuestion, mergeCustomizerForAllAndCount);
    }

    return normalizedData;
  }
}
