/* eslint-disable react/default-props-match-prop-types */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Icon, { ICON_TYPES } from '@commons/Icon';

import { cookieBannerVersionPropType, isV1 } from '@components/CookieBanner/shared/utils';

import styles from './cookieBannerHeader.scss';

function CookieBannerHeader({
  onBack,
  onClose,
  showBackButton,
  backButtonText,
  closeButtonText,
  version
}) {
  const wrapperClassName = classNames(styles.wrapper, {
    [styles.showBackButton]: showBackButton
  });

  const classes = classNames(styles.header, {
    [styles.showBackButton]: showBackButton
  });

  const handleOnBack = (e) => {
    e.preventDefault();
    onBack();
  };

  return (
    <div className={wrapperClassName}>
      <div className={classes}>
        {showBackButton && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a href="#" className={styles.backButton} onClick={handleOnBack}>
            <Icon type={ICON_TYPES.ARROW_LEFT} />
            <span dangerouslySetInnerHTML={{ __html: backButtonText }} />
          </a>
        )}

        {isV1(version) && (
          <button aria-label={closeButtonText} type="button" onClick={onClose}>
            <Icon type={ICON_TYPES.CLOSE} />
          </button>
        )}
      </div>
    </div>
  );
}

CookieBannerHeader.defaultProps = {
  closeButtonText: '',
  backButtonText: ''
};

CookieBannerHeader.propTypes = {
  version: cookieBannerVersionPropType.isRequired,
  onBack: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  showBackButton: PropTypes.bool.isRequired,
  backButtonText: PropTypes.string.isRequired,
  closeButtonText: PropTypes.string.isRequired
};

export default CookieBannerHeader;
