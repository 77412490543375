import { connect } from 'react-redux';
import { compose } from 'redux';

import { withData } from '@services/surveyDataProvider';

import PanelCookieConsent from './PanelCookieConsent';

export const mapSurveyDataToProps = ({
  cookieBanner: {
    consentInfo: { title, statusText, statusResultText, consentIdText, consentDateText },
    cookieConsentId,
    surveyStartDate
  }
}) => ({
  title,
  descriptionLabel: statusText,
  description: statusResultText,
  consentIdLabel: consentIdText,
  consentId: cookieConsentId,
  consentDateLabel: consentDateText,
  surveyStartDate
});

export const mapStateToProps = ({ cookieBanner }, { surveyStartDate }) => ({
  consentDate: cookieBanner.consentDate || surveyStartDate
});

export default compose(
  withData(mapSurveyDataToProps),
  connect(mapStateToProps)
)(PanelCookieConsent);
