import React from 'react';

import { isGeolocationFetchingSpinnerFixed_FF_Enabled } from '@services/featureFlags';

import styles from './styles.scss';

const Spinner = function () {
  if (isGeolocationFetchingSpinnerFixed_FF_Enabled()) {
    return (
      <div className={styles.container}>
        <div className={styles.loader}></div>
      </div>
    );
  }

  /** Old spinner. Broken for IE 11 and Safari **/
  return (
    <svg
      className={styles.loadingSpinner}
      version="1.1"
      id="L9"
      x="0px"
      y="0px"
      viewBox="15 15 60 60"
      enableBackground="new 0 0 0 0"
    >
      <path
        className={styles.svgPath}
        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
      />
    </svg>
  );
};

export default Spinner;
