import PropTypes from 'prop-types';
import React from 'react';

import Table from '@commons/Table';
import Row from '@commons/Table/Row';

import SelectionCell from '@components/RatingGrid/SelectionCell';

import { isWCAG21_4_Enabled_FF } from '@services/featureFlags';

import styles from './selectionBar.scss';

function createSelectionCells(
  optionIds,
  showNumbers,
  showStars,
  optionDescribedBy,
  isMultiValued,
  size
) {
  return [...optionIds].map((optionId, idx) => {
    // note for future self or whoever implements COCO-5491:
    // key should be the value, because index may change if an option is
    // conditioned out
    return (
      <SelectionCell
        key={idx}
        optionId={optionId}
        showNumbers={showNumbers}
        showStars={showStars}
        optionsSize={size}
        ariaValue={idx + 1}
        optionDescribedBy={optionDescribedBy}
        isMultiValued={isMultiValued}
      />
    );
  });
}

export default function SelectionBar(props) {
  const { optionDescribedBy, optionIds, showNumbers, showStars, isMultiValued } = props;

  return (
    <Table hasFixedLayoutStyle containerStyles={styles.selectionBarContainer}>
      <Row>
        {isWCAG21_4_Enabled_FF() ? (
          <ul>
            {createSelectionCells(
              optionIds,
              showNumbers,
              showStars,
              optionDescribedBy,
              isMultiValued,
              optionIds.size
            )}
          </ul>
        ) : (
          createSelectionCells(
            optionIds,
            showNumbers,
            showStars,
            optionDescribedBy,
            isMultiValued,
            optionIds.size
          )
        )}
      </Row>
    </Table>
  );
}

const { bool, string, instanceOf } = PropTypes;

SelectionBar.propTypes = {
  optionIds: instanceOf(Set),
  optionDescribedBy: string,
  showNumbers: bool,
  showStars: bool,
  isMultiValued: bool
};

SelectionBar.defaultProps = {
  optionIds: new Set([]),
  optionDescribedBy: null,
  showNumbers: false,
  showStars: false,
  isMultiValued: false
};
