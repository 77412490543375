import { withData } from '@services/surveyDataProvider';

import AnchorBar from './AnchorBar';

export const mapSurveyDataToProps = (surveyData, ownProps) => {
  const data = surveyData.blocks[ownProps.questionId];
  const anchors = data.columns.map((column) => ({
    caption: column.caption,
    columnId: column.id
  }));

  return {
    anchors
  };
};

export default withData(mapSurveyDataToProps)(AnchorBar);
