import { ResizeObserver } from '@juggle/resize-observer';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import smoothscroll from 'smoothscroll-polyfill';

import Icon, { ICON_TYPES } from '@commons/Icon';

import { isScrollFixForRTLMatrixEnabled } from '@services/featureFlags';

import styles from './scrollableContent.scss';

smoothscroll.polyfill();

export const SCROLL_DIRECTION = { LEFT: 'LEFT', RIGHT: 'RIGHT' };
const OVERFLOW_TO_SHOW_SCROLL = 5;
const SCROLL = 300;

class ScrollableContent extends React.Component {
  static propTypes = {
    isRTL: PropTypes.bool
  };

  constructor(props) {
    super(props);
    if (isScrollFixForRTLMatrixEnabled() && props.isRTL) {
      this.state = {
        isInStart: false,
        isInEnd: true,
        hasOverflow: false
      };
    } else {
      this.state = {
        isInStart: true,
        isInEnd: false,
        hasOverflow: false
      };
    }
    this.onScroll = this.onScroll.bind(this);
    this.onArrowClick = this.onArrowClick.bind(this);
    this.setRef = this.setRef.bind(this);
    this.evaluateContentOverflow = this.evaluateContentOverflow.bind(this);
    this.contentSizeObserver = new ResizeObserver(this.evaluateContentOverflow);
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-find-dom-node
    const node = ReactDOM.findDOMNode(this);
    this.contentSizeObserver.observe(node);
  }

  componentWillUnmount() {
    this.contentSizeObserver.disconnect();
  }

  setRef(container) {
    this.$container = container;
  }

  onScroll({ target }) {
    if (!target || this.$container !== target) return;

    const { scrollLeft, scrollWidth, clientWidth } = this.$container;
    const { isRTL } = this.props;
    const scrollableSpace = scrollWidth - clientWidth;

    if (isScrollFixForRTLMatrixEnabled() && isRTL) {
      this.setState({
        isInStart: scrollableSpace + scrollLeft < OVERFLOW_TO_SHOW_SCROLL,
        isInEnd: scrollLeft > OVERFLOW_TO_SHOW_SCROLL || scrollLeft === 0
      });
    } else {
      this.setState({
        isInStart: scrollLeft < OVERFLOW_TO_SHOW_SCROLL,
        isInEnd: scrollableSpace - scrollLeft < OVERFLOW_TO_SHOW_SCROLL
      });
    }
  }

  onArrowClick(direction) {
    if (!this.$container) return;

    const { scrollLeft, scrollWidth, clientWidth } = this.$container;
    const { isRTL } = this.props;
    const scrollableSpace = scrollWidth - clientWidth;

    let position;

    if (isScrollFixForRTLMatrixEnabled() && isRTL) {
      position =
        direction === SCROLL_DIRECTION.LEFT
          ? Math.min(scrollableSpace, scrollLeft - (clientWidth - SCROLL))
          : Math.min(0, scrollLeft + (clientWidth - SCROLL));
    } else {
      position =
        direction === SCROLL_DIRECTION.LEFT
          ? Math.max(0, scrollLeft - (clientWidth - SCROLL))
          : Math.min(scrollableSpace, scrollLeft + (clientWidth - SCROLL));
    }

    this.$container.scrollTo({
      top: 0,
      left: position,
      behavior: 'smooth'
    });
  }

  evaluateContentOverflow() {
    if (!this.$container) return;
    const { hasOverflow: prevValue } = this.state;
    const { scrollWidth, clientWidth } = this.$container || {};
    const hasOverflow = scrollWidth > clientWidth;
    if (hasOverflow === prevValue) return;
    this.setState({ hasOverflow });
  }

  render() {
    const { children, className } = this.props;
    const { isInStart, isInEnd, hasOverflow } = this.state;
    const mainContainerStyles = classNames(styles.mainContainer, className);
    const wrapperStyles = classNames(styles.contentWrapper, styles.overflowYVisibleWorkaround, {
      [styles.hideLeftIndicator]: !hasOverflow || isInStart,
      [styles.hideRightIndicator]: !hasOverflow || isInEnd
    });
    return (
      <div className={mainContainerStyles}>
        <div className={wrapperStyles} onScroll={this.onScroll} ref={this.setRef}>
          <span
            className={`${styles.icon} ${styles.leftIcon}`}
            onClick={() => this.onArrowClick(SCROLL_DIRECTION.LEFT)}
            role="button"
            id={`leftIconButton`}>
            <Icon type={ICON_TYPES.ARROW_DROPDOWN} containerStyles={styles.leftIconContent} />
          </span>
          {children}
          <span
            className={`${styles.icon} ${styles.rightIcon}`}
            onClick={() => this.onArrowClick(SCROLL_DIRECTION.RIGHT)}
            role="button"
            id={`rightIconButton`}>
            <Icon type={ICON_TYPES.ARROW_DROPDOWN} containerStyles={styles.rightIconContent} />
          </span>
        </div>
      </div>
    );
  }
}

ScrollableContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default ScrollableContent;
