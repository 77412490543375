import { ACCEPT_COOKIES, SET_COOKIE_CATEGORIES, TOGGLE_BANNER } from '@redux/actions/cookieBanner';

import { getCookieBannerSessionStatus } from '@services/cookieBannerSessionData';

export const getInitialState = (bannerSurveyData = {}) => {
  const sessionData = getCookieBannerSessionStatus(bannerSurveyData.cookieConsentId);
  const consentDate = sessionData.consentDate || bannerSurveyData.cookieConsentDate;
  const consentCategories = bannerSurveyData.cookieConsentCategories || [];

  return {
    consentDate,
    consentCategories,
    isOpen: sessionData.isOpen !== undefined ? sessionData.isOpen : !consentDate
  };
};

export default function reduce(state = {}, action) {
  if (action.type === ACCEPT_COOKIES) {
    return {
      ...state,
      consentDate: action.payload.consentDate,
      isOpen: false,
      opener: null
    };
  }

  if (action.type === SET_COOKIE_CATEGORIES) {
    return {
      ...state,
      consentCategories: action.payload.consentCategories
    };
  }

  if (action.type === TOGGLE_BANNER) {
    return {
      ...state,
      isOpen: !state.isOpen,
      opener: !state.isOpen ? action.payload.opener : null
    };
  }
  return state;
}
