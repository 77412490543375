import React from 'react';

import { isSearchableDropdownEnabled } from '@services/featureFlags';

import { isSafariMobile } from '@utils/browserAgent';

/**
 * This component adds fixes for css that need javascript checks
 * Don't add styles here unless you don't have another option
 * Take account css introduced here doesn't have auto prefixer
 * and is not transpilled. This is plain css3
 *
 */

/** This css fixes iphone safari bottom toolbar behaviour
 * Look for COCO-3231 as related issue.
 */

const generateCustomStyles = (searchableDropdownEnabled) => `
  @media screen and (max-width: 480px) {
    ${!searchableDropdownEnabled && 'html { overflow: scroll; }'}

    body {
      height: 100%;
      /* To smooth any scrolling behavior */
      -webkit-overflow-scrolling: touch;
    }

    footer {
      /* set relative for medallicon position:absolute element
      https://jira.medallia.com/browse/COCO-3489 */
      position: relative;
    }
  }
`;

export default function CustomCss() {
  if (!isSafariMobile()) return null;
  return (
    <style
      dangerouslySetInnerHTML={{
        __html: generateCustomStyles(isSearchableDropdownEnabled())
      }}
    />
  );
}
