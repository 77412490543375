/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './cookieBannerPanelSection.scss';

const CookieBannerPanelSection = ({ children, isRow, noMargin }) => {
  const sectionClassName = classNames(styles.wrapper, {
    [styles.isRow]: isRow,
    [styles.noMargin]: noMargin
  });
  return <div className={sectionClassName}>{children}</div>;
};

CookieBannerPanelSection.propTypes = {
  children: PropTypes.node.isRequired,
  isRow: PropTypes.bool,
  noMargin: PropTypes.bool
};

CookieBannerPanelSection.defaultProps = {
  isRow: false,
  noMargin: false
};

export default CookieBannerPanelSection;
