import lnfClasses from '@css/lnf.scss';

import { addClass, removeClass } from '@utils/domToggleClass';

export const getInputsFromNode = (node) =>
  ['input', 'textarea', 'select'].reduce((list, tagName) => {
    const nodes = node.getElementsByTagName(tagName);
    return list.concat(Array.from(nodes));
  }, []);

export const showDOMNode = (node) => {
  if (node) {
    removeClass(node, lnfClasses.hide);
    // if the components were hidden before, we need to restore the name property so they submit
    // the value to the SE
    const inputs = getInputsFromNode(node);

    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i];
      const inputHiddenName = input.getAttribute('hidden-name');
      if (inputHiddenName) {
        input.setAttribute('name', inputHiddenName);
        input.removeAttribute('hidden-name');
      }
    }
  }
};

export const hideDOMNode = (node) => {
  if (node) {
    addClass(node, lnfClasses.hide);
    // hidden components should not submit the value to the SE
    const inputs = getInputsFromNode(node);

    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i];
      const inputName = input.getAttribute('name');
      if (inputName) {
        input.setAttribute('hidden-name', inputName);
        input.removeAttribute('name');
      }
    }
  }
};
