import { getTranslation } from '@services/translations';

const UPLOAD_PROPERTIES = ['deletedOn', 'fileId', 'filename', 'sizeBytes', 'uploadedOn', 'url'];

const STATUS_NEW = 0;
const STATUS_UPLOADED = 1;

export const COMMON_COMPRESSION_EXTENSIONS = [
  '.7z',
  '.ace',
  '.ani',
  '.app',
  '.arj',
  '.bat',
  '.bfc',
  '.bin',
  '.bkf',
  '.c',
  '.cer',
  '.class',
  '.com',
  '.cpp',
  '.deb',
  '.dll',
  '.dmg',
  '.exe',
  '.gz',
  '.inf',
  '.ini',
  '.iso',
  '.java',
  '.lnk',
  '.msi',
  '.otf',
  '.pkg',
  '.ps1',
  '.r00',
  '.r01',
  '.rar',
  '.rar5',
  '.rpm',
  '.scr',
  '.sys',
  '.tar.gz',
  '.tar',
  '.theme',
  '.tmp',
  '.toast',
  '.ttf',
  '.vbs',
  '.vcd',
  '.z',
  '.zip'
];

export const ERROR_SIZE = 1;
export const ERROR_FILE_TYPE = 2;
export const ERROR_UPLOAD_FAIL = 3;
export const ERROR_MAX_FILE = 4;
export const ERROR_GENERIC = 100;

export const createFieldNameToFieldKeyMap = (fieldsData) =>
  UPLOAD_PROPERTIES.reduce((acc, field) => {
    acc[field] = fieldsData[field].key;
    return acc;
  }, {});

export const normalizeFilesData = ({ upload, fieldsData }) => {
  const files = upload.map(({ PUT, DELETE }, idx) => ({
    deleteUrl: DELETE,
    error: 0,
    errorMessage: null,
    isUploading: false,
    needsDeleteInfo: false,
    order: idx,
    status: STATUS_NEW,
    uploadProgress: 0,
    uploadUrl: PUT,
    xhrRequest: null
  }));

  const numberOfUploadedFiles = fieldsData.filename.values.length;
  for (let i = 0; i < numberOfUploadedFiles; i++) {
    const file = files[i];

    UPLOAD_PROPERTIES.forEach((field) => {
      file[field] = fieldsData[field].values[i] || null;
    });

    if (file.uploadedOn) {
      file.needsDeleteInfo = true;
      file.status = STATUS_UPLOADED;
      file.uploadProgress = 1;
    }
  }

  return files;
};

export const isFileExtensionAllowed = (filename, allowedFileFormats) => {
  for (let j = 0; j < COMMON_COMPRESSION_EXTENSIONS.length; j++) {
    const compressedExtension = COMMON_COMPRESSION_EXTENSIONS[j];
    if (filename.match(new RegExp(`${compressedExtension}$`, 'i'))) return false;
  }

  // empty file formats allows all file format
  if (allowedFileFormats.length === 0) return true;

  for (let i = 0; i < allowedFileFormats.length; i++) {
    const extensionRegex = new RegExp(`${allowedFileFormats[i]}$`, 'i');
    if (filename.match(extensionRegex)) return true;
  }

  return false;
};

export const getErrorMessage = (errorCode, fileName) => {
  if (errorCode === ERROR_SIZE) {
    return getTranslation('survey.validations.FILE_UPLOAD_UPLOAD_FILE_ERROR_SIZE').replace(
      '%s',
      fileName
    );
  }

  if (errorCode === ERROR_FILE_TYPE) {
    return getTranslation('survey.validations.FILE_UPLOAD_UPLOAD_FILE_ERROR_FORMAT').replace(
      '%s',
      fileName
    );
  }

  if (errorCode === ERROR_UPLOAD_FAIL) {
    return getTranslation('survey.validations.FILE_UPLOAD_UPLOAD_FILE_ERROR_FAILED').replace(
      '%s',
      fileName
    );
  }

  if (errorCode === ERROR_MAX_FILE)
    return getTranslation('survey.validations.FILE_UPLOAD_UPLOAD_FILE_MAX_FILE_LIMIT');

  return getTranslation('survey.validations.FILE_UPLOAD_UPLOAD_FILE_ERROR_FAILED');
};

export const createFileWithMaxError = () => ({
  order: 100000,
  error: ERROR_MAX_FILE,
  errorMessage: getErrorMessage(ERROR_MAX_FILE)
});

export const validateFile = ({ file, allowedFileFormats, maxFileBytes }) => {
  const newFile = { ...file };
  const { sizeBytes, filename } = newFile;

  if (!isFileExtensionAllowed(filename, allowedFileFormats)) {
    newFile.error = ERROR_FILE_TYPE;
    newFile.errorMessage = getErrorMessage(ERROR_FILE_TYPE, filename);
  } else if (sizeBytes > maxFileBytes) {
    newFile.error = ERROR_SIZE;
    newFile.errorMessage = getErrorMessage(ERROR_SIZE, filename);
  }

  return newFile;
};

export const getFileSizeLabel = (file) => {
  let conversion = file.sizeBytes / 1024;
  if (conversion <= 999) return `${conversion.toFixed(2)} KB`;

  conversion /= 1024;
  return `${conversion.toFixed(2)} MB`;
};

export const clearFileError = (file) => {
  const newFile = { ...file };
  if (!newFile.error) return newFile;
  newFile.error = 0;
  newFile.errorMessage = null;
  return newFile;
};

export const resetFileToNew = (file) => {
  const newFile = { ...file };
  newFile.status = STATUS_NEW;
  newFile.isUploading = false;
  newFile.uploadProgress = 0;
  newFile.error = 0;
  newFile.errorMessage = null;

  if (newFile.needsDeleteInfo) return newFile;

  UPLOAD_PROPERTIES.forEach((key) => {
    newFile[key] = null;
  });

  return newFile;
};

export const setFileData = ({ file, fileData, isUploading }) => {
  const newFile = { ...file };
  const { name, size } = fileData;
  newFile.fileId = name;
  newFile.filename = name;
  newFile.sizeBytes = size;
  newFile.isUploading = isUploading;
  return newFile;
};

export const getFormData = (file, fields) =>
  UPLOAD_PROPERTIES.map((key) => [fields[key], file[key]]);

export const currySetFileProgressWithFileOrder = (fileOrder, percentage) => (file) => {
  const newFile = { ...file };
  if (newFile.order !== fileOrder) return newFile;
  newFile.uploadProgress = percentage;
  return newFile;
};

export const currySetFileUploadFailedErrorWithFileOrder = (fileOrder) => (file) => {
  const newFile = { ...file };
  if (newFile.order !== fileOrder) return newFile;
  newFile.error = ERROR_UPLOAD_FAIL;
  newFile.errorMessage = getErrorMessage(ERROR_UPLOAD_FAIL, newFile.filename);
  newFile.isUploading = false;
  newFile.uploadProgress = 0;
  return newFile;
};

export const currySetFileFormatErrorWithFileOrder = (fileOrder) => (file) => {
  const newFile = { ...file };
  if (newFile.order !== fileOrder) return newFile;
  newFile.error = ERROR_FILE_TYPE;
  newFile.errorMessage = getErrorMessage(ERROR_FILE_TYPE, newFile.filename);
  return newFile;
};

export const currySetFileUploadedInfoWithFileOrder =
  ({ id, order, uploadedOn, url }) =>
  (file) => {
    const newFile = { ...file };
    if (newFile.order !== order) return newFile;
    newFile.error = 0;
    newFile.errorMessage = null;
    newFile.fileId = id;
    newFile.isUploading = false;
    newFile.status = STATUS_UPLOADED;
    newFile.uploadedOn = uploadedOn;
    newFile.uploadProgress = 1;
    newFile.url = url;
    return newFile;
  };

export const byAscFileOrder = (fileA, fileB) => fileA.order > fileB.order;
export const hasFileError = (file) => !!file.error;
export const hasNoFileError = (file) => !file.error;
export const isFileUploaded = (file) => !!file.uploadedOn;
export const isFileNotUploaded = (file) => !file.uploadedOn;
export const isSameFiles = (filesA, filesB) => {
  if (filesA.length !== filesB.length) return false;
  for (let i = 0; i < filesA.length; i++) {
    const fileA = filesA[i];
    const fileB = filesB[i];
    if (fileA.deletedOn !== fileB.deletedOn) return false;
    if (fileA.deleteUrl !== fileB.deleteUrl) return false;
    if (fileA.error !== fileB.error) return false;
    if (fileA.errorMessage !== fileB.errorMessage) return false;
    if (fileA.fileId !== fileB.fileId) return false;
    if (fileA.filename !== fileB.filename) return false;
    if (fileA.isUploading !== fileB.isUploading) return false;
    if (fileA.needsDeleteInfo !== fileB.needsDeleteInfo) return false;
    if (fileA.order !== fileB.order) return false;
    if (fileA.sizeBytes !== fileB.sizeBytes) return false;
    if (fileA.status !== fileB.status) return false;
    if (fileA.uploadedOn !== fileB.uploadedOn) return false;
    if (fileA.uploadProgress !== fileB.uploadProgress) return false;
    if (fileA.uploadUrl !== fileB.uploadUrl) return false;
    if (fileA.url !== fileB.url) return false;
  }
  return true;
};
