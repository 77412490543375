import PropTypes from 'prop-types';
import React from 'react';

import styles from './cookieListItem.scss';

const CookieListItem = ({ id, properties }) => (
  <div className={styles.item}>
    <p className={styles.id}>{id}</p>
    {Object.entries(properties).map((property) => {
      const [key, value] = property;
      return (
        <div className={styles.row} key={key}>
          <p className={styles.label}>{key}</p>
          <p className={styles.info}>{value}</p>
        </div>
      );
    })}
  </div>
);

CookieListItem.propTypes = {
  id: PropTypes.string.isRequired,
  properties: PropTypes.shape({
    duration: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    purpose: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired
};

export default CookieListItem;
