import { connect } from 'react-redux';
import { compose } from 'redux';

import withCondition from '@commons/withCondition';

import { isSurveyAPIEnabled } from '@services/featureFlags';
import IPCService from '@services/inPageConditioning';

import { formKeyToQField } from '@utils/formKeyAndQFieldTransformations';

import RatingGrid from './RatingGrid';

export const mapStateToProps = (state, ownProps) => {
  if (!state.questions || !state.questions[ownProps.id]) return {};
  const question = state.questions[ownProps.id];
  const {
    ariaLabel,
    caption,
    isRequired,
    options,
    useFieldset,
    hasValidationFailure,
    hasValidationFailureForEmpty
  } = question;

  const optionsSet = [...options];
  const firstVisibleRow = optionsSet.findIndex(IPCService.shouldShowComponent);
  const qFields = isSurveyAPIEnabled()
    ? optionsSet
        .map((optionKey) =>
          state.options[optionKey] ? formKeyToQField(state.options[optionKey].htmlInput) : null
        )
        .filter(Boolean)
    : [];

  return {
    ariaLabel,
    caption,
    isRequired,
    options,
    qFields,
    useFieldset,
    hasValidationFailure,
    hasValidationFailureForEmpty,
    firstVisibleRow
  };
};

export default compose(withCondition, connect(mapStateToProps))(RatingGrid);
