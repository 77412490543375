import { connect } from 'react-redux';
import { compose } from 'redux';

import answerQuestion from '@components/Matrix/actions';

import { withData } from '@services/surveyDataProvider';

import SubComponentCell from './SubComponentCell';

export const mapSurveyDataToProps = (surveyData, { questionId, columnIndex }) => {
  const question = surveyData.blocks[questionId];
  const columnCaption = question.columns[columnIndex].caption;
  return {
    columnCaption
  };
};

const actions = { answerQuestion };

const composedConnect = compose(withData(mapSurveyDataToProps), connect(null, actions));

export default composedConnect(SubComponentCell);
