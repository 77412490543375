import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Tooltip from '@commons/Tooltip';

import styles from './popup.scss';

const { string } = PropTypes;

const popupContainerStyles = `questionBlock standaloneTooltipQuestion ${styles.popupContainer}`;

export function Popup(props) {
  const { ariaLabel, caption, imagePath } = props;

  return (
    <div className={popupContainerStyles}>
      <Tooltip
        alwaysVisibleForVoiceOver
        isTabbable
        ariaLabel={ariaLabel}
        caption={caption}
        containerStyles={styles.toolip}
        imagePath={imagePath}
      />
    </div>
  );
}

Popup.propTypes = {
  ariaLabel: string,
  caption: string,
  imagePath: string
};

function mapStateToProps(state, ownProps) {
  const question = (state.questions || {})[ownProps.id];
  return { ...question };
}

export default connect(mapStateToProps)(Popup);
