/* eslint-disable react/forbid-component-props */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import withCondition from '@commons/withCondition';

import commonStyles from '@css/outline.scss';

import { isWCAG21_4_Enabled_FF } from '@services/featureFlags';

import styles from './cell.scss';

export function mainContainerStyles({ containerStyles, hasBorderStyle, hasHoverStyle }) {
  return classNames(styles.cell, containerStyles, {
    [commonStyles.hasBorder]: hasBorderStyle,
    [commonStyles.hasHover]: hasHoverStyle
  });
}

export default function Cell(props) {
  const {
    ariaHidden,
    ariaLabelledBy,
    ariaRole,
    children,
    containerElement,
    containerStyles,
    hasBorderStyle,
    hasHoverStyle,
    optionsSize
  } = props;
  const cellContainerStyles = mainContainerStyles({
    containerStyles,
    hasBorderStyle,
    hasHoverStyle
  });
  const Container = containerElement;

  if (optionsSize && isWCAG21_4_Enabled_FF()) {
    return (
      <li
        aria-hidden={ariaHidden}
        aria-labelledby={ariaLabelledBy}
        className={cellContainerStyles}
        role={ariaRole}>
        {children}
      </li>
    );
  }

  return (
    <Container
      aria-hidden={ariaHidden}
      aria-labelledby={ariaLabelledBy}
      className={cellContainerStyles}
      role={ariaRole}>
      {children}
    </Container>
  );
}

const { bool, node, string } = PropTypes;

Cell.propTypes = {
  ariaHidden: bool,
  ariaLabelledBy: string,
  ariaRole: string,
  children: node,
  containerElement: string,
  containerStyles: string,
  hasBorderStyle: bool,
  hasHoverStyle: bool
};

Cell.defaultProps = {
  containerElement: 'div'
};

export const ConditionedCell = withCondition(Cell);
