import isNaN from 'lodash/isNaN';

import evaluateNode from '@services/conditionEngine';
import { NODE_TYPES } from '@services/conditionEngine/constants';
import evaluatorRegistry from '@services/conditionEngine/evaluators/registry';

function rangeEvaluator(node, values) {
  const { value, min, max } = node;

  const evaluatedValue = evaluateNode(value, values);
  const numberValue = parseInt(evaluatedValue, 10);

  if (isNaN(numberValue)) {
    throw new Error('Not a number'); // TODO: This probably should be an instance of dedicated error class to tell upper catch block how to recover as we want this function to return "true" by default.
  }

  return min <= numberValue && numberValue <= max;
}

evaluatorRegistry.registerEvaluatorForNodeType(NODE_TYPES.RANGE, rangeEvaluator);
export default rangeEvaluator;
