import PropTypes from 'prop-types';
import React from 'react';

import layoutStyles from '@css/layout.scss';

import styles from './cookieBannerOpener.scss';

const { bool, func, string } = PropTypes;

class CookieBannerOpener extends React.Component {
  constructor(props) {
    super(props);

    this.focusableElementRef = null;
  }

  componentDidUpdate() {
    if (this.props.focused) {
      this.focusableElementRef.focus();
      this.props.clearFocus();
    }
  }

  render() {
    const { enabled, label, onClick, standalone } = this.props;

    if (!enabled) {
      return null;
    }

    const content = (
      <button
        type="button"
        className={styles.link}
        onClick={onClick}
        ref={(element) => {
          this.focusableElementRef = element;
        }}>
        {label}
      </button>
    );

    if (!standalone) {
      return content;
    }

    return (
      <div className={layoutStyles.footer}>
        <div className={layoutStyles.legalLinks}>{content}</div>
      </div>
    );
  }
}

CookieBannerOpener.defaultProps = {
  enabled: false,
  standalone: false,
  focused: false
};

CookieBannerOpener.propTypes = {
  enabled: bool,
  label: string.isRequired,
  onClick: func.isRequired,
  standalone: bool,
  focused: bool,
  clearFocus: func.isRequired
};
export default CookieBannerOpener;
