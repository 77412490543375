import PropTypes from 'prop-types';
import React from 'react';

import styles from './root.scss';

function AccordionRoot(props) {
  return <div className={styles.wrapper}>{props.children}</div>;
}

AccordionRoot.propTypes = {
  children: PropTypes.node.isRequired
};

export default AccordionRoot;
