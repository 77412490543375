import { connect } from 'react-redux';

import withCondition from '@commons/withCondition';

import RowWrapper from './RowWrapper';

export const mapStateToProps = (state) => {
  const mediaQuery = state.mediaQuery || {};
  const isMobileMedia = !mediaQuery.isDesktopMedia && mediaQuery.isMobileMedia;
  return {
    isMobileMedia
  };
};

const ConditionedRowWrapper = withCondition(RowWrapper);

export default connect(mapStateToProps)(ConditionedRowWrapper);
