import PropTypes from 'prop-types';
import React from 'react';

import { propTypes } from './utils';

const StackNavigatorView = (props) => {
  const { id, navigation, children } = props;
  if (navigation.pathname === id) {
    return children;
  }

  return null;
};

StackNavigatorView.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  navigation: propTypes.navigation
};

StackNavigatorView.defaultProps = {
  navigation: {}
};

export default StackNavigatorView;
