import evaluateNode from '@services/conditionEngine';
import { NODE_TYPES } from '@services/conditionEngine/constants';
import evaluatorRegistry from '@services/conditionEngine/evaluators/registry';

function eqEvaluator(node, values) {
  const { left, right } = node;

  return evaluateNode(left, values) === evaluateNode(right, values);
}

evaluatorRegistry.registerEvaluatorForNodeType(NODE_TYPES.EQ, eqEvaluator);
export default eqEvaluator;
