export const isSafariMobile = (windowObject = window) => {
  const userAgent =
    windowObject && windowObject.navigator && windowObject.navigator.userAgent
      ? windowObject.navigator.userAgent
      : null;
  if (!userAgent) {
    return false;
  }
  const iOS = !!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i);
  const webkit = !!userAgent.match(/WebKit/i);
  return iOS && webkit && !userAgent.match(/CriOS/i);
};

export default isSafariMobile;
