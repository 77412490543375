// The action phrase 'Drop files to attach, or browse files.' can't be separated into two phrases
// due to translations. We use 'browse files' as the label for the button but there is no way to
// figure out which part of the phrase is 'browse files' for other languages. Design team wanted
// to optimize for the english common use case and highlight 'browse files' in the action phrase.
// This function will try to regex for the 'browse files' and if it succeeds, we know it's english
// eslint-disable-next-line import/prefer-default-export
export const splitBrowseCaptionForEnglishOnly = (dragOrBrowseCaption) => {
  const regexCapture = dragOrBrowseCaption.match(/(.*) (browse[s]{0,1} file[s]{0,1}\W*)$/i);
  if (!regexCapture) return [dragOrBrowseCaption];

  const dragFileCaption = regexCapture[1];
  const browseCaption = regexCapture[2];

  return [dragFileCaption, browseCaption];
};
