import defaultsDeep from 'lodash/defaultsDeep';
import merge from 'lodash/merge';

import RandomStringGenerator from '@models/randomStringGenerator';

import validateAttributesPresence from '@utils/validateAttributesPresence';
import validateAttributesType from '@utils/validateAttributesType';
import validateAttributesValue from '@utils/validateAttributesValue';

/**
 * This is an exception for AskNow and is actually a Longform multiSelect.
 * ChooseMany uses 1 htmlInput with multi values as checkboxes, which doesn't
 * follow the normal behavior of a checkbox that can only have a value of 0 or 1.
 */
const CHOOSE_MANY = 'ChooseMany';

const HTML = 'Html';
const LONGFORM = 'Longform';
const NND_LONGFORM = 'NestedDropdownsLongform';
const RATING_GRID = 'RatingGrid';
const TEXT = 'Text';
const TEXTAREA = 'Textarea';
const TEXTFIELD = 'Textfield';
const TRIP_ADVISOR = 'TripAdvisor';
const CALENDAR_DATE_PICKER = 'CalendarDatePicker';
const TOOLTIP = 'Tooltip';
const VALIDATION_TEXT = 'ValidationText';
const MULTIMEDIA_FEEDBACK = 'MultimediaFeedback';
const DROPDOWN = 'Dropdown';
const RANKING_QT = 'RankingQT';
const FILE_UPLOAD = 'FileUpload';

export const QUESTION_TYPES = {
  CALENDAR_DATE_PICKER,
  CHOOSE_MANY,
  HTML,
  LONGFORM,
  NND_LONGFORM,
  RATING_GRID,
  TOOLTIP,
  TEXT,
  TEXTAREA,
  TEXTFIELD,
  TRIP_ADVISOR,
  VALIDATION_TEXT,
  MULTIMEDIA_FEEDBACK,
  DROPDOWN,
  RANKING_QT,
  FILE_UPLOAD
};

const REQUIRED_ATTRIBUTES = ['type'];
const TYPED_ATTRIBUTES = { options: Set, htmlInputs: Set };
const VALIDATED_ATTRIBUTES = { type: Object.values(QUESTION_TYPES) };

function generateDefaultAttributes() {
  return {
    options: new Set(),
    optionsWithAnswer: new Set(),
    htmlInputs: new Set(),
    htmlInputsWithAnswer: new Set(),
    isRequired: false,
    isAnswered: false,
    isValid: true,
    isShown: true,
    conditions: []
  };
}

export default class Question {
  constructor(args = {}) {
    if (typeof args !== 'object') {
      if (process.env.NODE_ENV === 'development') {
        throw new Error('[Question]: args must be an object');
      }
      return {};
    }
    merge(this, args);
    defaultsDeep(this, generateDefaultAttributes());

    if (this.id === undefined || this.id === null) this.id = RandomStringGenerator.generate();

    validateAttributesPresence(this, REQUIRED_ATTRIBUTES);
    validateAttributesType(this, TYPED_ATTRIBUTES);
    validateAttributesValue(this, VALIDATED_ATTRIBUTES);
  }

  isHtmlType() {
    return this.type === HTML;
  }

  isLongformType() {
    return this.type === LONGFORM;
  }

  isNestedDropdownLongformType() {
    return this.type === NND_LONGFORM;
  }

  isCalendarDatePickerType() {
    return this.type === CALENDAR_DATE_PICKER;
  }

  isLongformSingleSelectType() {
    return this.isLongformType() && this.isSingleSelect;
  }

  isLongformMultiSelectType() {
    return this.isLongformType() && this.isMultiSelect;
  }

  isRatingGridType() {
    return this.type === RATING_GRID;
  }

  isTextType() {
    return this.type === TEXT;
  }

  isTextareaType() {
    return this.type === TEXTAREA;
  }

  isTextfieldType() {
    return this.type === TEXTFIELD;
  }

  isTripdAdvisorType() {
    return this.type === TRIP_ADVISOR;
  }

  // TODO put here for the sake of completeness. Most of these methods are not used.
  isValidationTextType() {
    return this.type === VALIDATION_TEXT;
  }

  hasSameId(questionOrId) {
    const id = questionOrId.id ? questionOrId.id : questionOrId;
    return this.id === id;
  }

  addCondition(condition) {
    if (!condition) return;

    this.conditions.push(condition.id);
  }
}
