// note: parseInt(x, 10) the 10 is for decimal base
export default function convertToInt(value, defaultValue) {
  const int = parseInt(value, 10);
  if (Number.isNaN(int)) {
    // eslint-disable-next-line no-console
    console.log(`convertToInt(): invalid value ${value}, returning 0!`);
    return defaultValue || 0;
  }
  return int;
}
