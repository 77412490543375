import { NODE_TYPES } from '@services/conditionGenerator/constants';
import multiConstantGenerator from '@services/conditionGenerator/generators/multiConstant';
import multiValueFieldGenerator from '@services/conditionGenerator/generators/multiValueField';

function multiValuedSetGenerator(field, values, op) {
  return {
    type: NODE_TYPES.MULTI_VALUED_SET,
    op,
    left: multiValueFieldGenerator(field),
    right: multiConstantGenerator(values)
  };
}

function multiValuedSetConditionStringGenerator(field, values, op) {
  return `${field} contains ${op} {${values.join(',')}}`;
}

export { multiValuedSetConditionStringGenerator };
export default multiValuedSetGenerator;
