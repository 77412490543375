import classNames from 'classnames';
import React, { DetailedHTMLProps, InputHTMLAttributes, PropsWithChildren } from 'react';

import styles from './checkbox.scss';

// # ----------------------------------------------------------------------
// # TYPES
// # ----------------------------------------------------------------------

type CheckboxProps = PropsWithChildren<
  Pick<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'id' | 'checked' | 'onChange' | 'disabled' | 'className'
  >
>;

// # ----------------------------------------------------------------------
// # UI
// # ----------------------------------------------------------------------

function Checkbox(props: CheckboxProps) {
  const { className, children, id, checked, onChange, disabled } = props;

  const labelClassName = classNames(styles.label, {
    [`${className}`]: Boolean(className)
  });

  return (
    <label htmlFor={id} className={labelClassName}>
      <input id={id} type="checkbox" checked={checked} onChange={onChange} disabled={disabled} />
      {children}
    </label>
  );
}

Checkbox.defaultProps = {
  disabled: false,
  checked: false
};

export default Checkbox;
