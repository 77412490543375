/* eslint-disable no-param-reassign */
import { DragDropContext, DragSource, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';

/** drag source spec for react-dnd */
export const rowDragSource = {
  beginDrag(props) {
    props.onDragStart(props.rowIndex);

    return {
      rowIndex: props.rowIndex,
      targetIndex: null,
      previewTitle: props.previewTitle
    };
  },

  endDrag(props, monitor) {
    if (!monitor.didDrop()) {
      props.onDrop(monitor.getItem());
    }
  }
};

export const getDragDirection = (currentClientOffsetY, lastClientOffsetY) => {
  let isDraggingDown = null;
  let isDraggingUp = null;

  lastClientOffsetY = parseInt(lastClientOffsetY, 10);
  currentClientOffsetY = parseInt(currentClientOffsetY, 10);

  if (lastClientOffsetY && currentClientOffsetY) {
    if (lastClientOffsetY > currentClientOffsetY) {
      isDraggingDown = false;
      isDraggingUp = true;
    }

    if (lastClientOffsetY < currentClientOffsetY) {
      isDraggingDown = true;
      isDraggingUp = false;
    }
  }

  return {
    isDraggingDown,
    isDraggingUp
  };
};

export const getRenderableDropTargetIndex = ({
  draggedItemIndex,
  dropTargetIndex,
  isDraggingDown,
  isDraggingUp
}) => {
  let renderableDropTargetIndex = null;

  if (draggedItemIndex < dropTargetIndex) {
    if (isDraggingDown) {
      renderableDropTargetIndex = dropTargetIndex;
    }
    if (isDraggingUp) {
      renderableDropTargetIndex = dropTargetIndex - 1;
    }
  }

  if (draggedItemIndex > dropTargetIndex) {
    if (isDraggingDown) {
      renderableDropTargetIndex = dropTargetIndex + 1;
    }
    if (isDraggingUp) {
      renderableDropTargetIndex = dropTargetIndex;
    }
  }

  return renderableDropTargetIndex;
};

/** drop target spec for react-dnd */
export const rowDropTarget = {
  hover(dropTarget, monitor, component) {
    const draggedItem = monitor.getItem();
    const {
      lastClientOffsetY,
      rowIndex: draggedItemIndex,
      draggedItemListRef,
      renderableDropTargetIndex
    } = draggedItem;
    const { listRef: initialListRef } = component.props;

    const dropTargetListRef = dropTarget.listRef;
    const dropTargetIndex = dropTarget.rowIndex;
    const clientOffset = monitor.getClientOffset();
    const currentClientOffsetY = clientOffset ? clientOffset.y : null;

    const isValidDropTarget = draggedItemListRef === dropTargetListRef;

    const { isDraggingDown, isDraggingUp } = getDragDirection(
      currentClientOffsetY,
      lastClientOffsetY
    );

    const nextIsDraggingDown =
      isDraggingDown !== null ? isDraggingDown : draggedItem.isDraggingDown;
    const nextIsDraggingUp = isDraggingUp !== null ? isDraggingUp : draggedItem.isDraggingUp;

    const nextRenderableDropTargetIndex = isValidDropTarget
      ? getRenderableDropTargetIndex({
          draggedItemIndex,
          dropTargetIndex,
          isDraggingDown: nextIsDraggingDown,
          isDraggingUp: nextIsDraggingUp
        })
      : renderableDropTargetIndex;

    const updated = {
      dropTargetIndex,
      isDraggingDown: nextIsDraggingDown,
      isDraggingUp: nextIsDraggingUp,
      lastClientOffsetY: currentClientOffsetY,
      renderableDropTargetIndex: nextRenderableDropTargetIndex,
      isValidDropTarget
    };

    if (!draggedItemListRef) {
      updated.draggedItemListRef = initialListRef;
    }

    dropTarget.onDragTargetUpdate(updated);
    Object.assign(draggedItem, updated);
  },

  drop(dropTarget, monitor) {
    const { rowIndex, renderableDropTargetIndex, isValidDropTarget } = monitor.getItem() || {};

    if (!isValidDropTarget) {
      return;
    }

    dropTarget.onReorder(rowIndex, renderableDropTargetIndex);
  },

  canDrop(dropTarget, monitor) {
    const { isValidDropTarget } = monitor.getItem() || {};

    if (!isValidDropTarget) {
      return false;
    }

    return true;
  }
};

export const withDndHtmlContext = (wrappedComponent) =>
  DragDropContext(HTML5Backend)(wrappedComponent);

export const withDndTouchContext = (wrappedComponent) =>
  DragDropContext(
    TouchBackend({
      delayTouchStart: 250
    })
  )(wrappedComponent);

export const dragSource = (wrappedComponent, dragType) =>
  DragSource(dragType, rowDragSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  }))(wrappedComponent);

export const dropTarget = (wrappedComponent, dragType) =>
  DropTarget(dragType, rowDropTarget, (connect) => ({
    connectDropTarget: connect.dropTarget()
  }))(wrappedComponent);

export const withDndReorder = (wrappedComponent, dragType) =>
  dragSource(dropTarget(wrappedComponent, dragType), dragType);
