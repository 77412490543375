import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Calendar from '@components/Calendar';
import FileUpload from '@components/FileUpload';
import HtmlBlock from '@components/HtmlBlock';
import Longform from '@components/Longform';
import Matrix from '@components/Matrix';
import MultimediaFeedback from '@components/MultimediaFeedback';
import NavigationButtons from '@components/NavigationButtons';
import NestedDropdown from '@components/NestedDropdownWrapper';
import Popup from '@components/Popup';
import RankingOrder from '@components/RankingOrder';
import RatingGrid from '@components/RatingGrid';
import SingleDropdown from '@components/SingleDropdown';
import Text from '@components/Text';
import TextArea from '@components/TextArea';
import TextField from '@components/TextField';
import TripAdvisor from '@components/TripAdvisor';
import ValidationText from '@components/ValidationText';

import styles from '@css/layout.scss';

import { isA11yWfcEnhancementEnabled } from '@services/featureFlags';

const mainContainerStyles = classNames(styles.layoutContainer, styles.layoutContainer_fadeInUp);

export default function PageBody(props) {
  return (
    <div role="main" id="content" className={mainContainerStyles}>
      {props.questionBlocks.map((surveyNodeData) => {
        const { id } = surveyNodeData;
        switch (surveyNodeData.blockType) {
          case 'calendar':
            return <Calendar id={id} key={id} />;
          case 'choose':
            return <Longform id={id} key={id} />;
          case 'dropdown':
            return <SingleDropdown {...surveyNodeData} id={id} key={id} />;
          case 'grid':
            return <RatingGrid id={id} key={id} />;
          case 'livinglens':
            return <MultimediaFeedback {...surveyNodeData} key={id} />;
          case 'longform':
            return <Longform id={id} key={id} />;
          case 'mediaStreamerFileUploader':
            return <FileUpload id={id} key={id} {...surveyNodeData} />;
          case 'nestedDropdowns':
            return <NestedDropdown isMobile={props.isMobile} {...surveyNodeData} key={id} />;
          case 'questionText':
            return <Text {...surveyNodeData} key={id} />;
          case 'ranking':
            return <RankingOrder id={id} key={id} />;
          case 'rawhtml':
            return <HtmlBlock {...surveyNodeData} key={id} />;
          case 'text':
            return <Text {...surveyNodeData} key={id} />;
          case 'textarea':
            return <TextArea {...surveyNodeData} key={id} />;
          case 'textfield':
            return <TextField {...surveyNodeData} key={id} />;
          case 'timeout-page':
            return <Text {...surveyNodeData} key={id} />;
          case 'tooltip':
            return <Popup id={id} key={id} />;
          case 'tripAdvisorWidgetPage':
            return <TripAdvisor {...surveyNodeData} key={id} />;
          case 'validationText':
            return <ValidationText {...surveyNodeData} isGlobal key={id} />;
          case 'matrix':
            return <Matrix id={id} key={id} />;
          default:
            return null;
        }
      })}
      {isA11yWfcEnhancementEnabled() && <NavigationButtons />}
    </div>
  );
}

const { arrayOf, object, bool } = PropTypes;

PageBody.propTypes = {
  questionBlocks: arrayOf(object),
  isMobile: bool // eslint-disable-line react/no-unused-prop-types
};

PageBody.defaultProps = {
  questionBlocks: [],
  isMobile: false
};
