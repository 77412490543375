import PropTypes from 'prop-types';
import React, { Component } from 'react';

import LivingLensService, { ERROR_CODES } from '@services/LivingLens';

export default class LivingLensV0 extends Component {
  static propTypes = {
    apiKey: PropTypes.string.isRequired,
    channelId: PropTypes.string.isRequired,
    respondentId: PropTypes.string.isRequired,
    questionId: PropTypes.string.isRequired,
    mediaTypes: PropTypes.arrayOf(PropTypes.oneOf(['video', 'audio', 'image'])).isRequired,
    mediaCountryCode: PropTypes.string.isRequired,
    mediaLanguageCode: PropTypes.string.isRequired,
    mediaMinLength: PropTypes.string,
    mediaMaxLength: PropTypes.string,
    interfaceLanguageCode: PropTypes.string.isRequired,
    minWidth: PropTypes.string,
    maxWidth: PropTypes.string,
    allowFileUpload: PropTypes.bool,
    singleUseMode: PropTypes.bool,
    widgetDomain: PropTypes.string,
    widgetEnvironment: PropTypes.string,
    region: PropTypes.string,
    onUploadSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired
  };

  static defaultProps = {
    mediaMinLength: '',
    mediaMaxLength: '',
    allowFileUpload: false,
    minWidth: '0',
    maxWidth: '700',
    singleUseMode: true,
    region: null
  };

  constructor(props) {
    super(props);

    this.livingLensContainer = `multimedia-${this.props.questionId}`;
    this.mediaCaptureCallbacks = this.mediaCaptureCallbacks.bind(this);
    this.handleErrorLoadingScript = this.handleErrorLoadingScript.bind(this);
    this.LivingLensService = new LivingLensService(
      this.props.widgetDomain,
      this.handleErrorLoadingScript
    );
  }

  mediaCaptureCallbacks(mediaCapture) {
    mediaCapture.onEvent('uploadSuccessful', this.props.onUploadSuccess);
    mediaCapture.onEvent('publishFailed', () => this.props.onError(ERROR_CODES.PUBLISH_FAILED));
    mediaCapture.onEvent('uploadFailed', () => this.props.onError(ERROR_CODES.UPLOAD_FAILED));
    mediaCapture.onEvent('stalledUploadDetected', () =>
      this.props.onError(ERROR_CODES.UPLOAD_STALLED)
    );
    mediaCapture.onEvent('noRecordingDevice', () =>
      this.props.onError(ERROR_CODES.NO_RECORDING_DEVICE)
    );
  }

  handleErrorLoadingScript() {
    this.props.onError(ERROR_CODES.ERROR_LOADING_SCRIPT);
  }

  componentDidMount() {
    const LivingLens = this.LivingLensService.getInstance();
    LivingLens.startMediaCapture({
      target: this.livingLensContainer,
      callback: this.mediaCaptureCallbacks,
      filters: [
        {
          group: 'mec_ballot_id',
          filters: [this.props.respondentId]
        },
        {
          group: 'mec_question_id',
          filters: [this.props.questionId]
        }
      ],
      environment: this.props.widgetEnvironment
    });
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const {
      apiKey,
      channelId,
      respondentId,
      questionId,
      mediaTypes,
      mediaCountryCode,
      mediaLanguageCode,
      mediaMinLength,
      mediaMaxLength,
      interfaceLanguageCode,
      minWidth,
      maxWidth,
      allowFileUpload,
      singleUseMode,
      region
    } = this.props;

    const dataRegion = region ? { 'data-region': region } : {};

    return (
      <div
        data-livinglens-container={this.livingLensContainer}
        data-apikey={apiKey}
        data-channelid={channelId}
        data-respondentid={respondentId}
        data-questionid={questionId}
        data-media={mediaTypes[0]}
        data-minlength={mediaMinLength}
        data-maxlength={mediaMaxLength}
        data-countrycode={mediaCountryCode}
        data-languagecode={mediaLanguageCode}
        data-interfacelanguage={interfaceLanguageCode}
        data-minwidth={minWidth}
        data-maxwidth={maxWidth}
        data-upload={allowFileUpload}
        data-singleuse={singleUseMode}
        {...dataRegion}
      />
    );
  }
}
