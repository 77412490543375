/* eslint-disable react/forbid-component-props */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Question from '@commons/Question';

import layoutStyles from '@css/layout.scss';

import RowOption from './RowOption';

class RatingGrid extends React.Component {
  constructor(props) {
    super(props);

    this.createGridRows = this.createGridRows.bind(this);
  }

  createGridRows(rowProps) {
    const {
      hasValidationFailure,
      hasValidationFailureForEmpty,
      isRequired,
      options,
      isSubComponent,
      firstVisibleRow
    } = this.props;

    // This is a string-only, well formed version of this.props.id
    // If we can guarantee that this.props.id is always a string, we can remove this.
    const { questionId } = rowProps;

    return [...options].map((rowId, idx) => {
      return (
        <RowOption
          key={idx}
          id={rowId}
          hasValidationFailure={hasValidationFailure}
          hasValidationFailureForEmpty={hasValidationFailureForEmpty}
          isFirstRow={firstVisibleRow === idx}
          isRequired={isRequired}
          questionId={questionId}
          isSubComponent={isSubComponent}
        />
      );
    });
  }

  render() {
    const {
      id,
      ariaLabel,
      caption,
      options,
      qFields,
      hasValidationFailure,
      hasValidationFailureForEmpty,
      isRequired,
      useFieldset,
      isSubComponent
    } = this.props;

    const Container = useFieldset ? 'fieldset' : 'div';
    const containerStyles = classNames('questionBlock', 'ratingGridQuestion', {
      [`container_${qFields[0]}`]: qFields.length === 1,
      ratingGridQuestion_hasOneRow: options.size === 1,
      ratingGridQuestion_hasManyRows: options.size >= 2
    });

    const questionId = id !== null ? id.toString() : '';

    return (
      <Container className={containerStyles}>
        {!isSubComponent && (
          <Question
            ariaId={questionId}
            ariaLabel={ariaLabel}
            caption={caption}
            hasValidationFailure={hasValidationFailure}
            hasValidationFailureForEmpty={hasValidationFailureForEmpty}
            isRequired={isRequired}
            hasLegendCaption={useFieldset}
            captionId={questionId}
          />
        )}
        <div className={layoutStyles.answers}>{this.createGridRows({ questionId })}</div>
      </Container>
    );
  }
}

const { oneOf, oneOfType, bool, instanceOf, number, string, arrayOf } = PropTypes;

RatingGrid.propTypes = {
  // Id property can be 0
  id: oneOfType([number, oneOf([null])]),
  ariaLabel: string,
  caption: string,
  hasValidationFailure: bool,
  hasValidationFailureForEmpty: bool,
  isRequired: bool,
  options: instanceOf(Set),
  useFieldset: bool,
  qFields: arrayOf(string),
  isFirstRow: bool,
  isSubComponent: bool
};

RatingGrid.defaultProps = {
  id: null,
  ariaLabel: '',
  caption: '',
  options: new Set([]),
  hasValidationFailure: false,
  hasValidationFailureForEmpty: false,
  isRequired: false,
  useFieldset: false,
  qFields: [],
  isSubComponent: false,
  isFirstRow: false
};

export default RatingGrid;
