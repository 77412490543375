import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './indicatorRadio.scss';

export default function IndicatorRadio(props) {
  const { containerStyles, isSelected } = props;
  const mainContainerStyles = classNames(
    styles.indicatorRadioContainer,
    containerStyles,
    'indicatorRadio',
    {
      [styles.indicatorRadioContainer_isSelected]: isSelected
    }
  );
  return <span className={mainContainerStyles} />;
}

const { bool, string } = PropTypes;

IndicatorRadio.propTypes = {
  containerStyles: string,
  isSelected: bool
};
