import differenceBy from 'lodash/differenceBy';

import IPCService from '@services/inPageConditioning';

function getAllOptions(state, ownProps) {
  return ownProps.row || [];
}
function getAllOptionIds(state, ownProps) {
  return getAllOptions(state, ownProps).map((alt) => alt.id);
}

function hydrateOptions(state, ownProps, optionIds) {
  const optionsByAltId = getAllOptions(state, ownProps).reduce((obj, alt) => {
    // eslint-disable-next-line no-param-reassign
    obj[alt.id] = alt;
    return obj;
  }, {});

  return optionIds.map((altId) => optionsByAltId[altId] || null);
}

function getSelectedOptionIds(state, ownProps) {
  return state.components[ownProps.id].values[ownProps.fieldName] || [];
}

function getSelectedOptions(state, ownProps) {
  return hydrateOptions(state, ownProps, getSelectedOptionIds(state, ownProps));
}

function filterByIPC(componentId, fieldName, alternatives) {
  return alternatives.filter(
    (alt) => !alt || IPCService.shouldShowAlternative(componentId, fieldName, alt.id || alt)
  );
}

function getAllOptionIdsFilteredByIPC(state, ownProps) {
  return filterByIPC(ownProps.id, ownProps.fieldName, getAllOptionIds(state, ownProps));
}

function getAllOptionsFilteredByIPC(state, ownProps) {
  return filterByIPC(ownProps.id, ownProps.fieldName, getAllOptions(state, ownProps));
}

function getSelectedOptionIdsFilteredByIPC(state, ownProps) {
  const selectedOptionIds = filterByIPC(
    ownProps.id,
    ownProps.fieldName,
    getSelectedOptionIds(state, ownProps)
  );

  // Hacky times!
  // Selected options length should always be <= total options length.
  // But with the dropdown, we may have an alternative set at any position
  // Imagine a list of ten alternatives. One was sent to the last position.
  // Then through IPC, all the unselected alternatives are conditioned out.
  // We end up having an alternative in the position 10, but the survey taker
  // only sees one alternative available.
  // There's no easy way to address this, so we'll just remove nulls starting
  // from the end until selected.length <= options.length

  const allOptions = getAllOptionsFilteredByIPC(state, ownProps);
  let nullsToBeRemoved = Math.max(selectedOptionIds.length - allOptions.length, 0);
  let i = selectedOptionIds.length - 1;

  while (nullsToBeRemoved > 0 && i >= 0) {
    if (!selectedOptionIds[i]) {
      selectedOptionIds.splice(i, 1);
      nullsToBeRemoved--;
    }
    i--;
  }

  return selectedOptionIds;
}

function getSelectedOptionsFilteredByIPC(state, ownProps) {
  return hydrateOptions(state, ownProps, getSelectedOptionIdsFilteredByIPC(state, ownProps));
}

function getUnselectedOptionsFilteredByIPC(state, ownProps) {
  const allOptions = getAllOptionsFilteredByIPC(state, ownProps);
  const selectedOptions = getSelectedOptionsFilteredByIPC(state, ownProps);

  return differenceBy(allOptions, selectedOptions, 'id');
}

export {
  getAllOptionIds,
  getAllOptions,
  getSelectedOptionIds,
  getSelectedOptions,
  filterByIPC,
  getAllOptionIdsFilteredByIPC,
  getAllOptionsFilteredByIPC,
  getSelectedOptionIdsFilteredByIPC,
  getSelectedOptionsFilteredByIPC,
  getUnselectedOptionsFilteredByIPC
};
