import { COOKIE_CATEGORY } from '@components/CookieBanner/V3/utils';

import { saveSessionConsent, saveSessionOpenStatus } from '@services/cookieBannerSessionData';

import { setFocusedElement } from './setPageStatus';

export const ACCEPT_COOKIES = 'ACCEPT_COOKIES';
export const SET_COOKIE_CATEGORIES = 'SET_COOKIE_CATEGORIES';

export const TOGGLE_BANNER = 'TOGGLE_BANNER';

export const toggleBanner =
  (trigger) =>
  (dispatch, getState, { getSurveyData }) => {
    const { cookieConsentId } = getSurveyData().cookieBanner;
    const { isOpen, opener } = getState().cookieBanner;
    saveSessionOpenStatus(cookieConsentId, !isOpen);
    dispatch({
      type: TOGGLE_BANNER,
      payload: {
        opener: trigger
      }
    });
    if (isOpen && opener) {
      dispatch(setFocusedElement(opener));
    }
  };

export const acceptCookies =
  (date = new Date()) =>
  (dispatch, getState, { getSurveyData }) => {
    const { cookieConsentId } = getSurveyData().cookieBanner;
    const { opener } = getState().cookieBanner;

    const consentDate = date.toISOString();

    saveSessionConsent(cookieConsentId, consentDate);
    saveSessionOpenStatus(cookieConsentId, false);
    dispatch({
      type: ACCEPT_COOKIES,
      payload: {
        consentDate
      }
    });
    if (opener) {
      dispatch(setFocusedElement(opener));
    }
  };

export const toggleCookieCategory = (category) => (dispatch, getState) => {
  const { consentCategories } = getState().cookieBanner;
  let newConsentCategories = [];

  if (consentCategories.includes(category)) {
    newConsentCategories = consentCategories.filter((c) => c !== category);
  } else {
    newConsentCategories = [...consentCategories, category];
  }

  dispatch({
    type: SET_COOKIE_CATEGORIES,
    payload: {
      consentCategories: newConsentCategories
    }
  });
};

export const allowAllCookieCategories = () => (dispatch) => {
  const allCategories = Object.values(COOKIE_CATEGORY);

  dispatch({
    type: SET_COOKIE_CATEGORIES,
    payload: {
      consentCategories: allCategories
    }
  });
};

export const revokeAllCookieCategories = () => (dispatch) => {
  dispatch({
    type: SET_COOKIE_CATEGORIES,
    payload: {
      consentCategories: []
    }
  });
};
