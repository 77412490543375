import { formKeyPrefixes } from '@utils/formKeyAndQFieldTransformations';

export const getHtmlInput = (state, qField) => {
  // store.htmlInputs is a key/value object with keys like onf_q_field, ch_q_field, etc.
  // we need to loop each form key prefix, concat it with the q-field,
  // and see if it exists at store.htmlInputs
  const prefix = formKeyPrefixes.find((keyPrefix) => state.htmlInputs[`${keyPrefix}_${qField}`]);

  if (!prefix) return null;

  return state.htmlInputs[`${prefix}_${qField}`];
};

export const getQuestion = (state, qField) => {
  const htmlInput = getHtmlInput(state, qField);

  if (!htmlInput) return null;

  const questionId = htmlInput.question;

  if (questionId == null || !state.questions[questionId]) return null;

  return state.questions[questionId];
};

export const getAnswerValue = (state, qField) => {
  const htmlInput = getHtmlInput(state, qField);

  if (!htmlInput) return null;

  return htmlInput.value;
};

export const getAnswerLabels = (state, qField) => {
  const question = getQuestion(state, qField);

  if (!question) return null;

  const mainOptionId = question.options.values().next().value;

  if (!mainOptionId) return null;

  const optionsIds = Array.from(state.options[mainOptionId].options);

  if (!optionsIds) return null;

  if (state.options[mainOptionId].hasNaOption) {
    optionsIds.push(state.options[mainOptionId].naOption);
  }

  const options = optionsIds.map((optionId) => state.options[optionId]);

  if (!options) return null;

  return options.filter((option) => option.hasAnswer).map((option) => option.caption);
};
