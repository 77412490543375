import { connect } from 'react-redux';
import { compose } from 'redux';

import withCondition from '@commons/withCondition';

import { isSurveyAPIEnabled } from '@services/featureFlags';

import { formKeyToQField } from '@utils/formKeyAndQFieldTransformations';

import RowOption, { LEFT, TOP } from './RowOption';

const getCaptionPosition = (option, question, isMobileMedia) => {
  const optionsQuantity = question.options && question.options.size;
  if (isMobileMedia || question.forceQuestionAboveScale || option.forceQuestionAboveScale) {
    return TOP;
  }
  if (
    question.simpleQuestionGrid ||
    optionsQuantity > 1 ||
    /** If forceQuestionAboveScale is specifically false, the user wants it on the left */
    option.forceQuestionAboveScale === false ||
    question.forceQuestionAboveScale === false
  ) {
    return LEFT;
  }
  return TOP;
};

export const mapStateToProps = (state, ownProps) => {
  const options = state.options || {};
  const option = options[ownProps.id];
  if (!option) return {};
  const question =
    state.questions && state.questions[ownProps.questionId]
      ? state.questions[ownProps.questionId]
      : {};
  const hasSiblings = question.options && question.options.size > 1;
  const mediaQuery = state.mediaQuery || {};
  const isMobileMedia = !mediaQuery.isDesktopMedia && mediaQuery.isMobileMedia;
  const { isRequired } = question;
  const { hasNaOption, naOption } = option;
  const naOptionElement = hasNaOption && naOption && options[naOption];

  return {
    isMobileMedia,
    hasNaOption,
    naOption,
    isRequired,
    hasSiblings,
    anchors: question.anchors,
    captionPosition: getCaptionPosition(option, question, isMobileMedia),
    caption: option.caption,
    isNaSelected: naOptionElement.hasAnswer,
    hiddenLegendForADA: option.hiddenLegendForADA,
    naOptionCaption: option.naOptionCaption,
    naValue: option.naValue,
    options: option.options,
    showNumbers: option.showNumbers,
    showStars: option.showStars,
    useFieldset: option.useFieldset,
    isMultiValued: option.isMultiValued,
    isDesktopMedia: mediaQuery.isDesktopMedia,
    qField: isSurveyAPIEnabled() ? formKeyToQField(option.htmlInput) : null,
    mainCaption: question.caption,
    hasValidationFailure: option.hasValidationFailure,
    hasValidationFailureForEmpty: option.hasValidationFailureForEmpty
  };
};

export default compose(withCondition, connect(mapStateToProps))(RowOption);
