import { QUESTION_TYPES } from '@models/question';
import ResourcesRelationshipManager from '@models/resourcesRelationshipManager';

export const MATCH_RANKING_ORDER = 'ranking';

export default class RankingOrderLogic {
  // eslint-disable-next-line class-methods-use-this
  shouldNormalize({ questionData }) {
    return questionData.blockType === MATCH_RANKING_ORDER;
  }

  normalize({ id, questionData }) {
    if (!this.shouldNormalize({ questionData })) return undefined;
    const resourcesManager = new ResourcesRelationshipManager();

    const { selectedFormKey, condition, fieldName, row: rows, selected } = questionData;

    const componentData = {
      values: {
        [selectedFormKey]: []
      }
    };

    resourcesManager.createOrUpdateQuestion({
      id,
      type: QUESTION_TYPES.RANKING_QT
    });
    resourcesManager.createComponent(id, componentData);
    const initialValue = selected || [];
    resourcesManager.createMultiValuedField(fieldName, initialValue, id);
    resourcesManager.createCondition(condition, id);

    rows.forEach((alternative) => {
      const option = resourcesManager.createOrUpdateOption({
        question: id,
        caption: alternative.text
      });
      resourcesManager.createAlternative(fieldName, alternative.id, option.id);
      resourcesManager.createCondition(alternative.condition, option.id);
    });

    return resourcesManager.resources();
  }
}
