import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { getTranslation } from '@services/translations';

import FileUpload from './FileUpload';
import {
  byAscFileOrder,
  clearFileError,
  createFieldNameToFieldKeyMap,
  createFileWithMaxError,
  currySetFileFormatErrorWithFileOrder,
  currySetFileProgressWithFileOrder,
  currySetFileUploadFailedErrorWithFileOrder,
  currySetFileUploadedInfoWithFileOrder,
  hasFileError,
  hasNoFileError,
  isFileNotUploaded,
  isFileUploaded,
  normalizeFilesData,
  resetFileToNew,
  setFileData,
  validateFile
} from './helpers/file';
import { splitBrowseCaptionForEnglishOnly } from './helpers/utils';

export default class FileUploadContainer extends Component {
  constructor(props) {
    super(props);

    const { fields, maxFileSizeMb, maxNumFiles, upload } = props;

    this.handleClearFileError = this.handleClearFileError.bind(this);
    this.handleClearMaxError = this.handleClearMaxError.bind(this);
    this.handleDeleteFile = this.handleDeleteFile.bind(this);
    this.handleFilesUpload = this.handleFilesUpload.bind(this);
    this.handleUploadButtonClick = this.handleUploadButtonClick.bind(this);
    this.updateFileFailedFormatError = this.updateFileFailedFormatError.bind(this);
    this.updateFileMetaData = this.updateFileMetaData.bind(this);
    this.updateFileUploadError = this.updateFileUploadError.bind(this);
    this.updateFileUploadProgress = throttle(this.updateFileUploadProgress.bind(this), 1500, {
      leading: true
    });

    this.acceptMultipleFiles = maxNumFiles > 1;
    this.maxFileBytes = maxFileSizeMb * 1024 * 1024;
    this.setLabels();

    const files = normalizeFilesData({ upload, fieldsData: fields });
    const filesToUpload = files.filter(isFileNotUploaded).sort(byAscFileOrder);
    const filesUploaded = files.filter(isFileUploaded);
    this.fieldNameToFieldKey = createFieldNameToFieldKeyMap(fields);

    this.state = {
      filesToUpload,
      filesUploaded,
      fileMaxError: null
    };
  }

  setLabels() {
    const { caption, additionalText, allowedFileFormats, maxFileSizeMb, maxNumFiles } = this.props;

    const allowedFileFormatsLabel = allowedFileFormats.join(', ');

    // allowedFileFormats is blank when all files are allowed.
    // By default, executable and compressed files are never allowed
    this.fileFormatLabel =
      allowedFileFormats.length > 0
        ? `${getTranslation(
            'survey.FILE_UPLOAD_UPLOAD_FILE_FORMAT_ALLOWED'
          )} ${allowedFileFormatsLabel}.`
        : getTranslation('survey.FILE_UPLOAD_UPLOAD_FILE_FORMAT_NOT_ALLOWED');

    this.questionLabel = caption;
    this.consentLabel = additionalText;
    this.maxFilesLabel = getTranslation('survey.FILE_UPLOAD_UPLOAD_FILE_COUNT_ALLOWED');
    this.maxFileSizeLabel = getTranslation('survey.FILE_UPLOAD_UPLOAD_FILE_SIZE_ALLOWED');

    this.maxFilesLabel = `${this.maxFilesLabel} ${maxNumFiles}.`;
    this.maxFileSizeLabel = `${this.maxFileSizeLabel} ${maxFileSizeMb} MB.`;

    this.inputAcceptExtensionsLabel = allowedFileFormats
      .map((extension) => `.${extension}`)
      .join(', ');
  }

  setMobileLabels() {
    const { isMobile } = this.props;

    let actionButtonLabel = getTranslation('survey.FILE_UPLOAD_UPLOAD_FILE_MOBILE');
    this.actionButtonLabel = actionButtonLabel;
    this.prefixActionButtonLabel = '';
    if (!isMobile) {
      actionButtonLabel = getTranslation('survey.FILE_UPLOAD_UPLOAD_FILE_DESKTOP');
      const [prefixActionButtonLabel, parsedActionButtonLabel] =
        splitBrowseCaptionForEnglishOnly(actionButtonLabel);
      if (parsedActionButtonLabel) {
        this.prefixActionButtonLabel = prefixActionButtonLabel;
        this.actionButtonLabel = parsedActionButtonLabel;
      }
    }
  }

  setStateForFiles({ filesUploaded, filesToUpload }) {
    const newState = {};
    if (filesUploaded) newState.filesUploaded = filesUploaded;
    if (filesToUpload) newState.filesToUpload = filesToUpload;

    const isUploading = !!filesUploaded.find((file) => file.isUploading);

    if (isUploading) {
      this.setState(newState);
    } else {
      this.setState(newState, this.props.setPageReadyStatus);
    }
  }

  handleUploadButtonClick() {
    const { filesUploaded, filesToUpload } = this.state;

    const filesUploadedWithoutErrors = filesUploaded.filter(hasNoFileError);
    const filesUploadedWithError = filesUploaded.filter(hasFileError).map(resetFileToNew);
    const refreshedFilesToUpload = [...filesUploadedWithError, ...filesToUpload].sort(
      byAscFileOrder
    );

    this.setStateForFiles({
      filesUploaded: [...filesUploadedWithoutErrors],
      filesToUpload: refreshedFilesToUpload
    });
  }

  updateFileUploadProgress(event, fileOrder) {
    const { loaded, total } = event;
    const percentage = loaded / total;

    const { filesUploaded } = this.state;
    const setFileProgress = currySetFileProgressWithFileOrder(fileOrder, percentage);
    const updatedFiles = filesUploaded.map(setFileProgress);

    this.setStateForFiles({ filesUploaded: updatedFiles });
  }

  updateFileUploadError(fileOrder) {
    const { filesUploaded } = this.state;

    const setFileUploadFailedError = currySetFileUploadFailedErrorWithFileOrder(fileOrder);
    const updatedFiles = filesUploaded.map(setFileUploadFailedError);

    this.setStateForFiles({ filesUploaded: updatedFiles });
  }

  updateFileFailedFormatError(fileOrder) {
    const { filesUploaded } = this.state;

    const setFileFormatError = currySetFileFormatErrorWithFileOrder(fileOrder);
    const updatedFiles = filesUploaded.map(setFileFormatError);

    this.setStateForFiles({ filesUploaded: updatedFiles });
  }

  updateFileMetaData(xhr, fileOrder) {
    const { filesUploaded } = this.state;

    let updatedFiles;
    try {
      const fileUrl = xhr.getResponseHeader('location') || 'blah';
      const { fileId } = JSON.parse(xhr.response);
      const setFileUploadInfo = currySetFileUploadedInfoWithFileOrder({
        id: fileId,
        order: fileOrder,
        uploadedOn: new Date().toISOString(),
        url: fileUrl
      });
      updatedFiles = filesUploaded.map(setFileUploadInfo);
    } catch (__e) {
      const setFileUploadFailedError = currySetFileUploadFailedErrorWithFileOrder(fileOrder);
      updatedFiles = filesUploaded.map(setFileUploadFailedError);
    }

    this.setStateForFiles({ filesUploaded: updatedFiles });
  }

  handleFilesUpload(event) {
    const { allowedFileFormats, setPageFetchingStatus } = this.props;

    const filesData = [...event.target.files];
    const filesToUpload = [...this.state.filesToUpload];
    const filesUploaded = [...this.state.filesUploaded];

    let fileMaxError = filesToUpload.length === 0 ? createFileWithMaxError() : null;

    if (fileMaxError) {
      this.setState({ fileMaxError });
      return;
    }

    filesData.forEach((fileData) => {
      if (fileMaxError) return;

      let file = filesToUpload.shift();
      if (!file) {
        fileMaxError = createFileWithMaxError();
        return;
      }

      file = setFileData({ file, fileData, isUploading: true });
      file = validateFile({
        file,
        allowedFileFormats,
        maxFileBytes: this.maxFileBytes
      });

      if (file.error) {
        filesUploaded.push(file);
        return;
      }

      const fileOrder = file.order;

      const xhr = new XMLHttpRequest();
      file.xhrRequest = xhr;

      const formdata = new FormData();
      formdata.append('file', fileData);
      formdata.append('metadata', '{}');

      xhr.open('put', file.uploadUrl);
      xhr.upload.onprogress = (e) => this.updateFileUploadProgress(e, fileOrder);
      xhr.upload.onerror = () => this.updateFileUploadError(fileOrder);
      xhr.onreadystatechange = () => {
        // 400s are still success requests and will not trigger onerror
        // file mime was rejected
        if (xhr.status === 415) {
          this.updateFileFailedFormatError(fileOrder);
          return;
        }

        if (xhr.status >= 400 && xhr.status <= 512) {
          this.updateFileUploadError(fileOrder);
          return;
        }

        if (xhr.readyState !== 4) return;
        this.updateFileMetaData(xhr, fileOrder);
      };

      xhr.send(formdata);
      filesUploaded.push(file);
    });

    this.setState(
      {
        fileMaxError,
        filesToUpload,
        filesUploaded
      },
      setPageFetchingStatus
    );
  }

  // eslint-disable-next-line class-methods-use-this
  xhrDeleteFile(file) {
    const xhr = new XMLHttpRequest();
    xhr.open('delete', file.deleteUrl);
    xhr.send();
  }

  handleClearFileError(file) {
    const filesUploaded = [...this.state.filesUploaded];

    const indexOfFileToDelete = filesUploaded.findIndex((f) => f.order === file.order);
    if (indexOfFileToDelete === -1) return;

    let deleteFile = { ...filesUploaded[indexOfFileToDelete] };
    filesUploaded.splice(indexOfFileToDelete, 1);

    if (deleteFile.needsDeleteInfo && !deleteFile.deletedOn) {
      deleteFile = clearFileError(file);
      deleteFile.deletedOn = new Date().toISOString();
    } else {
      deleteFile = resetFileToNew(file);
    }

    const filesToUpload = [...this.state.filesToUpload, deleteFile].sort(byAscFileOrder);

    this.setStateForFiles({ filesUploaded, filesToUpload });
  }

  handleDeleteFile(file) {
    const { xhrRequest } = file;
    if (xhrRequest) xhrRequest.abort();

    const filesUploaded = [...this.state.filesUploaded];

    const indexOfFileToDelete = filesUploaded.findIndex((f) => f.order === file.order);
    if (indexOfFileToDelete === -1) return;

    let deleteFile = { ...filesUploaded[indexOfFileToDelete] };
    filesUploaded.splice(indexOfFileToDelete, 1);

    this.xhrDeleteFile(file);

    if (deleteFile.needsDeleteInfo) {
      deleteFile.deletedOn = new Date().toISOString();
    } else {
      deleteFile = resetFileToNew(deleteFile);
    }

    const filesToUpload = [...this.state.filesToUpload, deleteFile].sort(byAscFileOrder);

    this.setStateForFiles({ filesUploaded, filesToUpload });
  }

  handleClearMaxError() {
    this.setState({ fileMaxError: null });
  }

  render() {
    const {
      isMobile,
      questionId,
      requiredField,
      validationEmpty,
      validationFailed,
      validationMessages
    } = this.props;
    const { fileMaxError, filesToUpload, filesUploaded } = this.state;

    this.setMobileLabels();

    const reachedMaxUpload = filesToUpload.length === 0;
    const showUploadIcon = filesToUpload.length > 0 && filesUploaded.length === 0 && !fileMaxError;
    const files = [...filesToUpload, ...filesUploaded].sort(byAscFileOrder);

    return (
      <FileUpload
        acceptMultipleFiles={this.acceptMultipleFiles}
        fields={this.fieldNameToFieldKey}
        fileMaxError={fileMaxError}
        files={files}
        isMobile={isMobile}
        onClearMaxError={this.handleClearMaxError}
        onFileDelete={this.handleDeleteFile}
        onFileError={this.handleClearFileError}
        onFileUpload={this.handleFilesUpload}
        onUploadButtonClick={this.handleUploadButtonClick}
        questionId={questionId}
        reachedMaxUpload={reachedMaxUpload}
        requiredField={requiredField}
        showUploadIcon={showUploadIcon}
        validationEmpty={validationEmpty}
        validationFailed={validationFailed}
        validationMessages={validationMessages}
        actionButtonLabel={this.actionButtonLabel}
        consentLabel={this.consentLabel}
        fileFormatLabel={this.fileFormatLabel}
        inputAcceptExtensionsLabel={this.inputAcceptExtensionsLabel}
        maxFileSizeLabel={this.maxFileSizeLabel}
        maxFilesLabel={this.maxFilesLabel}
        prefixActionButtonLabel={this.prefixActionButtonLabel}
        questionLabel={this.questionLabel}
      />
    );
  }
}

const { any, arrayOf, bool, func, number, shape, string } = PropTypes;

FileUploadContainer.propTypes = {
  additionalText: string,
  allowedFileFormats: arrayOf(string),
  caption: string,
  fields: shape({
    [string]: shape({
      key: string,
      values: arrayOf(any)
    })
  }),
  isMobile: bool,
  maxFileSizeMb: number,
  maxNumFiles: number,
  questionId: string.isRequired,
  requiredField: string,
  setPageFetchingStatus: func.isRequired,
  setPageReadyStatus: func.isRequired,
  upload: arrayOf(
    shape({
      PUT: string,
      DELETE: string
    })
  ),
  validationEmpty: string,
  validationFailed: string,
  validationMessages: arrayOf(string)
};

FileUploadContainer.defaultProps = {
  additionalText: '',
  allowedFileFormats: [],
  caption: '',
  fields: {},
  isMobile: false,
  maxFileSizeMb: 50,
  maxNumFiles: 1,
  requiredField: '',
  upload: [],
  validationEmpty: '',
  validationFailed: '',
  validationMessages: []
};
