import debounce from 'lodash/debounce';

export const MEDIA_QUERY_ACTION_TYPE = 'MEDIA_QUERY_CHANGE';
export const DESKTOP_MEDIA_CONTENT = 'media-desktop';
export const MOBILE_MEDIA_CONTENT = 'media-mobile';

const emptyStore = {
  getState: () => {}
};

const MediaQueryWatcher = {
  getContentOfBodyPsuedoAfterElement() {
    if (!window.getComputedStyle) return '';
    const bodyPsuedoAfter = window.getComputedStyle(document.body, ':after');
    if (!bodyPsuedoAfter) return '';
    return bodyPsuedoAfter.getPropertyValue('content') || '';
  },

  isDesktopMedia() {
    return this.getContentOfBodyPsuedoAfterElement().includes(DESKTOP_MEDIA_CONTENT);
  },

  isMobileMedia() {
    return this.getContentOfBodyPsuedoAfterElement().includes(MOBILE_MEDIA_CONTENT);
  },

  monitorMediaQuery(store = emptyStore) {
    const currentState = store.getState() || {};
    const currentMediaState = currentState.mediaQuery || {};

    const isDesktopMedia = this.isDesktopMedia();
    if (currentMediaState.isDesktopMedia === isDesktopMedia) return;
    const newMediaState = { isDesktopMedia, isMobileMedia: !isDesktopMedia };
    store.dispatch({
      type: MEDIA_QUERY_ACTION_TYPE,
      payload: newMediaState
    });
  },

  watch(store = {}) {
    this.monitorMediaQuery = this.monitorMediaQuery.bind(this, store);
    this.monitorMediaQuery = debounce(this.monitorMediaQuery, 250);
    window.addEventListener('load', this.monitorMediaQuery);
    window.addEventListener('resize', this.monitorMediaQuery);
    window.addEventListener('orientationchange', this.monitorMediaQuery);
  },

  reset() {
    window.removeEventListener('load', this.monitorMediaQuery);
    window.removeEventListener('resize', this.monitorMediaQuery);
    window.removeEventListener('orientationchange', this.monitorMediaQuery);
  }
};

export default MediaQueryWatcher;
