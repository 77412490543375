import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { toggleBanner } from '@redux/actions/cookieBanner';
import { clearFocus } from '@redux/actions/setPageStatus';

import { withData } from '@services/surveyDataProvider';

import CookieBannerOpener from './CookieBannerOpener';
import { COOKIE_BANNER_OPENER } from './constants';

const mapSurveyDataToProps = (surveyData) => ({
  enabled: Object.keys(surveyData.cookieBanner).length > 0,
  label: get(surveyData, 'cookieBanner.openButtonText', '')
});

const mapStateToProps = (state) => ({
  focused: state.surveyStatus.focusedElement === COOKIE_BANNER_OPENER
});

export const mapDispatchToProps = (dispatch) => ({
  onClick: () => dispatch(toggleBanner(COOKIE_BANNER_OPENER)),
  clearFocus: () => dispatch(clearFocus())
});

const composedConnect = compose(
  withData(mapSurveyDataToProps),
  connect(mapStateToProps, mapDispatchToProps)
);

export default composedConnect(CookieBannerOpener);
