import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Longform from '@components/Longform';

import { testName } from '@utils/testUtils';

import NestedDropdown from './NestedDropdown';
import styles from './nestedDropdownWrapper.scss';

const { arrayOf, bool, number, string, shape } = PropTypes;

export default class NestedDropdownWrapper extends Component {
  static propTypes = {
    isMobile: bool,
    id: number.isRequired,
    nddId: string,
    validationEmpty: string,
    validationFailed: string,
    requiredField: string,
    caption: string,
    showFullNDD: bool,
    progressiveDisclosure: bool,
    filteredUnitList: shape({
      closestUnits: arrayOf(
        shape({
          id: string,
          alt: shape({
            id: string,
            key: string,
            name: string,
            numValue: string,
            inactive: bool
          }),
          distance: string,
          selected: bool
        })
      ),
      name: string
    }),
    dropdown: arrayOf(
      shape({
        hideAlways: bool,
        caption: string,
        level: number,
        alt: arrayOf(
          shape({
            id: string,
            active: bool,
            text: string
          })
        ),
        id: string
      })
    ),
    dropdowns: arrayOf(string)
  };

  static contextTypes = {
    translations: PropTypes.object
  };

  static defaultProps = {
    isMobile: false,
    filteredUnitList: {},
    nddId: '',
    validationEmpty: '',
    validationFailed: '',
    requiredField: '',
    caption: '',
    showFullNDD: true,
    progressiveDisclosure: false,
    dropdown: [],
    dropdowns: []
  };

  constructor(props) {
    super(props);

    this.state = {
      showNestedDropdown: props.showFullNDD,
      hasBoxShadow: false
    };

    this.handleShowBoxShadow = this.handleShowBoxShadow.bind(this);
    this.handleHideBoxShadow = this.handleHideBoxShadow.bind(this);
    this.handleShowNddClickHandler = this.handleShowNddClickHandler.bind(this);
  }

  handleShowNddClickHandler() {
    this.setState({ showNestedDropdown: true });
  }

  handleShowBoxShadow() {
    if (this.state.hasBoxShadow) return;
    this.setState({ hasBoxShadow: true });
  }

  handleHideBoxShadow() {
    if (!this.state.hasBoxShadow) return;
    this.setState({ hasBoxShadow: false });
  }

  render() {
    const { id } = this.props;

    const findOtherLocationStyles = classNames(styles.findOtherLocation, {
      [styles.findOtherLocation_hasBoxShadow]: this.state.hasBoxShadow
    });

    return (
      <div>
        {this.state.showNestedDropdown ? (
          <NestedDropdown {...this.props} />
        ) : (
          <div {...testName('nearest-locations')}>
            <Longform id={id} key={id} />
            <div
              className={findOtherLocationStyles}
              onMouseOver={this.handleShowBoxShadow}
              onFocus={this.handleShowBoxShadow}
              onMouseOut={this.handleHideBoxShadow}
              onBlur={this.handleHideBoxShadow}>
              <a
                tabIndex="0"
                className={styles.findOtherLocationInner}
                onClick={this.handleShowNddClickHandler}>
                {this.context.translations.FIND_OTHER_LOCATIONS}
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }
}
