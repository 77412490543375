export const NESTED_DROPDOWN_BLOCKTYPE = 'nestedDropdowns';
export const TEXTAREA_BLOCKTYPE = 'textarea';
export const MATRIX_BLOCKTYPE = 'matrix';

export const QUESTION_BLOCK_TYPES = [
  NESTED_DROPDOWN_BLOCKTYPE,
  TEXTAREA_BLOCKTYPE,
  'calendar',
  'choose',
  'dropdown',
  'grid',
  'longform',
  'mediaStreamerFileUploader',
  'questionText',
  'rawhtml',
  'text',
  'textfield',
  'timeout-page',
  'tooltip',
  'tripAdvisorWidgetPage',
  'validationText',
  'livinglens',
  'ranking',
  'matrix'
];

export const MAPPING_OF_WRAPPER_TO_CHILDREN = {
  group: 'parts',
  page: 'parts',
  pagerBody: 'body',
  pageTemplate: 'body',
  bazaarVoicePage: 'parts'
};

export const WRAPPING_BLOCK_TYPES = Object.keys(MAPPING_OF_WRAPPER_TO_CHILDREN);
