import { combineReducers } from 'redux';

import components from './components';
import cookieBanner from './cookieBanner';
import htmlInputs from './htmlInputs';
import mediaQuery from './mediaQueryWatcher';
import options from './options';
import pageSettings from './pageSettings';
import questions from './questions';
import surveyStatus from './surveyStatus';

const reducers = {
  pageSettings,
  htmlInputs,
  options,
  questions,
  mediaQuery,
  surveyStatus,
  components,
  cookieBanner,
  navigation: (state = {}) => state,
  conditions: (state = {}) => state,
  fields: (state = {}) => state,
  fieldControlledBy: (state = {}) => state
};

const rootReducer = combineReducers(reducers);
export default rootReducer;

export type RootState = InferRootState<typeof reducers>;

//  ----------------------------------------------------------------------
//  UTILS
//  ----------------------------------------------------------------------

type BaseReducerMap<S> = {
  [K in keyof S]: (state: S[K], action: any) => S;
};

type InferRootState<ReducerMap extends BaseReducerMap<S>, S = any> = {
  [K in keyof ReducerMap]: ReturnType<ReducerMap[K]>;
};
