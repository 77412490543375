/* global $ window */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Question from '@commons/Question';
import Tooltip from '@commons/Tooltip';

import layoutStyles from '@css/layout.scss';

import { getTranslation } from '@services/translations';

import styles from './tripAdvisor.scss';

const { bool, string, oneOfType } = PropTypes;

const parseJSONwithoutErrors = (str) => {
  try {
    return JSON.parse(str);
  } catch (err) {
    return null; // silently ignore JSON parsing errors
  }
};

export default class TripAdvisor extends Component {
  static propTypes = {
    requestUrl: oneOfType([bool, string]),
    validationEmpty: PropTypes.string,
    validationFailed: PropTypes.string,
    requiredField: PropTypes.string
  };

  static defaultProps = {
    requestUrl: false,
    validationEmpty: '',
    validationFailed: '',
    requiredField: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      tripAdvisorWidgetPostedValue: 0,
      postToTripAdvisorValue: 0,
      tripAdvisorFormValues: {}
    };

    this.reviewComplete = false;
    this.reviewId = null;
    this.submitAfterUpdate = false;

    this.onMessage = this.onMessage.bind(this);
    this.onComplete = this.onComplete.bind(this);
    this.submitForm = this.submitForm.bind(this);

    this.translations = {
      questionCaption: getTranslation('TA_HEADER'),
      description: getTranslation('TA_DESCRIPTION'),
      tooltip: getTranslation('TA_WHATS_THIS_DETAIL')
    };
  }

  componentDidMount() {
    window.addEventListener('message', this.onMessage);
  }

  submitForm(secondsTimeout) {
    setTimeout(() => {
      document.querySelector('form').submit();
    }, 1000 * secondsTimeout);
  }

  componentDidUpdate() {
    if (this.submitAfterUpdate) {
      this.submitForm(2);
    }
  }

  /**
   * Checks if a given string represents a TripAdvisor URL.
   * The only assumption made by this function is that the string "tripadvisor" is present
   * as a domain somewhere in the hostname.
   * This function is intentionally lax in that it allows "tripadvisor" to appear anywhere
   * in the hostname (even in the top level domain).
   * There's no practical advantage to a more strict check, which could in fact end up filtering
   * valid URLs if TripAdvisor adds a new exotic subdomain or top level domain.
   * This function will return true for known TripAdvisor URLs like
   * 'www.tripadvisor.com', 'www.tripadvisor.co.uk',  'fr.tripadvisor.ca'
   *
   * @param str
   * @returns {boolean} true only if we consider this a TripAdvisor URL
   */
  isTripAdvisorUrl(str) {
    try {
      const url = new URL(str);
      const domains = url.hostname.split('.');
      return domains.some((domain) => domain === 'tripadvisor');
    } catch (e) {
      // new URL() throws if the string is not a valid url
      return false;
    }
  }

  /**
   * Documentation from TA on message contract is unavailable. The messaging protocol is kept in this function
   * to keep the rest of the code free from TA widget assumptions
   *
   * @param {String} message sent from the widget, accepted in the following formats (anything else will trigger
   * the error handler)
   *
   * "review_complete"                    the TA review was successfully submitted
   * "ReviewID:"                          the TA sends ReviewID separated by space before review_complete
   * "review_complete {"extra": "data"}"  as above, this message can also contain data about the submission
   *                                      in JSON format, separated from message name by a space
   * "review_submission_failed"           the user submitted an invalid form within the widget
   */
  onMessage(message) {
    if (!this.isTripAdvisorUrl(message.origin)) {
      return;
    }
    let data = message.data;
    if (typeof data === 'string') {
      if (data.indexOf('review_complete') === 0) {
        // TA seem to have a bug where this message is sent more than once
        if (this.reviewComplete) {
          return;
        }
        this.reviewComplete = true;
        // process the JSON data passed in the message
        data = data.substring(data.indexOf(' ') + 1);
        data = parseJSONwithoutErrors(data) || {};
        data.reviewId = this.reviewId;
        this.submitAfterUpdate = true;
        this.onComplete(data);
      } else if (data.indexOf('ReviewID:') === 0) {
        this.reviewId = data.substring(data.indexOf(' ') + 1);
      } else if (data === 'review_submission_failed') {
        // TA display any submission errors at the top of the widget. Here we scroll the user to the top of the survey
        // page to ensure they are seeing the top of the iframe element
        window.scrollTo(0, 0);
      } else if (data.indexOf('TA_widgetError') < 0) {
        // Allow enough time for the user to view any generic widget errors before submitting the page and moving them
        // to the next, effectively skipping TA review submission.
        this.submitForm(5);
      }
    }
  }

  /**
   * Takes the data provided by the widget and modifies form data before submitting the Medallia survey form
   */
  onComplete(data) {
    const fields = {
      q_ta_overall_score: data.overallRating,
      q_ta_review_title: data.reviewTitle,
      q_ta_review: data.reviewText,
      q_ta_review_id: data.reviewId
    };

    if (typeof data.subRating === 'object') {
      Object.keys(data.subRating).forEach((name) => {
        const value = data.subRating[name];
        name = name.replace(/Rating$/, '');
        name = name.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
        name = 'q_ta_' + name;
        fields[name] = value;
      });
    }

    const tripAdvisorFormValues = Object.keys(fields).reduce((carry, key) => {
      const fieldValue = fields[key];
      if (!fieldValue) {
        return carry;
      }
      return {
        ...carry,
        [key]: fieldValue
      };
    }, {});

    this.setState({
      tripAdvisorWidgetPostedValue: 1,
      postToTripAdvisorValue: 1,
      tripAdvisorFormValues
    });
  }

  getElementsWithTripAdvisorValues() {
    const { tripAdvisorFormValues } = this.state;
    return Object.keys(tripAdvisorFormValues).map((key) => (
      <input type="hidden" name={key} key={key} value={tripAdvisorFormValues[key]} />
    ));
  }

  render() {
    const { questionCaption, tooltip, description } = this.translations;
    /**
     * Validations remain only in this component for surveys created without
     * survey builder, taking in account in doesnt have validations available
     **/

    const { validationEmpty, validationFailed, requiredField, requestUrl } = this.props;
    const { tripAdvisorWidgetPostedValue, postToTripAdvisorValue } = this.state;
    const iframeClass = `${styles.taWidget} ${layoutStyles.answer}`;
    return (
      <div className="questionBlock tripAdvisorQuestion">
        <fieldset>
          <Question
            caption={questionCaption}
            validationEmpty={validationEmpty}
            validationFailed={validationFailed}
            requiredField={requiredField}>
            <Tooltip isTabbable caption={tooltip} />
            <div className={styles.taWidgetIntro}>{description}</div>
          </Question>
          <div className={layoutStyles.answers}>
            {this.getElementsWithTripAdvisorValues()}
            <input
              id="tripAdvisorWidgetPosted"
              type="hidden"
              name="q_ta_posted"
              value={tripAdvisorWidgetPostedValue}
            />
            <input
              id="postToTripAdvisor"
              type="hidden"
              name="postToTripAdvisor"
              value={postToTripAdvisorValue}
            />
            <iframe
              title="Trip Advisor"
              id="tripAdvisorWidget"
              className={iframeClass}
              src={requestUrl}
              allowTransparency="true"
              scrolling="yes"
            />
          </div>
        </fieldset>
      </div>
    );
  }
}
