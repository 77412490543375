import { QUESTION_TYPES } from '@models/question';
import ResourcesRelationshipManager from '@models/resourcesRelationshipManager';

export const MATCH_VALIDATION_TEXT = 'validationText';

export default class ValidationTextLogic {
  // eslint-disable-next-line class-methods-use-this
  shouldNormalize({ questionData }) {
    return questionData.blockType === MATCH_VALIDATION_TEXT;
  }

  // eslint-disable-next-line class-methods-use-this
  normalize({ id, questionData }) {
    if (this.shouldNormalize({ questionData })) {
      const { caption } = questionData;
      const resourcesManager = new ResourcesRelationshipManager();

      resourcesManager.createOrUpdateQuestion({
        id,
        caption,
        type: QUESTION_TYPES.VALIDATION_TEXT,
        isRequired: false
      });

      return resourcesManager.resources();
    }
    return false;
  }
}
