import { QUESTION_TYPES } from '@models/question';

import { hideDOMNode, showDOMNode } from '@utils/domNodeHelpers';

import { getAnswerLabels, getAnswerValue, getQuestion } from './storeSelectors';

let store;
export const setStore = (assignedStore) => {
  store = assignedStore;
};

export const findComponent = (componentId) =>
  document.getElementsByClassName(`container_${componentId}`)[0];

export const showComponent = (componentId) => {
  showDOMNode(findComponent(componentId));
};

export const hideComponent = (componentId) => {
  hideDOMNode(findComponent(componentId));
};

export const getAnswer = (componentId) => {
  const state = store.getState();
  const answer = getAnswerValue(state, componentId);

  // answer may be undefined, let's ensure null
  if (answer == null) return null;

  // otherwise, let's ensure string
  return String(answer);
};

export const getAnswerLabel = (componentId) => {
  const state = store.getState();

  let labels = getAnswerLabels(state, componentId);
  const question = getQuestion(state, componentId);

  // labels may be undefined, let's ensure null
  if (labels == null) return null;

  // per type transformations
  // we should define strategies per type when this grows in complexity
  if (question.isSingleSelect || question.type === QUESTION_TYPES.RATING_GRID) {
    if (labels.length > 0 && labels[0] != null) {
      [labels] = labels;
    } else {
      labels = null;
    }
  }

  return labels;
};

export default {
  showComponent,
  hideComponent,
  getAnswer,
  getAnswerLabel
};
