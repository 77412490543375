import get from 'lodash/get';

import { enableSurveyAPI } from '@services/api';

const featureFlagsList = [
  'add-selected-option-class',
  'advanced-accessibility',
  'enable-survey-api',
  'remove-textfield-fieldset',
  'wcag-21-enabled',
  'enable-textarea-placeholder',
  'enable-each-option-with-label-class',
  'use-geolocation-timeout',
  'use-geolocation-fetching-spinner',
  'use-geolocation-fetching-spinner-fixed',
  '20cr3_MPC1670_enable_WCAG21_part_2',
  '21cr4_MPC3935_enable_WCAG21_part_3',
  '20cr3_MPC2756_enable_ios_dropdown_with_keyboard_open_fix',
  '20cr3.1_MPC3125_enable_dropdown_accessibility',
  '20cr3.1_MPC3179_enable_not_jquery_v7',
  '20cr4_MPC3127_enable_ios_dropdown_scroll_to_top_fix',
  '22cr1_MPC4261_enable_WCAG21_part_4',
  '22cr4_MPC5297_enable_auto_save_api',
  '23cr2_MPC5193_enable_living_lens_upload_validation',
  '23cr4_MPC7425_enable_searchable_dropdown',
  '23cr4_MPC7316_a11y_wfc_enhancement',
  '23cr4_OA26580_a11y_enable_row_option_error_state',
  '24cr2_OA27535_a11y_enable_less_verbose_grid_row_captions',
  '24cr3_OA26533_enable_grid_row_cell_alignment',
  '24cr3_OA27908_enable_scroll_fix_rtl_matrix',
  '24cr4_OA28280_a11y_enable_grid_fieldset_wrapper'
];

const featureFlagsManifest = {};

export const clearFeatureFlags = () =>
  featureFlagsList.forEach((featureFlag) => {
    featureFlagsManifest[featureFlag] = false;
  });

clearFeatureFlags();

export const featureFlagGetter = (ff) => () => featureFlagsManifest[ff];

export const isWCAG21_4_Enabled_FF = featureFlagGetter('22cr1_MPC4261_enable_WCAG21_part_4'); // eslint-disable-line camelcase
const advancedAccessibilityGetter = featureFlagGetter('advanced-accessibility');
const removeTextfieldGetter = featureFlagGetter('remove-textfield-fieldset');
// we agreed offline that mixing snake case was better than strict camel case here below
const isGeolocationFetchingSpinner_FF_Enabled = featureFlagGetter(
  'use-geolocation-fetching-spinner'
); // eslint-disable-line camelcase

export const isWCAG21_FF_Enabled = featureFlagGetter('wcag-21-enabled'); // eslint-disable-line camelcase
export const isWCAG21_2_Enabled_FF = featureFlagGetter('20cr3_MPC1670_enable_WCAG21_part_2'); // eslint-disable-line camelcase
export const isWCAG21_3_Enabled_FF = featureFlagGetter('21cr4_MPC3935_enable_WCAG21_part_3'); // eslint-disable-line camelcase

export const isWCAG21_2_Enabled = () =>
  // eslint-disable-line camelcase
  isWCAG21_3_Enabled_FF() || isWCAG21_2_Enabled_FF();
export const isWCAG21Enabled = () =>
  isWCAG21_4_Enabled_FF() ||
  isWCAG21_3_Enabled_FF() ||
  isWCAG21_2_Enabled_FF() ||
  isWCAG21_FF_Enabled(); // eslint-disable-line max-len

export const isSurveyAPIEnabled = featureFlagGetter('enable-survey-api');
export const isAddingSelectedClassEnabled = featureFlagGetter('add-selected-option-class');
export const isTextfieldWithoutFieldsetEnabled = () => isWCAG21Enabled() || removeTextfieldGetter();
export const isAdvancedAccessibilityEnabled = () =>
  isWCAG21Enabled() || advancedAccessibilityGetter();

export const isTextareaPlaceholderEnabled = featureFlagGetter('enable-textarea-placeholder');
export const isLabelForEachOptionClassEnabled = featureFlagGetter(
  'enable-each-option-with-label-class'
);
export const isGeolocationTimeoutEnabled = featureFlagGetter('use-geolocation-timeout');

// eslint-disable-next-line camelcase
export const isGeolocationFetchingSpinnerFixed_FF_Enabled = featureFlagGetter(
  'use-geolocation-fetching-spinner-fixed'
);
export const isGeolocationFetchingSpinnerEnabled = () =>
  isGeolocationFetchingSpinner_FF_Enabled() || isGeolocationFetchingSpinnerFixed_FF_Enabled();
export const isIosDropdownOpeningWhenKeyboardShownFixEnabled = featureFlagGetter(
  '20cr3_MPC2756_enable_ios_dropdown_with_keyboard_open_fix'
);
export const isDropdownAccessibilityEnabled = featureFlagGetter(
  '20cr3.1_MPC3125_enable_dropdown_accessibility'
);
// Deprecated/Legacy.
export const isjQueryDisabled = featureFlagGetter('20cr3.1_MPC3179_enable_not_jquery_v7');
export const isIosDropdownScrollToTopFixEnabled = featureFlagGetter(
  '20cr4_MPC3127_enable_ios_dropdown_scroll_to_top_fix'
);
export const isLivingLensUploadValidationEnabled = featureFlagGetter(
  '23cr2_MPC5193_enable_living_lens_upload_validation'
);
export const isSearchableDropdownEnabled = featureFlagGetter(
  '23cr4_MPC7425_enable_searchable_dropdown'
);

// Cookie Banner V3 has no specific FF, it's enabled via this configuration param.
// Refer to: https://jira.medallia.com/browse/MPC-6358
export const isCookieBannerV3Enabled = () =>
  get(window, ['surveyData', 'cookieNotice', 'cookieVersion'], '') === 'V3';

export const isRowOptionErrorStateEnabled = featureFlagGetter(
  '23cr4_OA26580_a11y_enable_row_option_error_state'
);

export const isA11yWfcEnhancementEnabled = featureFlagGetter('23cr4_MPC7316_a11y_wfc_enhancement');

export const isLessVerboseGridRowCaptionsEnabled = featureFlagGetter(
  '24cr2_OA27535_a11y_enable_less_verbose_grid_row_captions'
);

export const isGridRowCellAlignmentEnabled = featureFlagGetter(
  '24cr3_OA26533_enable_grid_row_cell_alignment'
);

export const isScrollFixForRTLMatrixEnabled = featureFlagGetter(
  '24cr3_OA27908_enable_scroll_fix_rtl_matrix'
);

export const isGridFieldsetWrapperEnabled = featureFlagGetter(
  '24cr4_OA28280_a11y_enable_grid_fieldset_wrapper'
);

export const setFeatureFlags = (surveyData) => {
  // latest SE sends FF grouped
  if (surveyData.featureFlags) {
    Object.assign(featureFlagsManifest, surveyData.featureFlags);
  } else {
    // 'advanced-accessibility' is a particular case because it was added before having FF object
    featureFlagsManifest['advanced-accessibility'] =
      !!window.surveyData.isAdvancedAccessibilityEnabled;
  }

  if (isSurveyAPIEnabled()) {
    enableSurveyAPI();
  }
};
