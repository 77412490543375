export const GEOLOCATION_TIMEOUT_KEY = 'geolocationTimeout';

export const defaultConfigs = {
  [GEOLOCATION_TIMEOUT_KEY]: 10000
};

export const getIntConfig = (surveyData, key) => {
  const surveyConfiguration = surveyData.configs || {};
  const targetedConfiguration = surveyConfiguration[key];
  const parsedConfig = parseInt(targetedConfiguration, 10);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(parsedConfig)) {
    return defaultConfigs[key];
  }
  return parsedConfig;
};

export default getIntConfig;
