import HtmlInput from '@models/htmlInput';

export default function checkbox(state, action, htmlInput) {
  let { value } = action.payload;
  const { question } = action.payload;

  value = htmlInput.value === value ? undefined : value;
  const newHtmlInput = new HtmlInput({ ...htmlInput, value });

  // flip all other htmlInputs off because noneOfTheAbove is selected
  if (newHtmlInput.isNoneOfTheAbove && newHtmlInput.value) {
    const htmlInputsWithAnswer = {};
    question.htmlInputsWithAnswer.forEach((id) => {
      const oldHtmlInput = state[id];
      if (newHtmlInput.isNotSameType(oldHtmlInput)) return;
      if (!newHtmlInput.hasSameId(oldHtmlInput) && oldHtmlInput.value) {
        const updatedHtmlInput = new HtmlInput({
          ...oldHtmlInput,
          value: undefined
        });
        htmlInputsWithAnswer[updatedHtmlInput.id] = updatedHtmlInput;
      }
    });

    return {
      ...state,
      ...htmlInputsWithAnswer,
      [newHtmlInput.id]: newHtmlInput
    };
  }

  // flip the noneOfTheAbove htmlInput to false because an answer was selected
  if (question.hasNoneOfTheAboveOption) {
    const oldNoneOfTheAboveHtmlInput = state[question.noneOfTheAboveHtmlInput];
    const newNoneOfTheAboveHtmlInput = new HtmlInput({
      ...oldNoneOfTheAboveHtmlInput,
      value: undefined
    });

    return {
      ...state,
      [newHtmlInput.id]: newHtmlInput,
      [newNoneOfTheAboveHtmlInput.id]: newNoneOfTheAboveHtmlInput
    };
  }

  return {
    ...state,
    [newHtmlInput.id]: newHtmlInput
  };
}
