/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React from 'react';

import ConsentDetails from '@components/CookieBanner/shared/ConsentDetails';
import CookieBannerPanel from '@components/CookieBanner/shared/CookieBannerPanel';

const buildDetailList = (
  descriptionKey,
  description,
  consentIdKey,
  consentId,
  consentDateKey,
  consentDate
) => [
  { title: descriptionKey, value: description },
  { title: consentIdKey, value: consentId },
  { title: consentDateKey, value: consentDate }
];

const PanelCookieBanner = ({
  title: cookieConsentTitle,
  descriptionLabel,
  description,
  consentIdLabel,
  consentId,
  consentDateLabel,
  consentDate
}) => {
  const details = buildDetailList(
    descriptionLabel,
    description,
    consentIdLabel,
    consentId,
    consentDateLabel,
    consentDate
  );

  return (
    <CookieBannerPanel.Root>
      <CookieBannerPanel.Title>{cookieConsentTitle}</CookieBannerPanel.Title>
      <CookieBannerPanel.Description>
        {details.map(({ title, value }) => (
          <ConsentDetails key={title} title={title} value={value} />
        ))}
      </CookieBannerPanel.Description>
    </CookieBannerPanel.Root>
  );
};

PanelCookieBanner.propTypes = {
  title: PropTypes.string.isRequired,
  descriptionLabel: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  consentIdLabel: PropTypes.string.isRequired,
  consentId: PropTypes.string.isRequired,
  consentDateLabel: PropTypes.string.isRequired,
  consentDate: PropTypes.string
};

PanelCookieBanner.defaultProps = {
  consentDate: new Date().toISOString()
};

export default PanelCookieBanner;
