import { COOKIE_BANNER_SESSION_STORAGE_ID } from './constants';

const getSessionData = () => {
  if (!sessionStorage) {
    return {};
  }
  const sessionData = sessionStorage.getItem(COOKIE_BANNER_SESSION_STORAGE_ID);
  if (!sessionData) {
    return {};
  }
  return JSON.parse(sessionData) || {};
};

const saveSessionData = (sessionData) => {
  if (!sessionStorage) {
    return;
  }
  sessionStorage.setItem(COOKIE_BANNER_SESSION_STORAGE_ID, JSON.stringify(sessionData));
};

export const getCookieBannerSessionStatus = (cookieConsentId) => {
  const sessionData = getSessionData();
  return sessionData[cookieConsentId] || {};
};

const saveCookieBannerSessionStatus = (cookieConsentId, status) => {
  if (!sessionStorage) {
    return;
  }
  const sessionData = getSessionData();
  saveSessionData({
    ...sessionData,
    [cookieConsentId]: status
  });
};

export const saveSessionConsent = (cookieConsentId, consentDate) => {
  const status = getCookieBannerSessionStatus(cookieConsentId);
  const newStatus = {
    ...status,
    consentDate
  };
  saveCookieBannerSessionStatus(cookieConsentId, newStatus);
};

export const saveSessionOpenStatus = (cookieConsentId, isOpen) => {
  const status = getCookieBannerSessionStatus(cookieConsentId);
  const newStatus = {
    ...status,
    isOpen
  };
  saveCookieBannerSessionStatus(cookieConsentId, newStatus);
};
