const NESTED_DROPDOWN_BLOCKTYPE = 'nestedDropdowns';

export default function convertNddToLongform(longformData) {
  const data = longformData;
  if (
    data.blockType !== NESTED_DROPDOWN_BLOCKTYPE ||
    !(data.filteredUnitList && data.filteredUnitList.name && data.filteredUnitList.closestUnits)
  ) {
    return data;
  }

  const { closestUnits } = data.filteredUnitList;

  const parts = [];
  for (let i = 0; i < closestUnits.length; i++) {
    const { alt } = closestUnits[i];
    const part = {
      blockType: 'radioRow',
      formValue: alt.key,
      formKey: data.filteredUnitList.name,
      caption: alt.name,
      hasContent: false,
      content: null,
      checked: closestUnits[i].selected || false
    };
    parts.push(part);
  }

  data.parts = parts;

  return data;
}
