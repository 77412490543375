import HtmlInput from '@models/htmlInput';

export default function text(state, action, htmlInput) {
  const { value = '' } = action.payload;
  const newHtmlInput = new HtmlInput({ ...htmlInput, value });
  return {
    ...state,
    [newHtmlInput.id]: newHtmlInput
  };
}
