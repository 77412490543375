import debounce from 'lodash/debounce';
import merge from 'lodash/merge';

const PAGE_VIEW = 'PAGE_VIEW';
const PAGE_ERROR = 'PAGE_ERROR';

const getPerformanceAPI = () =>
  window &&
  (window.performance || window.mozPerformance || window.msPerformance || window.webkitPerformance);

export const isAppDynamicsEnabled = () => window.ADRUM && window.APP_DYNAMICS;
export const packageName = process.env.PACKAGE_NAME || process.env.npm_package_name;
export const isLegacy = packageName === '@m/react-surveys';

export const loadingStartTime = window.loadingStartTime || Date.now();

// throttle ADRUM.report calls otherwise all but last one get dropped
const queue = [];

// ATTRIBUTES is used to maintain flat structure of set attributes while
// APP_DYNAMICS is AD view of the world

export const getInitAttributes = (attributes = window.APP_DYNAMICS) => {
  const baseAttributes = {
    Version: 'V7',
    V7Version: isLegacy
      ? process.env.PACKAGE_VERSION || null
      : process.env.npm_package_version || null,
    V7Milestone: isLegacy ? 'legacy' : 'next'
  };

  if (!attributes) {
    return baseAttributes;
  }
  return Object.keys(attributes).reduce((carry, key) => {
    const updatedCarry = carry;
    Object.keys(attributes[key]).forEach((attribute) => {
      updatedCarry[attribute] = attributes[key][attribute];
    });
    return updatedCarry;
  }, baseAttributes);
};

let ATTRIBUTES = getInitAttributes();

function toAppDynamicsKey(key, value) {
  switch (typeof value) {
    case 'string':
      return 'userData';
    case 'number':
      return Number.isInteger(value) ? 'userDataLong' : 'userDataDouble';
    case 'boolean':
      return 'userDataBoolean';
    case 'object':
      return value instanceof Date && 'userDataDate';
    default:
      // eslint-disable-next-line no-console
      console.warn(
        key,
        'value of type',
        typeof value,
        'cannot be converted to known app dynamics key'
      );
  }
  return 'notTypeDetected';
}

const toAppDynamics = (value) => {
  const adValue = {};
  Object.keys(value).forEach((key) => {
    const adKey = toAppDynamicsKey(key, value[key]);
    if (adKey) {
      adValue[adKey] = adValue[adKey] || {};
      adValue[adKey][key] = value[key];
    }
  });

  return adValue;
};

export const setAttribute = (name, value) => {
  const adKey = toAppDynamicsKey(name, value);
  if (adKey) {
    ATTRIBUTES[name] = value;
  }
};

export function clearAttributes() {
  ATTRIBUTES = {};
}

export const getAttributes = () => ATTRIBUTES;

const generateEvent = (eventType, eventAttributes) => {
  if (eventType === PAGE_ERROR) {
    return new window.ADRUM.events.Error(eventAttributes);
  }
  if (eventType === PAGE_VIEW) {
    const vPageView = new window.ADRUM.events.VPageView({
      url: eventAttributes.name
    });
    vPageView.startCorrelatingXhrs();
    vPageView.markVirtualPageStart(eventAttributes.timestamp);
    vPageView.stopCorrelatingXhrs();
    vPageView.markVirtualPageEnd(eventAttributes.timestamp);
    return vPageView;
  }
  return undefined;
};

const report = (item = { attributes: {} }) => {
  const { attributes, eventType, eventAttributes } = item;
  const event = generateEvent(eventType, eventAttributes);
  window.APP_DYNAMICS = toAppDynamics(merge({}, ATTRIBUTES, attributes));
  return window.ADRUM.report(event);
};

let debouncedNext;

const next = () => {
  if (!isAppDynamicsEnabled() || !queue.length) {
    return;
  }
  const item = queue.shift();
  report(item);

  if (queue.length) {
    debouncedNext();
  }
};

debouncedNext = debounce(next, 1000);

const queueReport = (nextReport) => {
  queue.push(nextReport);
  if (isAppDynamicsEnabled() && queue.length === 1) {
    debouncedNext();
  }
};

export function mark(name, attributes = {}) {
  /** As long as APP dynamics only support AJAX calls and
   * Virtual Pages view, we use them to track other app behaviours
   */

  queueReport({
    eventType: PAGE_VIEW,
    eventAttributes: { name, timestamp: Date.now() },
    attributes: {
      ...attributes,
      EventName: name
    }
  });
}

/**
 * Log an error to appDynamics
 * @param {Error} err
 * @returns {void} nothing
 */
export function noticeError(error) {
  queueReport({
    eventType: PAGE_ERROR,
    eventAttributes: { msg: error.message, line: error.lineNumber },
    attributes: {}
  });
}

export const setInitialLoadTime = (time = Date.now()) => {
  const initialLoadTime = time - loadingStartTime;
  setAttribute('InitialLoadTime', initialLoadTime);
};

const getResourceStats = (resourceName) => {
  const performanceAPI = getPerformanceAPI();
  return (
    performanceAPI &&
    performanceAPI.getEntries &&
    performanceAPI
      .getEntries()
      .find((resource) => resource.name && resource.name.includes(resourceName))
  );
};

function setLoadTime(resourceName, duration) {
  // To track vendor.js loadtime we use the attribute LoadTime-vendorjs
  const trackedAttribute = `LoadTime-${resourceName.replace(/\./g, '')}`;
  setAttribute(trackedAttribute, duration);
}

const getResourceDuration = (resourceStats) =>
  Math.round(resourceStats.duration || resourceStats.responseEnd - resourceStats.fetchStart);

const setResourceLoadTime = (resourceName) => {
  const resourceStats = getResourceStats(resourceName);
  if (!resourceStats) {
    return;
  }
  const duration = getResourceDuration(resourceStats);
  setLoadTime(resourceName, duration);
};

const isResourceCached = (resourceName) => {
  const resourceStats = getResourceStats(resourceName);
  return resourceStats && !resourceStats.nextHopProtocol;
};

const getVendorCssLoadTime = () => {
  const performanceAPI = getPerformanceAPI();
  return (
    performanceAPI &&
    performanceAPI.getEntries &&
    performanceAPI
      .getEntries()
      .filter(
        (resource) =>
          !resource.name.includes('main.css') &&
          (resource.name.includes('.css') ||
            (resource.initiatorType === 'link' && !resource.name.includes('.js')))
      )
      .map(getResourceDuration)
      .reduce((total, nextNumber) => total + nextNumber, 0)
  );
};

export const trackPageStats = (loadingEndTime = Date.now()) => {
  const performanceAPI = getPerformanceAPI();

  if (performanceAPI && performanceAPI.getEntries) {
    const trackeableResources = [
      'vendor.js',
      'vendorPolyfill.js',
      'main.js',
      'jquery',
      'main.css',
      'main.legacy.js',
      'main.modern.js'
    ];
    trackeableResources.forEach((resource) => {
      setResourceLoadTime(resource);
    });
    const vendorCssLoadTime = getVendorCssLoadTime();
    setLoadTime('vendorcss', vendorCssLoadTime);
    const isCacheUsed = trackeableResources.some((resource) => isResourceCached(resource));
    setAttribute('isCacheUsed', Boolean(isCacheUsed));
  }
  const timeTaken = loadingEndTime - loadingStartTime;
  setAttribute('ReadyToInteract', timeTaken);
  queueReport({});
};

window.onEnableAppDynamics = () => {
  next();
};

export function clearQueue() {
  queue.splice(0, queue.length);
}
