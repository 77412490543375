import isNil from 'lodash/isNil';

import ValidationsServiceInstance from '@services/validations';

import setComponentValidations from './setComponentValidations';

export const RESET_COMPONENT_VALIDATIONS = 'RESET_COMPONENT_VALIDATIONS';

const resetComponentValidations = (componentId) => (dispatch, getState) => {
  const { validations } = getState().components[componentId];

  // We want to ensure we have validations setup for this component.
  if (!isNil(validations)) {
    const defaultValues = ValidationsServiceInstance.getInstanceForComponent(componentId);
    const initialState = defaultValues.map((validation) => ({
      retries: validation.retries
    }));
    dispatch({ type: RESET_COMPONENT_VALIDATIONS });
    setComponentValidations(componentId, initialState)(dispatch);
  }
};

export default resetComponentValidations;
