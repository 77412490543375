import { connect } from 'react-redux';

import { setPageFetchingStatus, setPageReadyStatus } from '@redux/actions/setPageStatus';

import Geolocation from './Geolocation';

const mapStateToProps = (state) => ({
  fetchTimeout: state.pageSettings.geolocationTimeout
});

const actions = {
  setPageFetchingStatus,
  setPageReadyStatus
};

export default connect(mapStateToProps, actions)(Geolocation);
