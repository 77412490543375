/* eslint-disable react/sort-comp */

/* eslint-disable  react/require-default-props */

/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React from 'react';

import Question from '@commons/Question';

import TouchDetector from '@models/touchDetector';

import { DesktopDNDLayout, MobileDNDLayout } from './DNDLayout';
import DropdownLayout from './DropdownLayout';
import { DND } from './constants';

class RankingOrder extends React.Component {
  constructor(props) {
    super(props);
    this.wrapperRef = null;
    this.state = {
      currentlyOnTouchInteractions: TouchDetector.hasTouchAbility()
    };
  }

  setWrapperRef = (ref) => {
    this.wrapperRef = ref;
  };

  registerClickInteraction = () => {
    if (this.state.currentlyOnTouchInteractions) {
      this.setState({ currentlyOnTouchInteractions: false });
    }
  };

  registerTouchInteraction = () => {
    if (!this.state.currentlyOnTouchInteractions) {
      this.setState({ currentlyOnTouchInteractions: true });
    }
  };

  componentDidMount() {
    this.wrapperRef.addEventListener('click', this.registerClickInteraction);
    this.wrapperRef.addEventListener('touchstart', this.registerTouchInteraction);
  }

  componentWillUnmount() {
    this.wrapperRef.removeEventListener('click', this.registerClickInteraction);
    this.wrapperRef.removeEventListener('touchstart', this.registerTouchInteraction);
  }

  getFormHiddenInputs() {
    const { selected, unselected, selectedFormKey, notSelectedFormKey } = this.props;

    let answered = selected.map((item, idx) =>
      item ? (
        <input
          key={`selected${idx}${item.id}`}
          type="hidden"
          name={`${selectedFormKey}`}
          value={item.id}
        />
      ) : (
        <input key={`selected${idx}`} type="hidden" name={`${selectedFormKey}`} />
      )
    );

    if (!answered.length) {
      answered = [<input key="selected" type="hidden" name={`${selectedFormKey}`} />];
    }

    let discarded = unselected.map((item, idx) =>
      item ? (
        <input
          key={`unselected${idx}${item.id}`}
          type="hidden"
          name={`${notSelectedFormKey}`}
          value={item.id}
        />
      ) : (
        <input key={`unselected${idx}`} type="hidden" name={`${notSelectedFormKey}`} />
      )
    );

    if (!discarded.length) {
      discarded = [<input key="unselected" type="hidden" name={`${notSelectedFormKey}`} />];
    }

    return [...answered, ...discarded];
  }

  render() {
    const {
      id,
      caption,
      row,
      selected,
      layout,
      setAlternativeAt,
      firstSelectionOnDnD,
      unselectAlternative,
      validationEmpty,
      validationFailed,
      requiredField,
      validationMessages
    } = this.props;

    const DNDLayout = this.state.currentlyOnTouchInteractions ? MobileDNDLayout : DesktopDNDLayout;

    return (
      <div className="questionBlock rankingQuestion" ref={this.setWrapperRef}>
        <Question
          key="question"
          caption={caption}
          validationEmpty={validationEmpty}
          validationFailed={validationFailed}
          requiredField={requiredField}
          validationMessages={validationMessages}
          captionId={String(id)}
          hasLegendCaption={false}
        />
        {layout === DND ? (
          <DNDLayout
            originalItems={row}
            selectedItems={selected}
            reorderList={setAlternativeAt}
            firstSelection={firstSelectionOnDnD}
          />
        ) : (
          <DropdownLayout
            originalItems={row}
            selectedItems={selected}
            setAlternativeAt={setAlternativeAt}
            unselectAlternative={unselectAlternative}
          />
        )}
        {this.getFormHiddenInputs()}
      </div>
    );
  }
}
const { string, number, arrayOf, func, shape, bool } = PropTypes;

const itemPropType = shape({
  id: string,
  text: string
});

RankingOrder.propTypes = {
  id: number,
  caption: string,
  row: arrayOf(itemPropType),
  selected: arrayOf(itemPropType),
  unselected: arrayOf(itemPropType),
  selectedFormKey: string,
  notSelectedFormKey: string,
  layout: string,
  setAlternativeAt: func.isRequired,
  firstSelectionOnDnD: func.isRequired,
  unselectAlternative: func.isRequired,
  validationEmpty: string,
  validationFailed: string,
  requiredField: bool,
  validationMessages: arrayOf(string)
};

RankingOrder.defaultProps = {
  id: null,
  caption: '',
  row: [],
  selected: [],
  unselected: [],
  selectedFormKey: '',
  notSelectedFormKey: '',
  layout: DND
};

export default RankingOrder;
