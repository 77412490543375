/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React from 'react';

import {
  PANEL_ID_COOKIE_CONSENT,
  PANEL_ID_COOKIE_SETTINGS,
  PANEL_ID_LEGAL_NOTICE
} from '@components/CookieBanner/V3/utils';
import CookieBannerPanel from '@components/CookieBanner/shared/CookieBannerPanel';
import { COOKIE_BANNER_PANEL_ACTION_VARIANT } from '@components/CookieBanner/shared/CookieBannerPanel/Action';
import {
  COOKIE_BANNER_VERSION_V3,
  createNavigationLink
} from '@components/CookieBanner/shared/utils';
import { propTypes as StackNavigatorPropTypes } from '@components/StackNavigator/utils';

const PanelMain = ({
  navigation,
  consentGiven,
  // Translations
  title,
  description,
  consentInfoLinkText,
  legalInfoLinkText,
  allowAllCookiesButtonText,
  customizeCookiesButtonText,
  rejectCookiesButtonText,
  // Actions
  onAllowAll,
  onDecline
}) => (
  <CookieBannerPanel.Root>
    <CookieBannerPanel.Title>{title}</CookieBannerPanel.Title>

    <CookieBannerPanel.Description parseHTML>{description}</CookieBannerPanel.Description>

    <CookieBannerPanel.Section>
      <CookieBannerPanel.Link
        version={COOKIE_BANNER_VERSION_V3}
        onClick={createNavigationLink(navigation, PANEL_ID_LEGAL_NOTICE)}
      >
        {legalInfoLinkText}
      </CookieBannerPanel.Link>
      {consentGiven && (
        <CookieBannerPanel.Link
          version={COOKIE_BANNER_VERSION_V3}
          onClick={createNavigationLink(navigation, PANEL_ID_COOKIE_CONSENT)}
        >
          {consentInfoLinkText}
        </CookieBannerPanel.Link>
      )}
    </CookieBannerPanel.Section>

    <CookieBannerPanel.ActionArea>
      {!consentGiven && (
        <CookieBannerPanel.Action
          onClick={onDecline}
          variant={COOKIE_BANNER_PANEL_ACTION_VARIANT.text}
        >
          {rejectCookiesButtonText}
        </CookieBannerPanel.Action>
      )}
      <CookieBannerPanel.Action
        onClick={createNavigationLink(navigation, PANEL_ID_COOKIE_SETTINGS)}
        variant={COOKIE_BANNER_PANEL_ACTION_VARIANT.outlined}
      >
        {customizeCookiesButtonText}
      </CookieBannerPanel.Action>
      {/* Customize Cookies */}
      <CookieBannerPanel.Action onClick={onAllowAll}>
        {allowAllCookiesButtonText}
      </CookieBannerPanel.Action>
    </CookieBannerPanel.ActionArea>
  </CookieBannerPanel.Root>
);

PanelMain.propTypes = {
  navigation: StackNavigatorPropTypes.navigation.isRequired,
  consentGiven: PropTypes.bool.isRequired,
  // Translations
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  consentInfoLinkText: PropTypes.string.isRequired,
  legalInfoLinkText: PropTypes.string.isRequired,
  allowAllCookiesButtonText: PropTypes.string.isRequired,
  customizeCookiesButtonText: PropTypes.string.isRequired,
  rejectCookiesButtonText: PropTypes.string.isRequired,
  // Actions
  onAllowAll: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired
};

export default PanelMain;
