import evaluateNode from '@services/conditionEngine';
import { NODE_TYPES } from '@services/conditionEngine/constants';
import evaluatorRegistry from '@services/conditionEngine/evaluators/registry';

import COUNT_OPERATIONS from './constant';

const OPERATIONS = {
  [COUNT_OPERATIONS.LESS]: (fieldValuesLength, conditionValuesLength) =>
    fieldValuesLength < conditionValuesLength,
  [COUNT_OPERATIONS.LESS_OR_EQUAL]: (fieldValuesLength, conditionValuesLength) =>
    fieldValuesLength <= conditionValuesLength,
  [COUNT_OPERATIONS.EQUAL]: (fieldValuesLength, conditionValuesLength) =>
    fieldValuesLength === conditionValuesLength,
  [COUNT_OPERATIONS.GREATER_OR_EQUAL]: (fieldValuesLength, conditionValuesLength) =>
    fieldValuesLength >= conditionValuesLength,
  [COUNT_OPERATIONS.GREATER]: (fieldValuesLength, conditionValuesLength) =>
    fieldValuesLength > conditionValuesLength
};

function multiValuedCountConditionEvaluator(node, values) {
  const { op, left, right } = node;
  const fieldValues = evaluateNode(left, values);
  const operationFn = OPERATIONS[op];

  if (!operationFn) {
    throw new Error('Invalid operation type');
  }

  return Array.isArray(fieldValues) && operationFn(fieldValues.length, right);
}

evaluatorRegistry.registerEvaluatorForNodeType(
  NODE_TYPES.MULTI_VALUED_COUNT,
  multiValuedCountConditionEvaluator
);
export default multiValuedCountConditionEvaluator;
