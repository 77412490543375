import get from 'lodash/get';
import { connect } from 'react-redux';

import { longformOtherTextAnswerQuestion } from '@components/Longform/actions';

import OtherOptionTextfield from './OtherOptionTextfield';

function mapStateToProps(state, ownProps) {
  const defaultObject = {};
  const option = get(state, ['options', ownProps.id], defaultObject);
  const htmlInput = get(state, `htmlInputs[${option.htmlInput}]`, defaultObject);
  return {
    ...option,
    textfieldInput: { ...htmlInput }
  };
}

const mapDispatchToProps = { answerQuestion: longformOtherTextAnswerQuestion };

export default connect(mapStateToProps, mapDispatchToProps)(OtherOptionTextfield);
