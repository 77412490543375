import mapValues from 'lodash/mapValues';

function isNonNullObject(obj) {
  return typeof obj === 'object' && obj !== null;
}

function convertBool(value) {
  if (value === 'true' || value === 'false') {
    return value === 'true';
  } else if (isNonNullObject(value)) {
    // eslint-disable-next-line no-use-before-define
    return parseBoolProperties(value);
  }
  return value;
}

/**
 * Traverses an object, and returns a new object, containing the same properties,
 * with exception of strings, which will be transformed to boolean if they have
 * a value of "true" or "false".
 *
 * This only applies to properties of an object, which have string values of "false" or "true".
 */
export default function parseBoolProperties(o) {
  if (o instanceof Array) {
    return o.map(parseBoolProperties);
  } else if (isNonNullObject(o)) {
    return mapValues(o, convertBool);
  }
  return o;
}
