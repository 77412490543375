/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import React from 'react';

import Icon, { ICON_TYPES } from '@commons/Icon';

import styles from './tab.scss';

class AccordionTab extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    defaultExpanded: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    children: PropTypes.node.isRequired
  };

  static defaultProps = {
    defaultExpanded: false
  };

  constructor(props) {
    super(props);
    this.state = {
      expanded: this.props.defaultExpanded
    };
  }

  toggle = () => {
    this.setState((state) => ({ ...state, expanded: !state.expanded }));
  };

  render() {
    const { id, title, children } = this.props;
    const { expanded } = this.state;

    const iconClassName = expanded ? styles['icon-expand'] : styles['icon-close'];

    return (
      <div className={styles.wrapper}>
        <input type="checkbox" checked={expanded} id={id} onChange={this.toggle} />
        <label className={styles.label} htmlFor={id}>
          <Icon containerStyles={iconClassName} type={ICON_TYPES.ARROW_UP_CHEVRON} />
          {title}
        </label>
        <div className={styles.content}>{children}</div>
      </div>
    );
  }
}

export default AccordionTab;
